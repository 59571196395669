import { AbilityBuilder, createMongoAbility, ExtractSubjectType, InferSubjects, MongoAbility } from '@casl/ability';
import { AccessAction, AccessActionKey, AccessType, JWTPayload } from '@model-park/entities';
import type { AppProject, AppProjectDocument } from '@model-park/models';
import { Model } from 'mongoose';
import { Flatten } from '../interfaces';
import { _keys } from '../utils';

export function AppProjectAbility(user: JWTPayload, subject: Model<AppProjectDocument> | AppProject, password?: string) {
    type Subjects = InferSubjects<typeof subject> | 'all';
    type AppAbility = MongoAbility<[AccessAction, Subjects]>;

    const userId = user?.sharingUser ?? user?.userId ?? null;

    const { can: allow, cannot, build } = new AbilityBuilder<AppAbility>(createMongoAbility);

    if (user?.role === 'admin') allow(AccessAction.MANAGE, 'all'); // read-write access to everything
    if (user?.role === 'admin') allow(AccessAction.READ, 'all'); // read-write access to everything
    if (user?.role === 'admin') allow(AccessAction.ACCESS, 'all'); // read-write access to everything
    if (user?.appName === subject.name) allow(AccessAction.READ, 'all');
    if (user?.appName === subject.name) allow(AccessAction.ACCESS, 'all');

    allow<Flatten<AppProject>>(AccessAction.READ, subject as any, {
        ['accessManagement.access']: AccessType.PRIVATE,
        name: user?.appName,
    });
    allow<Flatten<AppProject>>(AccessAction.ACCESS, subject as any, {
        ['accessManagement.access']: AccessType.PRIVATE,
        name: user?.appName,
    });
    allow<Flatten<AppProject>>(AccessAction.READ, subject as any, {
        ['accessManagement.access']: AccessType.PROTECTED,
        name: user?.appName,
    });
    allow<Flatten<AppProject>>(AccessAction.ACCESS, subject as any, {
        ['accessManagement.access']: AccessType.PROTECTED,
        name: user?.appName,
    });

    if (password) allow(AccessAction.ACCESS, subject as any, { ['accessManagement.password']: password });

    if (userId) allow<Flatten<AppProject>>([AccessAction.MANAGE], subject as any, { ['accessManagement.owner']: userId });

    _keys(AccessAction).forEach((action: AccessActionKey) => {
        allow<Flatten<AppProject>>(AccessAction[action], subject as any, {
            ['accessManagement.permissions']: {
                $elemMatch: { user: userId, access: AccessAction[action] },
            },
        });
    });

    allow<Flatten<AppProject>>(AccessAction.READ, subject as any, { ['accessManagement.access']: AccessType.PUBLIC });
    allow<Flatten<AppProject>>(AccessAction.ACCESS, subject as any, { ['accessManagement.access']: AccessType.PUBLIC });
    allow<Flatten<AppProject>>(AccessAction.READ, subject as any, { ['accessManagement.access']: AccessType.PROTECTED });

    return build({
        detectSubjectType: item => {
            return item.constructor as ExtractSubjectType<Subjects>;
        },
    });
}
