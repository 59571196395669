import { Table, Typography } from 'antd';

const { Title, Paragraph } = Typography;

export default function Kill() {
    return (
        <>
            <Title level={4}>Kill:</Title>
            <Paragraph>Kill your registered app.</Paragraph>
            <Table
                dataSource={killData}
                columns={killColumns}
                pagination={false}
                scroll={{ x: 'max-content' }}
            />
            <Title level={5}>Example:</Title>
            <Typography>
                <code>modelpark kill -a</code>
            </Typography>
            <Typography>
                <code>modelpark kill -n mp-app</code>
            </Typography>
        </>
    );
}

const killData = [
    {
        name: '--all, -a',
        type: 'boolean',
        description: 'Kill all registered apps.',
        defaultValue: 'undefined',
    },
    {
        name: '--name, -n',
        type: 'string',
        description: 'App name.',
        defaultValue: 'undefined',
    },
];

const killColumns = [
    {
        title: 'Name',
        dataIndex: 'name',
    },
    {
        title: 'Type',
        dataIndex: 'type',
    },
    {
        title: 'Description',
        dataIndex: 'description',
    },
    {
        title: 'Default Value',
        dataIndex: 'defaultValue',
    },
];
