import { fetchEventSource } from '@microsoft/fetch-event-source';
import { IDBEventResponse, isJson } from '@model-park/common';

class RetriableError extends Error {}
class FatalError extends Error {}

export class ServerEvent {
    constructor(
        private readonly uri: string,
        private readonly cb: (data: IDBEventResponse) => void,
        private readonly onError?: (err?: any) => void,
        private readonly abortController?: AbortController
    ) {
        this.subscribeEvent = this.subscribeEvent.bind(this);
    }

    async subscribeEvent() {
        const { uri, cb, onError, abortController } = this;

        fetchEventSource(uri, {
            headers: {
                Accept: 'text/event-stream',
                Authorization: `Bearer ${sessionStorage.getItem('authToken')}`,
            },
            async onopen(response) {
                if (response.ok) {
                    console.log('eventStarted', response);
                } else if (response.status >= 400 && response.status < 500 && response.status !== 429) {
                    // client-side errors are usually non-retriable:
                    throw new FatalError(response.status.toString());
                } else {
                    throw new RetriableError();
                }
            },
            onmessage(response) {
                console.log('event', response);
                if (!isJson(response.data)) return;
                const data: IDBEventResponse = JSON.parse(response.data);
                cb(data);
            },
            onerror(err) {
                if (err instanceof FatalError) {
                    onError?.(new Error(err.message));
                }
                console.log('error', err);
            },
            signal: abortController?.signal,
            openWhenHidden: true,
        });
    }
}
