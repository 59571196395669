import { DownloadOutlined } from '@ant-design/icons';
import { MPSegmented, MPTable } from '@model-park/mp-components';
import { Typography } from 'antd';
import { ColumnsType } from 'antd/es/table';
import prettyBytes from 'pretty-bytes';
import { useState } from 'react';
import semver from 'semver';
import UAParser from 'ua-parser-js';
import { DistFile } from '../../entities/types';
import { useGetDistListQuery } from '../../store/DistService';

export function DistTable() {
    const [OS, setOS] = useState(getOS());
    const { data, isFetching } = useGetDistListQuery(undefined, {
        selectFromResult: ({ currentData, isFetching }) => ({
            data: structuredClone(currentData)?.sort((a, b) => {
                const aSemVer = semver.coerce(a.name);
                const bSemVer = semver.coerce(b.name);
                if (aSemVer && bSemVer) {
                    return semver.rcompare(aSemVer.version, bSemVer.version);
                }
                if (a.name === 'mpinstaller.exe') return -1;
                if (b.name === 'mpinstaller.exe') return 1;
                return 0;
            }),
            isFetching,
        }),
    });

    let tableData = data;
    if (OS === 'win') tableData = data?.filter(item => item.name.includes('win') || item.name.includes('mpinstaller'));
    else if (OS !== 'all') tableData = data?.filter(item => item.name.includes(OS));

    return (
        <>
            <Typography.Title level={3}>Modelpark CLI Distribution</Typography.Title>

            <MPSegmented
                options={osOptions}
                value={OS}
                onChange={_value => setOS(_value as string)}
            />

            <MPTable
                dataSource={tableData}
                columns={columns}
                scroll={{ x: 'max-content' }}
                loading={isFetching}
            />
        </>
    );
}

const columns: ColumnsType<DistFile> = [
    {
        dataIndex: 'name',
        title: 'Name',
    },
    {
        key: 'download',
        dataIndex: 'download',
        title: 'Download',
        render: (_, record) => (
            <a href={`/dist-folder/${record?.name}`}>
                <DownloadOutlined />
            </a>
        ),
    },
    {
        dataIndex: 'size',
        title: 'Size',
        render: size => prettyBytes(size),
    },
    {
        dataIndex: 'mod_time',
        title: 'Modified',
        render: mtime => new Date(mtime).toLocaleString(),
    },
];

const osOptions = [
    { label: 'Windows', value: 'win' },
    { label: 'Linux', value: 'linux' },
    { label: 'Mac', value: 'mac' },
    { label: 'All', value: 'all' },
];

function getOS() {
    const parser = new UAParser();
    const osName = parser.getOS().name;

    if (!osName) return 'all';
    if (osName.includes('Windows')) return 'win';
    if (osName.includes('Linux')) return 'linux';
    if (osName.includes('Mac')) return 'mac';
    return 'all';
}
