import { Button, ButtonProps } from 'antd';

export default function MPButton({ ...props }: IMPButtonProps) {
    return (
        <>
            <Button {...props} />
        </>
    );
}

export interface IMPButtonProps extends ButtonProps {

}
