import { Modals } from '@app/entities';
import { useModalState } from '@app/hooks';
import { Breadcrumb, useAppSelector } from '@common';
import { Seo } from '@common/components';
import { ProjectForm } from '@common/components/ProjectForm';
import { MPButton, MPProtectedRoute } from '@model-park/mp-components';
import { selectAuthUser } from '@pages/Authentication/store';
import Styles from './Styles.module.scss';
import ImportExternalResource from './components/ImportExternalApp';
import useNewProject from './useNewProject';

export default function NewProject() {
    const [_, updateModalState] = useModalState(Modals.ImportExternalApp);
    const { form, isLoading, onFinish } = useNewProject();
    const user = useAppSelector(selectAuthUser);

    return (
        <MPProtectedRoute user={user}>
            <Seo
                title="New Project"
                description="Create a new project"
                keywords="modelpark, new project, create app, huggingface, spaces, modelpark, ai model, classification, nlp, llm, streamlit"
                name="New Project"
            />
            <div className={Styles.NewProjectPageContainer}>
                <Breadcrumb />

                <MPButton onClick={() => updateModalState({ open: true })}>Import from external resources</MPButton>

                <ImportExternalResource form={form} />

                <ProjectForm
                    form={form}
                    isLoading={isLoading}
                    onFinish={onFinish}
                />
            </div>
        </MPProtectedRoute>
    );
}
