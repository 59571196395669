import { useCreateNewAppProjectMutation } from '@app/services';
import { NavigationHelper } from '@common';
import { ProjectFormValues, isIncludeOrEqual } from '@model-park/common';
import { Errors } from '@model-park/entities';
import { Form } from 'antd';
import { serialize } from 'object-to-formdata';
import { useCallback } from 'react';
import { toast } from 'react-toastify';

export default function useNewProject() {
    const [createNewApp, { isLoading }] = useCreateNewAppProjectMutation();
    const [form] = Form.useForm<ProjectFormValues>();

    const onFinish = useCallback(
        async (values: ProjectFormValues) => {
            try {
                const payload = { ...values, ...form.getFieldsValue(true) };
                const formData = serialize(payload, { indices: true });

                await createNewApp(formData).unwrap();

                form.resetFields();

                toast.success('App created successfully');

                NavigationHelper.AppProject.AppDashboard({}).navigate();
            } catch (error: any) {
                console.log(error);

                toast.error(error?.data?.message);

                if (isIncludeOrEqual(error?.data?.message, Errors.appExists)) {
                    form.setFields([
                        {
                            name: 'name',
                            errors: [Errors.appExists],
                        },
                    ]);
                }
            }
        },
        [createNewApp, form]
    );

    return { onFinish, isLoading, form };
}
