import { IModalState } from '@app/entities';
import { RootState } from '@store';

export const selectProjectList = (state: RootState) => state.app.projectList;
export const selectIsAppInit = (state: RootState) => state.app.isAppInit;
export const selectUserTheme = (state: RootState) => state.app.theme;
export const selectCurrentUser = (state: RootState) => state.auth.user;

export function getActiveModalSelector<T extends object>(name: string) {
    return (state: RootState) => (state.app.commonModals[name] as IModalState<T>) || {};
}
