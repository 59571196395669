import { URLHelper } from '@model-park/common';
import { IAppProject } from '@model-park/models';
import { useCallback, useRef, useState } from 'react';
import { useEvent } from 'react-use';
import Styles from './Styles.module.scss';
import useAppname from './useAppname';
import { AppError } from './AppError';

export default function AppFrame({ accessToken }: IProps) {
    const { appName, maindomain, path } = useAppname();
    const [linkStatus, setLinkStatus] = useState<LinkStatusResult>();

    const iframeRef = useRef<HTMLIFrameElement | null>();
    const iframeUrl = new URLHelper(`https://${appName}-proxy.${maindomain}/iframe-auth/`, { accessToken });

    const props = {
        className: Styles.AppFrame,
        src: iframeUrl.toString(),
        credentialless: 'true',
    };

    const onIframeMessage = useCallback(
        (e: MessageEvent<IframeAuthMessage>) => {
            if (e.origin !== iframeUrl.origin) return;

            const { action, result } = e.data;

            if (action === 'link-check') {
                setLinkStatus(result);

                if (result.status === 'ok') {
                    iframeRef.current?.contentWindow?.postMessage({ action: 'navigate', path }, iframeUrl.origin);
                }
            }
        },
        [iframeUrl.toString(), accessToken]
    );

    useEvent('message', onIframeMessage);

    if (linkStatus?.status === 'error') {
        return <AppError error={linkStatus} />;
    }

    return (
        <iframe
            onError={e => console.error(e)}
            ref={iframeRef as any}
            title={appName || 'AppPage'}
            sandbox="allow-scripts allow-same-origin allow-popups allow-popups-to-escape-sandbox allow-top-navigation"
            {...props}
        />
    );
}

interface IProps {
    accessToken?: string;
}

interface IframeAuthMessage {
    type: 'iframe-auth';
    action: 'link-check';
    result: LinkStatusResult;
}

interface LinkStatusResult {
    status: 'ok' | 'error';
    message: string;
    statusCode?: number;
    text?: string;
}
