import { getLocationParts } from '@model-park/common';
import { useParams } from 'react-router-dom';

export default function useAppname(location?: string) {
    const { name } = useParams();
    const { subdomain, maindomain, path } = getLocationParts(location);

    const appName = name || (subdomain as string);

    return { appName, maindomain, path };
}
