import { LoginForm } from '@model-park/mp-components';
import useLoginForm from './useLoginForm';
import Styles from './Styles.module.scss';

export default function Login({ onSuccess, withCard = true }: IProps) {
    const { onFinish, isLoading } = useLoginForm(onSuccess);

    if (!withCard) return LoginForm({ onFinish, isLoading });

    return (
        <LoginForm
            isLoading={isLoading}
            onFinish={onFinish}
            className={Styles.LoginForm}
        />
    );
}

interface IProps {
    onSuccess?: () => void;
    withCard?: boolean;
}
