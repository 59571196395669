import { useCallback } from 'react';
import { useSearchParams } from 'react-router-dom';
import { _entries, isNullOrUndefined } from '../utils';

export function useUpdateSearchParams() {
    const [, setSearchParams] = useSearchParams();

    function updateSearchParams(newParams: Dictionary) {
        if (!newParams) return;
        const _params = new URLSearchParams(window.location.search);
        _entries(newParams).forEach(([key, value]) => {
            if (isNullOrUndefined(value)) {
                _params.delete(key as any);
            } else {
                _params.set(key as any, value);
            }
        });
        setSearchParams(_params);
    }

    return useCallback(updateSearchParams, [setSearchParams]);
}
