import { NavigationHelper } from './NavigationHelper';

export * from './NavigationHelper';
export { default as i18n } from './i18n';

export function getAuthToken(): string {
    const storedToken = sessionStorage.getItem('authToken') ?? localStorage.getItem('authToken');
    const queryToken = new URLSearchParams(window.location.search).get('authToken');
    return queryToken ?? (storedToken as string);
}

export function Logout() {
    localStorage.clear();
    sessionStorage.clear();

    window.location.href = NavigationHelper.Home().uri;
    window.location.reload();
}

export function getAppPassword(name: string) {
    return sessionStorage.getItem(`password-${name}`);
}

export function setAppPassword(name: string, password: string) {
    sessionStorage.setItem(`password-${name}`, password);
}
