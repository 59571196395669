import { Input } from 'antd';
import { SearchProps } from 'antd/lib/input';
import cs from "classnames";
import Styles from "./Styles.module.scss";

export default function MPSearch({ className, ...props }: MPSearchProps) {
    return (
        <>
            <Input.Search
                className={cs(Styles?.MPInput, className)}
                {...props}
            />
        </>
    );
}

export interface MPSearchProps extends SearchProps {

}
