import { ICommonProps, _isEmpty } from '@model-park/common';
import { Empty, EmptyProps, Spin } from 'antd';
import type { Breakpoint } from 'antd/lib';
import cs from 'classnames';
import { useMemo } from 'react';
import Styles from './Styles.module.scss';
import { Size, useCurrentBreakpoint } from './useCurrentBreakpoint';

export default function MPGrid({
    className,
    style = {},
    gridOptions,
    gridClassName,
    loading = false,
    size,
    emptyProps,
    ...props
}: MPGridProps) {
    const currentBreakpoint = useCurrentBreakpoint(size as any);

    const _style = useMemo((): React.CSSProperties => {
        if (!currentBreakpoint) return {};

        const { gap, columns } = gridOptions?.[currentBreakpoint] || {};

        return {
            display: 'grid',
            gap: gap,
            gridTemplateColumns: `repeat(${columns}, minmax(0, 1fr))`,
            ...style,
        };
    }, [currentBreakpoint, gridOptions, style]);

    return (
        <div className={cs(Styles.MPGridContainer, className)}>
            <Spin spinning={loading}>
                {_isEmpty(props.children) ? (
                    <Empty {...emptyProps} />
                ) : (
                    <div
                        className={cs(Styles?.Grid, gridClassName)}
                        style={_style}
                        {...props}
                    />
                )}
            </Spin>
        </div>
    );
}

export interface MPGridProps extends ICommonProps {
    gridOptions?: Record<Breakpoint, GridOptions>;
    loading?: boolean;
    size?: Size;
    gridClassName?: string;
    emptyProps?: EmptyProps;
}

export interface GridOptions {
    gap?: number;
    columns?: number;
}
