import { MPForm } from '@model-park/mp-components';
import { Button, Checkbox, Form, FormInstance } from 'antd';
import { ISignupForm } from '../../entities';
import { AuthEmail, AuthPassword, AuthPasswordConfirm, AuthUserName } from '../AuthFormItems';

export default function SignupForm({ onFinish, isLoading, className, showModal }: IProps) {
    const [form] = Form.useForm<ISignupForm>();

    return (
        <>
            <MPForm
                onFinish={values => onFinish(values, form)}
                layout="vertical"
                form={form}
                loading={isLoading}
                className={className}
            >
                <AuthUserName />
                <AuthEmail />

                <AuthPassword />

                <AuthPasswordConfirm />
                <Form.Item
                    name="legaldisclaimer"
                    valuePropName="checked"
                    rules={[{ required: true, message: 'Please accept legal disclaimer' }]}
                >
                    <Checkbox>
                        I have read and agree with the{' '}
                        <Button
                            style={{ display: 'contents' }}
                            type="link"
                            onClick={showModal}
                        >
                            Legal Disclaimer
                        </Button>
                    </Checkbox>
                </Form.Item>
            </MPForm>
        </>
    );
}

interface IProps {
    onFinish: (values: ISignupForm, form: FormInstance<ISignupForm>) => void;
    isLoading?: boolean;
    className?: string;
    showModal: () => void;
}
