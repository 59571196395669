import { DashboardPanel } from '@app/components';
import { Seo } from '@common/components';
import { AppProjectStatsTypes } from '@model-park/entities';
import AnalyticChart from './AnalyticChart';
import Styles from './Styles.module.scss';
import AppAnalytics from './AppAnalytics';

export default function Analytics() {
    return (
        <>
            <Seo
                title="ModelPark Analytics"
                description="ModelPark Analytics provides insights into your app usage, including number of views, number of unique visitors, and more."
                keywords="modelpark, analytics, localtunnel, localhost, ngrok, modelpark, cloud server, tunneling, app sharing"
                name="modelpark"
            />
            <DashboardPanel
                className={Styles.AnalyticChart}
                title="Analytics"
            >
                {AppProjectStatsTypes.map(chartKey => (
                    <AnalyticChart
                        chartKey={chartKey}
                        key={chartKey}
                    />
                ))}
            </DashboardPanel>

            <AppAnalytics />
        </>
    );
}
