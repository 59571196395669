import { MPAPISlice } from '@app/services';
import { ProfileFormValues, base64ResponseHandler } from '@model-park/common';
import { Organization, UIUser } from '@model-park/models';

export const UserApiSlice = MPAPISlice.injectEndpoints({
    endpoints: builder => ({
        updateProfile: builder.mutation<UIUser, { body: ProfileFormValues; id: string }>({
            query: ({ id, body }) => ({
                url: `users/${id}`,
                method: 'PATCH',
                body,
            }),
        }),
        updateProfilePicture: builder.mutation<{ profilePicturePath: string }, FormData>({
            query: formData => ({
                url: 'users/profile-picture',
                method: 'POST',
                body: formData,
            }),
            invalidatesTags: ['ProfilePicture'],
        }),

        getProfilePicture: builder.query<string, any>({
            query: () => ({
                url: 'users/profile-picture',
                responseHandler: base64ResponseHandler,
            }),
            providesTags: ['ProfilePicture'],
        }),

        removeProfilePicture: builder.mutation<any, void>({
            query: () => ({
                url: 'users/profile-picture',
                method: 'DELETE',
            }),
        }),
        createNewOrganization: builder.mutation<any, any>({
            query: data => ({
                url: `users/create-organization`,
                method: 'POST',
                body: data,
            }),
            invalidatesTags: ['OrganizationList'],
        }),
        getOrganizations: builder.query<Array<Organization>, undefined>({
            query: () => ({
                url: `users/organizations`,
            }),
            providesTags: ['OrganizationList'],
        }),
        getOrganization: builder.query<Organization, string>({
            query: organization => ({
                url: `users/organization`,
                params: { organization },
            }),
        }),
        getOrganizationMembers: builder.query<Array<UIUser>, string>({
            query: organization => ({
                url: `users/organization-members`,
                params: { organization },
            }),
        }),
        isUserNameUnique: builder.mutation<{ isUnique: boolean }, { userName: string }>({
            query: ({ userName }) => ({
                url: `users/is-user-name-unique`,
                params: { userName },
            }),
        }),
    }),
});

export const {
    useUpdateProfileMutation,
    useUpdateProfilePictureMutation,
    useGetProfilePictureQuery,
    useRemoveProfilePictureMutation,
    useCreateNewOrganizationMutation,
    useGetOrganizationsQuery,
    useGetOrganizationQuery,
    useGetOrganizationMembersQuery,
    useIsUserNameUniqueMutation,
} = UserApiSlice;
interface IProfilePictureResponse {}
