import Provider from './Provider';
import './assets/main.scss';

export default function App() {
    return (
        <>
            <Provider />
        </>
    );
}
