import { MPMenu } from '@model-park/mp-components';
import { MenuProps } from 'antd/lib';
import { Outlet, useNavigate } from 'react-router-dom';
import { ItemType } from 'antd/es/menu/hooks/useItems';
import Styles from './Styles.module.scss';
import { RoutingMap } from '@app/entities';
import Avatar from 'antd/es/avatar/avatar';
import { UserCircle } from '@assets/icons';
import { useSelector } from 'react-redux';
import { selectAuthUser } from '@pages/Authentication/store';

export default function Settings() {
    const navigate = useNavigate();
    const user = useSelector(selectAuthUser);

    const onClick: MenuProps['onClick'] = e => {
        navigate(`${e?.key}`);
    };

    const MenuItems: ItemType[] = [
        {
            key: RoutingMap.Settings.Profile,
            // label: 'Apps',
            label: 'Profile',
        },
        {
            key: RoutingMap.Settings.Account,
            // label: 'New Project',
            label: 'Account',
        },
        {
            key: RoutingMap.Settings.Organizations,
            // label: 'Distribution',
            label: 'Organizations',
        },
    ];
    return (
        <div className={Styles.SettingsLayout}>
            <div className={Styles.MenuWrapper}>
                <div className={Styles.NameSection}>
                    <UserCircle style={{ color: '#6AE942' }} />
                    <div>{user?.fullName ?? user?.email}</div>
                </div>
                <MPMenu
                    className={Styles.SettingMenu}
                    items={MenuItems}
                    onClick={onClick}
                    defaultSelectedKeys={[window.location.pathname]}
                    theme="light"
                />
            </div>
            <Outlet />
        </div>
    );
}
