import { MPForm, MPSwitch } from '@model-park/mp-components';
import { Form, FormInstance } from 'antd';
import { ILoginForm } from '../../entities/';
import { AuthEmail, AuthPassword } from '../AuthFormItems';

export default function LoginForm({ onFinish, isLoading, className }: IProps) {
    const [form] = Form.useForm<ILoginForm>();

    return (
        <>
            <MPForm
                onFinish={values => onFinish(values, form)}
                layout="vertical"
                form={form}
                loading={isLoading}
                className={className}
            >
                <AuthEmail />

                <AuthPassword />

                <Form.Item
                    name={'remember'}
                    label={'Remember Me'}
                    valuePropName={'checked'}
                    initialValue
                >
                    <MPSwitch />
                </Form.Item>
            </MPForm>
        </>
    );
}

interface IProps {
    onFinish: (values: ILoginForm, form: FormInstance<ILoginForm>) => void;
    isLoading: boolean;
    className?: string;
}
