import { useGetCoverPictureQuery } from '@app/services';

export function useCoverPicture(name?: string, coverPictureUrl?: string) {
    const { currentData: coverPictureBlob } = useGetCoverPictureQuery(name as string, {
        skip: !name || !coverPictureUrl || coverPictureUrl?.startsWith('http'),
    });

    const coverPicture = Boolean(coverPictureUrl) && coverPictureUrl?.startsWith('http') ? coverPictureUrl : coverPictureBlob;

    return { coverPicture };
}
