import { Pagination, PaginationProps } from 'antd';
import cs from 'classnames';
import Styles from './Styles.module.scss';

export function MPPagination({ className, ...props }: IMPPaginationProps) {
    return (
        <>
            <div className={cs(Styles.MPPaginationWrapper, className)}>
                <Pagination
                    className={cs(Styles.MPPagination, className)}
                    {...props}
                />
            </div>
        </>
    );
}

export interface IMPPaginationProps extends PaginationProps {}
