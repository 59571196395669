import { IUser } from '@model-park/models';
import React from 'react';
import { Navigate } from 'react-router-dom';

export function MPProtectedRoute({ children, user }: IProps) {
    if (!user?._id) {
        return (
            <Navigate
                to="/"
                replace
            />
        );
    }

    return children;
}

export interface IProps {
    user?: Omit<IUser, 'password'>;
    children: React.ReactNode;
}
