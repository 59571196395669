import { selectUserTheme } from '@app/store/selectors';
import { useAppSelector } from '@common';
import { ArrowUpRight } from '@model-park/assets';
import { IMPCardProps, MPCard } from '@model-park/mp-components';
import cs from 'classnames';
import Styles from './Styles.module.scss';

export function DashboardPanel({ children, className, onExpand, expanded, ...props }: IDashboardPanelProps) {
    const userTheme = useAppSelector(selectUserTheme);

    return (
        <MPCard
            className={cs(Styles.DashboardPanel, className)}
            theme={userTheme}
            {...props}
        >
            {children}
            {onExpand ? (
                <ExpandIcon
                    onExpand={onExpand}
                    expanded={expanded}
                />
            ) : null}
        </MPCard>
    );
}

function ExpandIcon({ expanded, onExpand }: { expanded?: boolean; onExpand?: () => void }) {
    return (
        <ArrowUpRight
            className={cs(Styles.Arrow, { [Styles.Expanded]: expanded })}
            onClick={onExpand}
        />
    );
}

export interface IDashboardPanelProps extends IMPCardProps {
    onExpand?: () => void;
    expanded?: boolean;
}
