import { useGetAppDetailsQuery, useUpdateAppProjectMutation } from '@app/services';
import { useAppDispatch } from '@common';
import { ProjectFormValues, _entries, _isEqual } from '@model-park/common';
import { IAppProject } from '@model-park/models';
import { selectCurrentAppName, setEditDrawerOpen } from '@pages/MainDashBoard/store';
import { Form } from 'antd';
import { serialize } from 'object-to-formdata';
import { useCallback, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { toast } from 'react-toastify';

export default function useUpdateProject() {
    const [form] = Form.useForm<ProjectFormValues>();
    const currentAppName = useSelector(selectCurrentAppName);
    const dispatch = useAppDispatch();
    const [updateAppProject, { isLoading: updateLoading }] = useUpdateAppProjectMutation();

    const { currentData: app, isFetching: detailsLoading } = useGetAppDetailsQuery(currentAppName as string, {
        skip: !currentAppName,
    });

    useEffect(() => {
        if (!app) return;
        form.setFieldsValue(app);
    }, [app, form]);

    const onFinish = useCallback(
        async (values: ProjectFormValues) => {
            try {
                const payload = getDifferences(values, app as IAppProject);
                const formData = serialize(payload, { indices: true });

                await updateAppProject({ body: formData, id: app?._id as string }).unwrap();

                // NavigationHelper.AppProject.AppDetails({ params: { appName: app?.name as string } }).navigate();
                dispatch(setEditDrawerOpen(false));
                toast.success('App updated successfully');
            } catch (error: any) {
                console.log(error);
                toast.error(error?.data?.message);
            }
        },
        [app, updateAppProject, dispatch]
    );

    return {
        onFinish,
        form,
        app,
        detailsLoading,
        updateLoading,
        currentAppName,
    };
}

function getDifferences(payload: ProjectFormValues, data: IAppProject) {
    const differences: Partial<IAppProject> = {};
    _entries(payload).forEach(([key, value]) => {
        if (!_isEqual(value, (data as any)?.[key])) {
            (differences as any)[key] = value;
        }
    });

    return differences;
}
