import { NavigationHelper } from '@common';
import { MPButton } from '@model-park/mp-components';
import OrganizationList from './OrganizationList';
import Styles from './Styles.module.scss';

export default function OrganizationSettings() {
    function getNewOrganization() {
        NavigationHelper.Settings.newOrganization().navigate();
    }

    return (
        <div>
            <div className={Styles.Header}>
                <h1>Organization Settings</h1>
                <MPButton
                    onClick={getNewOrganization}
                    className={Styles.SubmitButton}
                    type="primary"
                >
                    + Add New Organization
                </MPButton>
            </div>

            <OrganizationList />
        </div>
    );
}
