import variables from '@app/assets/_variables.module.scss';
import { ThemeSwitch } from '@app/components/MainLayout';
import { Layout } from 'antd';
import { Outlet } from 'react-router-dom';
import Styles from './Styles.module.scss';
import { useCheckAuthToken } from './hooks/useCheckAccessToken';
import { useCurrentView } from './hooks/useCurrentView';
import { RoutingMap } from '@app/entities';
import { AuthCard, MPSegmented } from '@model-park/mp-components';
import { Login, Signup } from './views';
import { useNavigate } from 'react-router-dom';
import { ModelparkLogo19 } from '@model-park/assets';
import { NavigationHelper } from '@common';

export default function Authentication() {
    useCheckAuthToken();
    const currentView = useCurrentView();
    const navigate = useNavigate();

    function handleAuthChange(value: string) {
        switch (value) {
            case 'login':
                navigate(RoutingMap.Login);
                break;
            case 'signup':
                navigate(RoutingMap.Signup);
                break;
            default:
                navigate(RoutingMap.Login);
                break;
        }
    }

    function onNavigateToHome() {
        NavigationHelper.Home().navigate();
    }

    const authCardMap = {
        login: <Login />,
        signup: <Signup />,
    };

    return (
        <Layout className={Styles.LayoutContainer}>
            {/* <ThemeSwitch
                className={Styles.ThemeSwitch}
                sunColor={variables.colorPrimary}
            /> */}
            <ModelparkLogo19
                onClick={onNavigateToHome}
                className={Styles.Logo}
            />
            <AuthCard>
                <MPSegmented
                    value={currentView}
                    onChange={(value: any) => handleAuthChange(value)}
                    options={[
                        {
                            label: <div>Sign In</div>,
                            value: 'login',
                        },
                        {
                            label: <div>Sign Up</div>,
                            value: 'signup',
                        },
                    ]}
                />
                {authCardMap[currentView]}
            </AuthCard>
        </Layout>
    );
}
