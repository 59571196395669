import { useAppSelector } from '@common';
import { selectUserTheme } from '@app/store/selectors';
import cx from 'classnames';

export default function useGetThemeClass(){
    const userTheme = useAppSelector(selectUserTheme);

    const scrollClass = cx({
        'dark-scrollbar': userTheme === 'dark',
        'light-scrollbar': userTheme === 'light',
    });

    return {scrollClass}
}