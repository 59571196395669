import React from 'react';
import { Typography, Table } from 'antd';

const { Title, Paragraph } = Typography;

export function DistHead() {
    return (
        <>
            <Title level={2}>Modelpark CLI</Title>
            <Title level={3}>PUBLISH YOUR ML MODELS EFFORTLESSLY ON A SLEEK APP</Title>
            <Paragraph>
                You can share and manage your ML Models from modelpark. Our CLI tools provides opportunity to manage and publish
                your app from your machine directly with a tunnel. You can determine that who can access your models.
            </Paragraph>
        </>
    );
}
