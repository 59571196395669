import { ModelparkLogo, ModelparkLogo19 } from '@model-park/assets';
import Styles from './Styles.module.scss';
import { RoutingMap } from '@app/entities';
import { ItemType } from 'antd/es/menu/hooks/useItems';
import { Link } from 'react-router-dom';

export default function FooterSection() {
    return (
        <div className={Styles.Footer}>
                <div className={Styles.LogoSection}>
                    <ModelparkLogo className={Styles.Logo} />
                    <div className={Styles.TextWrapper}>
                        <span>&copy;ModelPark 2024 </span>
                        <span> | All Rights Reserved</span>
                    </div>
                </div>
                <div className={Styles.LinkSection}>
                    {MenuItems.map(item => (
                        <Link
                            to={item.key}
                            className={Styles.Link}
                            key={item.key}
                        >
                            {item.label}
                        </Link>
                    ))}
                </div>
            </div>
    );
}
const MenuItems: MenuItemType[] = [
    {
        key: RoutingMap.Home,
        label: 'Apps',
        title: 'Home',
    },
    {
        key: RoutingMap.NewProject,
        label: 'New Project',
        title: 'New Project',
    },
    {
        key: RoutingMap.Distribution,
        label: 'Distribution',
        title: 'Distribution',
    },
    {
        key: RoutingMap.Analytcis,
        label: 'Analytics',
        title: 'Analytics',
    },
    {
        key: RoutingMap.About,
        label: 'About',
        title: 'About',
    },
    {
        key: RoutingMap.Settings.Profile,
        label: 'Settings',
        title: 'Settings',
    },
    {
        key: RoutingMap.LegalClaimer,
        label: 'Legal Disclaimer ',
        title: 'Legal Disclaimer',
    },
];

type MenuItemType = ItemType & { key: string; label?: string };
