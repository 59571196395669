import { _compact, _entries } from '../../utils';

export class URLHelper extends URL {
    constructor(url: string, queryParams?: Dictionary<string | undefined> | URLSearchParams, base?: string | URL) {
        const _url = _compact([base, url]).join('');
        super(_url);

        if (queryParams) {
            if (queryParams instanceof URLSearchParams) {
                URLHelper.setQueryParams(this.searchParams, URLHelper.queryParamsToDict(queryParams));
            } else {
                URLHelper.setQueryParams(this.searchParams, queryParams);
            }
        }
    }

    public get portFromScheme(): number {
        switch (this.protocol) {
            case 'http:':
                return 80;
            case 'https:':
                return 443;
            default:
                return 80;
        }
    }

    public static queryParamsToDict(params: URLSearchParams): Dictionary<string> {
        const result: Record<string, string> = {};
        for (const [key, value] of params) {
            result[key] = value;
        }
        return result;
    }

    public static setQueryParams(params: URLSearchParams, newParams: Dictionary<string | undefined>): void {
        for (const [key, value] of _entries(newParams)) {
            if (value === null) params.delete(key);
            if (!value) return;
            params.set(key, value);
        }
    }
}
