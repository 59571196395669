import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { AuthStateFactory, IAuthState } from '../entities';

export const AuthSlice = createSlice({
    name: 'Auth',
    initialState: AuthStateFactory(),
    reducers: {
        updateAuthState(state, { payload }: PayloadAction<Partial<IAuthState>>) {
            Object.assign(state, payload);
        },
    },
});

export const { updateAuthState } = AuthSlice.actions;

export * from './selectors';
