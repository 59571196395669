import { MPAPISlice } from '@app/services';
import { DistFile } from '../entities/types';

export const extendedApiSlice = MPAPISlice.injectEndpoints({
    endpoints: builder => ({
        getDistList: builder.query<Array<DistFile>, undefined>({
            query: () => ({
                url: window.origin + '/dist-folder/',
                headers: {
                    Accept: 'application/json',
                },
            }),
        }),
    }),
});

export const { useGetDistListQuery } = extendedApiSlice;
