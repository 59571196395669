import { NavigateOptions } from "react-router-dom";
import { _entries } from "../../utils";
import { RouteParameters } from "./interface";

export class CommonNavigationHelper {

    public static navigate: (path: string, options?: NavigateOptions) => void;

    public static returnPayload(uri: string, query?: Dictionary) {
        uri = this.getUriWithParams(uri, query);
        return {
            uri,
            navigate: (options?: NavigateOptions) => this.navigate(uri, options)
        };
    }

    private static getUriWithParams(path: string, query: Dictionary = {}) {
        const _params = (new URLSearchParams(query)).toString();

        if (!_params || _params == "") return path;

        return path + "?" + _params;
    }

    public static replaceNavigationParams<T extends string>(uri: T, params?: RouteParameters<T>) {
        let path: string = uri;
        _entries(params).forEach(([key, value]) => {
            path = path.replace(`:${key as any}`, value as any);
        });

        return path;
    }

    public static getAppUri({ appName, host, params, protocol = "https:" }: AppUriParams) {
        return CommonNavigationHelper.getUriWithParams(`${protocol}//${appName}.${host}`, params).toString();
    }
}

export type { RouteParameters };

export interface AppUriParams {
    appName: string;
    params?: Dictionary;
    protocol?: string;
    host?: string;
}