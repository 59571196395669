import { AppRegistrationTypeOptions, ProjectFormValues } from '@model-park/common';
import { MPForm, MPSelect } from '@model-park/mp-components';
import { Col, Form, FormInstance, Row } from 'antd';
import AccessManagement from './AccessManagement';
import AiModelProperties from './AiModelProperties';
import AppInfo from './AppInfo';
import CoverImageForm from './CoverImageForm';
import { LegalDisclaimer } from './LegalDisclaimer';
import PermissionsTable from './PermissionsTable';
import RegistrationConfig from './RegistrationConfig';
import Styles from './Styles.module.scss';
import Subscription from './Subscription';
import Tags from './Tags';

export function ProjectForm({ form, isLoading, onFinish, currentAppName }: IProps) {
    return (
        <>
            <MPForm<ProjectFormValues>
                onFinish={onFinish}
                layout="vertical"
                loading={isLoading}
                form={form}
                className={Styles.ProjectForm}
                initialValues={{
                    registrationType: 'uri-registration',
                }}
            >
                <Row gutter={24}>
                    <Col>
                        <CoverImageForm />
                    </Col>

                    <Col flex={'auto'}>
                        <AppInfo currentAppName={currentAppName} />
                    </Col>
                </Row>

                <AiModelProperties />

                <Tags />

                <Form.Item
                    name={'registrationType'}
                    label={'Registration Type'}
                    hidden
                >
                    <MPSelect options={AppRegistrationTypeOptions} />
                </Form.Item>

                <RegistrationConfig />

                <Subscription />

                <AccessManagement />

                <PermissionsTable />

                <LegalDisclaimer />
            </MPForm>
        </>
    );
}

interface IProps {
    onFinish: (values: ProjectFormValues) => Promise<void>;
    isLoading: boolean;
    form: FormInstance<ProjectFormValues>;
    currentAppName?: string;
}
