import { Gradio, Streamlit } from '@assets/icons';
import { DateFormat } from '@model-park/common';
import { AIModelCategoryMap, AIModelFramework, AIModelTaskMap, AppProjectTypeMap, LinkStatus, LinkStatusMap } from '@model-park/entities';
import { IAppProject } from '@model-park/models';
import { MPTooltip } from '@model-park/mp-components';
import cs from 'classnames';
import dayjs from 'dayjs';
import ConditionallyRender from 'react-conditionally-render';
import Styles from './Styles.module.scss';
import { Tag } from 'antd';

export default function Header({ app, title }: IProps) {
    if (!app) return <h1>{title}</h1>;

    return (
        <div className={Styles.Header}>
            <div className={Styles.HeaderTop}>
                <h1>
                    {title}

                    <div className={Styles.Date}>
                        Added by {app?.ownerName} on {dayjs(app?.createdAt).format(DateFormat.Default)}
                    </div>
                </h1>

                <div className={Styles.AppInfo}>
                    <ConditionallyRender
                        condition={Boolean(app?.appType)}
                        show={
                            <MPTooltip
                                title={renderTooltip('App Type', AppProjectTypeMap[app?.appType])}
                                placement="top"
                            >
                                <div className={Styles.AppType}>
                                    <span>{AppProjectTypeMap[app?.appType]}</span>
                                </div>
                            </MPTooltip>
                        }
                    />

                    <MPTooltip title={renderTooltip('Category', AIModelCategoryMap[app?.aiModelProperties?.category])}>
                        <div
                            className={cs(Styles.Property, Styles.AppCategory)}
                            style={tagColorMap?.[app?.aiModelProperties?.category]}
                        >
                            {AIModelCategoryMap[app?.aiModelProperties?.category]}
                        </div>
                    </MPTooltip>

                    <MPTooltip title={renderTooltip('Task', AIModelTaskMap[app?.aiModelProperties?.task])}>
                        <div
                            className={cs(Styles.Property, Styles.AppCategory)}
                            style={tagColorMap?.[app?.aiModelProperties?.task]}
                        >
                            {AIModelTaskMap[app?.aiModelProperties?.task]}
                        </div>
                    </MPTooltip>

                    <div
                        className={cs(Styles.AccessTag, Styles.Property, {
                            [Styles.Public]: app?.accessManagement?.access === 'public',
                        })}
                    >
                        {app?.accessManagement?.access}
                    </div>

                    <div
                        className={cs(Styles.Property, Styles.Status, {
                            [Styles.Online]: app.linkStatus === LinkStatus.ONLINE,
                            [Styles.Offline]: app.linkStatus === LinkStatus.OFFLINE,
                            [Styles.Error]: app.linkStatus === LinkStatus.ERROR,
                        })}
                    >
                        {LinkStatusMap[app.linkStatus]}
                    </div>

                    <ConditionallyRender
                        condition={Boolean(FrameworkIconMap[app?.aiModelProperties?.framework])}
                        show={
                            <MPTooltip
                                title={renderTooltip('Framework', app?.aiModelProperties?.framework)}
                                placement="bottom"
                            >
                                <div className={Styles.FrameworkIcon}>{FrameworkIconMap[app?.aiModelProperties?.framework]}</div>
                            </MPTooltip>
                        }
                    />
                    <ConditionallyRender
                        condition={Boolean(app?.tags)}
                        show={
                            <div className={Styles.TagContainer}>
                                {app.tags?.map(tag => (
                                    <MPTooltip
                                        key={tag}
                                        title={renderTooltip('Tag', tag)}
                                        placement="bottom"
                                    >
                                        <div className={Styles.Tag}>
                                            <Tag color="blue">{tag}</Tag>
                                        </div>
                                    </MPTooltip>
                                ))}
                            </div>
                        }
                    />
                </div>
            </div>
            <div className={Styles.Desc}>{app.description}</div>
        </div>
    );
}

function renderTooltip(title: string, content: string) {
    return `${title}: ${content}`;
}

const FrameworkIconMap = {
    [AIModelFramework.Gradio]: <Gradio />,
    [AIModelFramework.Streamlit]: <Streamlit />,
};

const tagColorMap: Dictionary<React.CSSProperties> = {
    other: { backgroundColor: '#FF5C00', color: '#fff' },
    nlp: { backgroundColor: '#634BDD', color: '#fff' },
    classification: { backgroundColor: '#FF5C00', color: '#fff' },
    detection: { backgroundColor: '#401BA6', color: '#fff' },
    cv: { backgroundColor: '#634BDD', color: '#fff' },
};

interface IProps {
    title: string;
    app?: IAppProject;
}
