import { useAppSelector } from '@common';
import { MPGrid, MPPagination } from '@model-park/mp-components';
import { selectActiveDashboardPanelKey, selectFeaturedListSize } from '@pages/MainDashBoard/store';
import { useMemo, useState } from 'react';
import AppCardModal from '../AppCardModal';
import AppCard from './AppCard';
import Styles from './Styles.module.scss';
import { usePagination } from './usePagination';

export default function AppGallery() {
    const { data, isLoading, total, currentPage, setCurrentPage, pageSize, setPageSize } = usePagination();
    const [selectedItem, setSelectedItem] = useState<string | null>(null);
    const width = useAppSelector(selectFeaturedListSize);
    const activePanel = useAppSelector(selectActiveDashboardPanelKey);

    const filteredItem = useMemo(() => data?.find(item => selectedItem && item._id === selectedItem), [selectedItem, data]);

    return (
        <div className={Styles.AppGallery}>
            <MPGrid
                className={Styles.AppGalleryGrid}
                gridOptions={gridOptions}
                loading={isLoading}
                size={activePanel ? undefined : { width }}
            >
                {data?.map(item => (
                    <AppCard
                        key={item._id}
                        item={item}
                        handleModalOpen={setSelectedItem}
                    />
                ))}
            </MPGrid>

            {data?.length ? (
                <MPPagination
                    total={total}
                    current={currentPage}
                    onChange={setCurrentPage}
                    onShowSizeChange={(_, size) => setPageSize(size)}
                    pageSizeOptions={[10, 20, 30, 40, 50]}
                    pageSize={pageSize}
                    showSizeChanger
                    showTotal={total => (
                        <span>
                            Total <span style={{ fontWeight: 'bold' }}>{total}</span> apps
                        </span>
                    )}
                />
            ) : null}

            <AppCardModal
                setSelectedItem={setSelectedItem}
                item={filteredItem}
            />
        </div>
    );
}

const gridOptions = {
    xs: { gap: 24, columns: 1 },
    sm: { gap: 16, columns: 2 },
    md: { gap: 16, columns: 3 },
    lg: { gap: 24, columns: 3 },
    xl: { gap: 24, columns: 4},
    xxl: { gap: 24, columns: 5 },
};
