import { ICustomFormElementProps } from '@model-park/common';
import { MPButton, MPModal } from '@model-park/mp-components';
import LegalClaimer from '@pages/Authentication/views/LegalClaimer';
import { Checkbox, Form } from 'antd';
import { useState } from 'react';

export function LegalDisclaimer() {
    return (
        <Form.Item
            name="legaldisclaimer"
            rules={[{ required: true, message: 'Please accept legal disclaimer' }]}
        >
            <LegalDisclaimerForm />
        </Form.Item>
    );
}

function LegalDisclaimerForm({ onChange, value }: ICustomFormElementProps<boolean>) {
    const [isModalOpen, setIsModalOpen] = useState(false);

    const showModal = () => {
        setIsModalOpen(true);
    };
    const handleOk = () => {
        onChange?.(true);
        setIsModalOpen(false);
    };

    const handleCancel = () => {
        setIsModalOpen(false);
    };

    return (
        <>
            <Checkbox
                checked={value}
                onChange={e => (value ? onChange?.(false) : showModal())}
            >
                I have read and agree with the
            </Checkbox>
            <MPButton
                style={{ display: 'contents' }}
                type="link"
                onClick={showModal}
            >
                Legal Disclaimer
            </MPButton>
            <MPModal
                open={isModalOpen}
                onOk={handleOk}
                onCancel={handleCancel}
                width={800}
            >
                <LegalClaimer />
            </MPModal>
        </>
    );
}
