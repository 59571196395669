import { useGetAppProjectsQuery } from '@app/services';
import { useAppSelector } from '@common';
import { AppProjectQueryParams, SortParamKey, _compact, useQueryState } from '@model-park/common';
import { AppFilterType, NamespaceStatus } from '@model-park/entities';
import { selectFeaturedListActiveTabKey } from '@pages/MainDashBoard/store';
import { useMemo, useState } from 'react';

export function usePagination() {
    const [searchValue] = useQueryState('search');
    const [sortBy] = useQueryState<SortParamKey>('sort', 'createByDesc');
    const [ownerFilter] = useQueryState<AppFilterType.MY_APPS | undefined>('ownerFilter');
    const [statusFilter] = useQueryState<AppFilterType.ONLINE | undefined>('statusFilter');
    const [appTypeFilter] = useQueryState<AppFilterType | undefined>('appTypeFilter');
    const activeTab = useAppSelector(selectFeaturedListActiveTabKey);

    const [currentPage, setCurrentPage] = useState(1);
    const [pageSize, setPageSize] = useState(20);

    const params: AppProjectQueryParams = useMemo(
        () => ({
            sort: sortBy,
            search: searchValue ?? '',
            limit: pageSize,
            skip: (currentPage - 1) * pageSize,
            filter: _compact([activeTab, ownerFilter, statusFilter]) as Array<AppFilterType>,
            namespaceStatus: NamespaceStatus.active,
            appType: appTypeFilter,
        }),
        [activeTab, currentPage, ownerFilter, pageSize, searchValue, sortBy, statusFilter, appTypeFilter]
    );

    const { data, isLoading, isFetching } = useGetAppProjectsQuery(params);

    return {
        isLoading: isLoading || isFetching,
        data: data?.data,
        total: data?.count,
        currentPage,
        setCurrentPage,
        pageSize,
        setPageSize,
    };
}
