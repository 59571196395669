import { MPAPISlice } from '@app/services';
import { AppSlice } from '@app/store';
import { AuthSlice } from '@pages/Authentication/store';
import { MainDashboardSlice } from '@pages/MainDashBoard/store';
import { configureStore } from '@reduxjs/toolkit';
import { setupListeners } from '@reduxjs/toolkit/dist/query';

export const store = configureStore({
    reducer: {
        [MPAPISlice.reducerPath]: MPAPISlice.reducer,
        app: AppSlice.reducer,
        auth: AuthSlice.reducer,
        mainDashboard: MainDashboardSlice.reducer,
    },
    middleware: getDefaultMiddleware => getDefaultMiddleware().concat(MPAPISlice.middleware),
});

// Infer the `RootState` and `AppDispatch` types from the store itself
export type RootState = ReturnType<typeof store.getState>;
// Inferred type: {posts: PostsState, comments: CommentsState, users: UsersState}
export type AppDispatch = typeof store.dispatch;

export default store;

setupListeners(store.dispatch);
