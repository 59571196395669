import { GithubFilled, TwitterCircleFilled } from '@ant-design/icons';
import { Organization } from '@model-park/models';
import { MPForm, MPInput, MPSelect, MPTextArea } from '@model-park/mp-components';
import { Col, Form, Row, Space } from 'antd';
import { FormInstance } from 'antd/lib';
import ProfilePicture from '../../Profile/ProfilePicture';
import Styles from './../Styles.module.scss';

export default function OrganizationForm({ form, isLoading, onFinish, btnText }: IProps) {
    return (
        <div>
            <MPForm<Organization>
                layout="vertical"
                submitBtnText={btnText}
                submitclassName={Styles.SubmitButton}
                onFinish={onFinish}
                loading={isLoading}
                form={form}
            >
                <Row gutter={24}>
                    <Col
                        span={24}
                        className={Styles.ProfilePicture}
                    >
                        <Form.Item name={'profilePicture'}>
                            <ProfilePicture
                                width={100}
                                height={100}
                            />
                        </Form.Item>
                    </Col>
                    <Col span={12}>
                        <Form.Item
                            name={'name'}
                            label={'Organization Username'}
                            rules={[
                                {
                                    pattern: /^[a-zA-Z0-9-]+$/,
                                    message: 'Organization Username must be alphanumeric',
                                },
                                {
                                    required: true,
                                    message: 'Please enter a Organization Username',
                                },
                            ]}
                        >
                            <MPInput placeholder="UserName" />
                        </Form.Item>
                    </Col>
                    <Col span={12}>
                        <Form.Item
                            name={'fullName'}
                            label={'Organization Full name'}
                            rules={[
                                {
                                    required: true,
                                    message: 'Please enter a Organization Full name',
                                },
                            ]}
                        >
                            <MPInput placeholder="Full name" />
                        </Form.Item>
                    </Col>
                    <Col span={12}>
                        <Form.Item
                            name={'organizationType'}
                            label={'Organization Type'}
                            rules={[{ required: true, message: 'Please select a type' }]}
                        >
                            <MPSelect options={OrganizationType} />
                        </Form.Item>
                    </Col>
                    <Col span={12}>
                        <Form.Item
                            name={'homePage'}
                            label={'Home Page'}
                            rules={[
                                {
                                    type: 'url',
                                    message: 'The input is not valid url',
                                },
                            ]}
                        >
                            <MPInput placeholder="Homepage" />
                        </Form.Item>
                    </Col>

                    <Col span={12}>
                        <Form.Item
                            name={'github'}
                            label={
                                <Space>
                                    <GithubFilled />
                                    <div>GitHub Username</div>
                                </Space>
                            }
                        >
                            <MPInput placeholder="GitHub alias" />
                        </Form.Item>
                    </Col>
                    <Col span={12}>
                        <Form.Item
                            name={'twitter'}
                            label={
                                <Space>
                                    <TwitterCircleFilled />
                                    <div>Twitter Username</div>
                                </Space>
                            }
                        >
                            <MPInput placeholder="Twitter account" />
                        </Form.Item>
                    </Col>

                    <Col span={24}>
                        <Form.Item
                            name={'interest'}
                            label={'AI & ML interests '}
                        >
                            <MPTextArea
                                placeholder="Share your interests in AI & ML, e.g., neural networks, data analysis..."
                                size="large"
                                maxLength={200}
                                showCount
                                autoSize={{ minRows: 3 }}
                            />
                        </Form.Item>
                    </Col>
                </Row>
            </MPForm>
        </div>
    );
}

const OrganizationType = [
    { label: 'Company', value: 'company' },
    { label: 'Universty', value: 'universty' },
    { label: 'Classroom', value: 'classroom' },
    { label: 'Non-profit', value: 'non-profit' },
    { label: 'Community', value: 'community' },
];

interface IProps {
    onFinish?: (values: Organization) => Promise<void>;
    isLoading: boolean;
    form?: FormInstance<Organization>;
    btnText?: string;
}
