import { NavigationHelper } from '@common';
import { useCreateNewOrganizationMutation } from '../../services/UserService';
import Styles from './../Styles.module.scss';
import OrganizationForm from './OrganizationForm';

export default function CreateOrganization() {
    const [createOrganization, { isLoading }] = useCreateNewOrganizationMutation();

    const onFinish = async (values: any) => {
        try {
            await createOrganization(values).unwrap();
            NavigationHelper.Settings.Organizations().navigate();
        } catch (error) {
            console.log(error);
        }
    };

    return (
        <div>
            <h1 className={Styles.Title}>New Organization</h1>
            <OrganizationForm
                isLoading={isLoading}
                onFinish={onFinish}
                btnText="Create New Organization"
            />
        </div>
    );
}
