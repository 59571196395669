import { RoutingMap } from '@app/entities';
import { selectIsAppInit } from '@app/store/selectors';
import { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';
import { selectAuthToken } from '../store/selectors';

export function useCheckAuthToken() {
    const authToken = useSelector(selectAuthToken);
    const isAppInit = useSelector(selectIsAppInit);

    const navigate = useNavigate();
    const location = useLocation();

    useEffect(() => {
        if (isAppInit && authToken) {
            const _next = location?.state?.from?.pathname || RoutingMap.Home;

            navigate(_next, { state: { from: location } });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isAppInit, authToken]);
}
