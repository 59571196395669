import { Table, Typography } from 'antd';

const { Title, Paragraph } = Typography;

export default function Login() {
    return (
        <>
            <Title level={4}>Login:</Title>
            <Paragraph>Login with your</Paragraph>
            <Typography>
                <code>modelpark login</code>
            </Typography>
            <Title level={5}>Options:</Title>
            <Table
                columns={loginColumns}
                dataSource={loginData}
                pagination={false}
                scroll={{ x: 'max-content' }}
            />
        </>
    );
}

const loginColumns = [
    {
        title: 'Name',
        dataIndex: 'name',
        key: 'name',
    },
    {
        title: 'Type',
        dataIndex: 'type',
        key: 'type',
    },
    {
        title: 'Default value',
        dataIndex: 'defaultValue',
        key: 'defaultValue',
    },
];

const loginData = [
    {
        key: '1',
        name: '--token, -t (optional)',
        type: 'string',
        defaultValue: 'undefined',
    },
    {
        key: '2',
        name: '--username, -u (optional)',
        type: 'string',
        defaultValue: 'undefined',
    },
    {
        key: '3',
        name: '--password, -p (optional)',
        type: 'string',
        defaultValue: 'undefined',
    },
];
