import { useGetRegistrationTypeStatsQuery } from '@app/services';
import { AppProjectStats } from '@model-park/entities';
import { ResponsivePie } from '@nivo/pie';
import Styles from './Styles.module.scss';
import { useWindowSize } from 'react-use';

export default function AnalyticChart({ chartKey }: { chartKey: keyof AppProjectStats }) {
    const { data = {} } = useGetRegistrationTypeStatsQuery(undefined);
    const { width } = useWindowSize();
    const isMobileSize = width < 500;

    return (
        <div className={Styles.ChartContainer}>
            <ResponsivePie
                data={data?.[chartKey] || []}
                margin={
                    isMobileSize ? { top: 20, right: 40, bottom: 80, left: 40 } : { top: 40, right: 80, bottom: 80, left: 80 }
                }
                colors={['#00C7F2', '#6AE942', '#C7E942', '#C7E942']}
                innerRadius={0.4}
                padAngle={0.7}
                cornerRadius={3}
                activeOuterRadiusOffset={13}
                borderWidth={1}
                borderColor={{
                    from: 'color',
                    modifiers: [['darker', 0.5]],
                }}
                enableArcLinkLabels={!isMobileSize}
                arcLinkLabel="label"
                arcLinkLabelsSkipAngle={10}
                arcLinkLabelsTextColor="#333333"
                arcLinkLabelsThickness={2}
                arcLinkLabelsStraightLength={15}
                arcLinkLabelsColor={{ from: 'color' }}
                arcLabelsSkipAngle={10}
                arcLabelsTextColor={{
                    from: '#333333',
                    modifiers: [['darker', 2]],
                }}
                legends={[
                    {
                        anchor: isMobileSize ? 'bottom-left' : 'bottom',
                        direction: isMobileSize ? 'column' : 'row',

                        justify: false,
                        translateX: 0,
                        translateY: isMobileSize ? 80 : 70,
                        itemsSpacing: isMobileSize ? 0 : 12,
                        itemWidth: 114,
                        itemHeight: isMobileSize ? 20 : 30,
                        itemTextColor: '#999',
                        itemDirection: 'left-to-right',
                        itemOpacity: 1,
                        symbolSize: isMobileSize ? 12 : 18,
                        symbolShape: 'circle',
                        effects: [
                            {
                                on: 'hover',
                                style: {
                                    itemTextColor: '#000',
                                },
                            },
                        ],
                    },
                ]}
            />
        </div>
    );
}
