import { Drawer } from "antd";
import cs from "classnames";
import Styles from "./Styles.module.scss";
import { DrawerProps } from "antd/lib";

export function MPDrawer({ className, ...props }: IMPDrawerProps ) {

    return(
        <Drawer
            className={cs(Styles.MPDrawerContainer, className)}
            {...props}
        />
    );
}

export interface IMPDrawerProps extends DrawerProps {}
