import { IModalState } from "@app/entities/interface";
import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { AppStateFactory, IAppState } from "../entities";

export const AppSlice = createSlice({
    name: "App",
    initialState: AppStateFactory(),
    reducers: {
        updateAppState(state, { payload }: PayloadAction<Partial<IAppState>>) {
            Object.assign(state, payload);
        },

        updateActiveModalState(state, { payload }: PayloadAction<IModalState>) {
            state.commonModals[payload.name] = payload;
        },
        updateTheme(state, { payload }: PayloadAction<"light" | "dark">) {
            state.theme = payload;

            if (typeof window !== "undefined") {
                localStorage.setItem("theme", payload);
            }
        }
    }
});

export const { updateAppState, updateActiveModalState, updateTheme } = AppSlice.actions;
