import { MPDropDown } from '@model-park/mp-components';
import MainDashboardExtraContent from '../MainDashboardExtraContent';
import { FilterOutlined } from '@ant-design/icons';
import styles from './styles.module.scss';
import { useState } from 'react';

export default function MobileViewExtraContent({ setView }: { setView: any }) {
    const [open, setOpen] = useState(false);
    return (
        <MPDropDown
            dropdownRender={() => <MainDashboardExtraContent setView={setView} />}
            onOpenChange={setOpen}
            open={open}
            trigger={['click']}
            placement="bottomRight"
            overlayClassName={styles.MobileViewExtraContent}
        >
            <div className={styles.FilterBtn}>
                <FilterOutlined />
            </div>
        </MPDropDown>
    );
}
