import { Table, TableProps } from 'antd';
import cs from 'classnames';
import Styles from './Styles.module.scss';

export function MPTable<T extends object>({ className, ...props }: IMPTableProps<T>) {
    return (
        <Table
            className={cs(Styles.MPTable, className)}
            {...props}
        />
    );
}

export interface IMPTableProps<T> extends TableProps<T> {}
