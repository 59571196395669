import { NavigationHelper } from '@common/helpers';
import { _entries, _mapKeys, _mapValues } from '@model-park/common';
import { IMPBreadcrumbProps, MPBreadcrumb } from '@model-park/mp-components';
import cs from 'classnames';
import Styles from "./Styles.module.scss";

export function Breadcrumb({ breadcrumbNameChangeMap, className, ...props }: IBreadcrumbProps) {

    let nameMap = Object.assign({}, NavigationHelper?.BreadCrumbNameMap);
    _entries(breadcrumbNameChangeMap).forEach(([key, value]) => {
        nameMap = _mapKeys(nameMap, (_, path) => path?.includes(key) ? path.replace(key, value) : path);
        nameMap = _mapValues(nameMap, name => name?.includes(key) ? name.replace(key, value) : name);
    });

    return (
        <>
            <MPBreadcrumb
                nameMap={nameMap}
                className={cs(Styles?.Breadcrumb, className)}
                useDefault
                {...props}
            />
        </>
    );
}

export interface IBreadcrumbProps extends IMPBreadcrumbProps {
    breadcrumbNameChangeMap?: Dictionary<string>;
}