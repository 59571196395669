import { useToggleAppFavoriteMutation } from '@app/services';
import { AppProjectClone, useAppDispatch, useAppProjectAbility, useAppSelector, useAppUri, useCoverPicture } from '@common';
import { AccessAction } from '@model-park/entities';
import { IAppProject } from '@model-park/models';
import { selectAuthToken } from '@pages/Authentication/store';
import { setEditAppName, setEditDrawerOpen } from '@pages/MainDashBoard/store';
import React from 'react';

export default function useAppCard(item: IAppProject, handleModalOpen?: (id: string | null) => void) {
    const dispatch = useAppDispatch();
    const appUri = useAppUri(item?.name);
    const authToken = useAppSelector(selectAuthToken);
    const [toggleAppFavorite] = useToggleAppFavoriteMutation();
    const ability = useAppProjectAbility();

    const canEdit = ability?.can?.(AccessAction.UPDATE, new AppProjectClone(item) as any) || false;

    function handleAppFavorite(event: React.MouseEvent) {
        event.stopPropagation();
        toggleAppFavorite(item?._id);
    }

    function openModal(event: React.MouseEvent) {
        event.stopPropagation();
        handleModalOpen?.(item._id);
    }

    function goToApp(event: React.MouseEvent) {
        event.stopPropagation();
        window.open(appUri, '_blank');
    }
    function handleEditDrawerOpen(event: React.MouseEvent) {
        event.stopPropagation();
        dispatch(setEditAppName(item.name));
        dispatch(setEditDrawerOpen(true));
    }

    return { goToApp, openModal, appUri, handleEditDrawerOpen, authToken, handleAppFavorite, canEdit };
}
