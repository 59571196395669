import { URLHelper } from '@model-park/common';
import hfApi, { Params, Space } from '@modelpark/huggingface-hub-api';
import { useEffect } from 'react';
import { useMap } from 'react-use';

const baseUrl = window.location.origin + '/huggingface';
const hf = new hfApi(undefined, undefined, baseUrl + '/api');

export function useHFApi() {
    const [state, { set, setAll }] = useMap(new SpaceState());

    async function getHFSpaces(params: Params = {}, page = 1, pageSize = 10) {
        try {
            set('loading', true);

            let url: string | undefined;
            if (page < state.page) url = state.prevUrl;
            else if (page > state.page) url = state.nextUrl;

            const result = await hf.GetSpaces(
                {
                    full: true,
                    limit: pageSize,
                    ...params,
                },
                url
            );

            if (!result.data) return;

            let nextUrl: string | undefined;
            if (result.nextUrl) {
                const _nextUrl = new URL(result.nextUrl);
                nextUrl = new URLHelper(_nextUrl.pathname, _nextUrl.searchParams, baseUrl).toString();
            }

            setAll(new SpaceState(result.data, false, result.total, nextUrl, state.nextUrl, page));
        } catch (error) {
            console.log(error);
            set('loading', false);
        }
    }

    useEffect(() => {
        getHFSpaces();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return { getHFSpaces, tableState: state };
}

class SpaceState {
    constructor(
        public spaceList: Array<Space> = [],
        public loading = false,
        public total = 0,
        public nextUrl = '',
        public prevUrl = '',
        public page = 1
    ) {}
}
