import { List, ListProps } from 'antd';
import cs from 'classnames';
import Styles from './Styles.module.scss';

export function MPList<T extends object>({ className, ...props }: IMPListProps<T>) {
    return (
        <List
            className={cs(Styles.MPTable, className)}
            {...props}
        />
    );
}

export interface IMPListProps<T> extends ListProps<T> {}
