import { useGetAppProjectsQuery } from '@app/services';
import { AppTrackingsAPI } from '@app/services/AppTrackingsAPI';
import { selectCurrentUser } from '@app/store/selectors';
import { useAppDispatch, useAppSelector } from '@common';
import { useGetSearchParamValue, useUpdateSearchParams } from '@model-park/common';
import { AccessAction } from '@model-park/entities';
import { IMPSelectProps } from '@model-park/mp-components';

export function useAppSelection() {
    const dispatch = useAppDispatch();
    const user = useAppSelector(selectCurrentUser);
    const selectedApp = useGetSearchParamValue('app');
    const updateSearchParams = useUpdateSearchParams();
    const { options, isFetching } = useGetAppProjectsQuery(
        { slim: true, action: AccessAction.MANAGE },
        {
            skip: !user?._id,
            selectFromResult: ({ currentData, isFetching }) => ({
                options: currentData?.data?.map(({ title, _id }) => ({ label: title, value: _id })) || [],
                isFetching,
            }),
        }
    );
    function refetch() {
        if (!selectedApp || !user?._id) return;
        dispatch(AppTrackingsAPI.endpoints.getAppTrackingStats.initiate(selectedApp, { forceRefetch: true }));
    }

    function onChangeSelectedApp(app: string) {
        updateSearchParams({ app });
    }

    const filterOption: IMPSelectProps<string, OptionType>['filterOption'] = (
        input: string,
        option?: { label: string; value: string }
    ) => (option?.label ?? '')?.toLowerCase().includes(input.toLowerCase());

    return {
        selectedApp,
        onChangeSelectedApp,
        options,
        isFetching,
        refetch,
        filterOption,
    };
}

export interface OptionType {
    label: string;
    value: string;
}
