import { Modal, ModalProps } from 'antd';
import cs from 'classnames';
import Styles from './Styles.module.scss';

export function MPModal({ className, ...props }: IMPModalProps) {
    return (
        <>
            <Modal
                className={cs(Styles.MPModalContainer, className)}
                {...props}
            />
        </>
    );
}

export interface IMPModalProps extends ModalProps {}
