import { Helmet } from 'react-helmet-async';

export function Seo({ title, description, name, keywords }: SeoProps) {
    return (
        <Helmet>
            <title>{title}</title>
            <meta
                name="description"
                content={description}
            />
            <meta
                name="author"
                content={name}
            />
            <meta
                name="keywords"
                content={keywords}
            />
            <link
                rel="canonical"
                href={window.location.href}
            />

            <meta
                property="og:title"
                content={title}
            />
            <meta
                property="og:description"
                content={description}
            />
            <meta
                property="og:url"
                content={window.location.href}
            />
            <meta
                property="og:type"
                content="article"
            />

            <meta
                name="twitter:card"
                content="summary_large_image"
            />
            <meta
                name="twitter:creator"
                content={name}
            />
            <meta
                name="twitter:title"
                content={title}
            />
            <meta
                name="twitter:description"
                content={description}
            />

            <meta
                property="og:site_name"
                content={title}
            />
            <meta
                property="og:description"
                content={description}
            />

            <meta
                name="robots"
                content="index,follow"
            />

            {/* <meta name="twitter:image" content={imageUrl} /> */}
            {/* <meta name="image" content={imageUrl} /> */}
            {/* <meta property="og:image" content={imageUrl} /> */}
        </Helmet>
    );
}

interface SeoProps {
    title: string;
    description: string;
    name: string;
    keywords?: string;
}
