import { useCheckAuthMutation } from '@app/services';
import { updateAppState } from '@app/store';
import { getAuthToken, useAppDispatch } from '@common';
import { updateAuthState } from '@pages/Authentication/store';
import { useCallback, useEffect } from 'react';

export function useInitApp() {
    const dispath = useAppDispatch();
    const [checkAuth] = useCheckAuthMutation();

    const checkAuthentication = useCallback(async () => {
        const authToken = getAuthToken();
        try {
            if (!authToken) return;

            sessionStorage.setItem('authToken', authToken);

            const user = await checkAuth(undefined).unwrap();
            dispath(updateAuthState({ user, authToken }));
        } catch (error: any) {
            console.log({ error });
            if (error?.status == 401) {
                localStorage.removeItem('authToken');
                sessionStorage.removeItem('authToken');
            }
        } finally {
            if (new URLSearchParams(window.location.search).has('authToken')) {
                window.history.replaceState({}, document.title, window.location.pathname);
            }

            dispath(updateAppState({ isAppInit: true }));
        }
    }, [checkAuth, dispath]);

    useEffect(() => {
        checkAuthentication();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);
}
