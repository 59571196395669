import { Spin } from 'antd';
import Styles from './Styles.module.scss';

export function MPFallback() {
    return (
        <div className={Styles.FallbackContainer}>
            <Spin
                spinning
                size="large"
                className={Styles.Fallback}
            />
        </div>
    );
}
