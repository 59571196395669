import { useAppSelector } from '@common';
import { CommonNavigationHelper, _compactDict } from '@model-park/common';
import { selectAuthToken } from '@pages/Authentication/store/selectors';

export function useAppUri(appName?: string) {
    const authToken = useAppSelector(selectAuthToken);
    if (!appName) return '';
    return CommonNavigationHelper.getAppUri({
        appName,
        params: _compactDict({ authToken }),
        host: window.location.host,
        protocol: window.location.protocol,
    });
}
