import { Badge, BadgeProps } from 'antd';

export function MPBadge({ ...props }: MPStatusProps) {
    return (
        <>
            <Badge {...props} />
        </>
    );
}

export interface MPStatusProps extends BadgeProps {

}
