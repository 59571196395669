import { Copy, Edit, FavoriteIcon, Info } from '@assets/icons';
import { LinkStatus, LinkStatusMap } from '@model-park/entities';
import { IAppProject } from '@model-park/models';
import { MPTooltip } from '@model-park/mp-components';
import { Typography } from 'antd';
import cs from 'classnames';
import ConditionallyRender from 'react-conditionally-render';
import Styles from './Styles.module.scss';
import useAppCard from './useAppCard';
import { FileSearchOutlined } from '@ant-design/icons';

export interface IProps {
    item: IAppProject;
    handleModalOpen?: (id: string | null) => void;
}

export default function IconSection({ item , handleModalOpen }: IProps) {
    const { appUri, handleEditDrawerOpen, openModal, authToken, handleAppFavorite, canEdit } = useAppCard(item, handleModalOpen);

    return (
        <div className={Styles.IconSection}>
            <div className={Styles.IconWrapper}>
                <MPTooltip
                    placement="bottomLeft"
                    title="Copy the URL"
                >
                    <Typography.Paragraph
                        copyable={{
                            icon: <Copy />,
                            tooltips: false,
                            text: appUri,
                        }}
                        className={Styles.CopyIcon}
                    ></Typography.Paragraph>
                </MPTooltip>

                <div className={Styles.Icon}>
                    <MPTooltip
                        placement="bottom"
                        title={item?.description}
                    >
                        <Info />
                    </MPTooltip>
                </div>
                <ConditionallyRender
                    condition={canEdit}
                    show={
                        <div className={Styles.Icon}>
                            <MPTooltip
                                placement="bottomRight"
                                title="Edit App"
                            >
                                <Edit onClick={handleEditDrawerOpen} />
                            </MPTooltip>
                        </div>
                    }
                />
                <ConditionallyRender
                    condition={Boolean(authToken)}
                    show={
                        <div
                            className={Styles.FavoriteIcon}
                            onClick={handleAppFavorite}
                        >
                            <MPTooltip
                                placement="bottomRight"
                                title={item?.isFavorite ? 'Remove from favorites' : 'Add to favorites'}
                            >
                                <FavoriteIcon className={item?.isFavorite ? Styles.Favorite : ''} />
                            </MPTooltip>
                        </div>
                    }
                />

                <MPTooltip
                    placement="bottomRight"
                    title="See Details"
                    className={Styles.Icon}
                >
                    <FileSearchOutlined
                        onClick={openModal}
                        className={Styles.FileSerach}
                    />
                </MPTooltip>
            </div>
            <div className={Styles.RightCornerIcons}>
                <div className={cs(Styles.AccessTag, { [Styles.Public]: item?.accessManagement?.access === 'public' })}>
                    {item?.accessManagement?.access}
                </div>
                <div
                    className={cs(Styles.Status, {
                        [Styles.Online]: item.linkStatus === LinkStatus.ONLINE,
                        [Styles.Offline]: item.linkStatus === LinkStatus.OFFLINE,
                        [Styles.Error]: item.linkStatus === LinkStatus.ERROR,
                    })}
                >
                    {LinkStatusMap[item.linkStatus]}
                </div>
            </div>
        </div>
    );
}
