import { useGetAppAccessQuery, useGetAppAccessTypeQuery } from '@app/services';
import { Errors } from '@model-park/entities';
import { MPFallback } from '@model-park/mp-components';
import AppAuthorization from './AppAuthorization';
import { AppError } from './AppError';
import AppFrame from './AppFrame';
import useAppname from './useAppname';

export function AppContent() {
    const { appName } = useAppname();

    const { accessToken, isError, error, refetch, isFetching } = useGetAppAccessQuery(appName, {
        skip: !appName,
        selectFromResult: ({ data, isError, isFetching, error }) => ({
            accessToken: data?.accessToken,
            isError,
            isFetching,
            error: error as any,
        }),
    });

    const { accessTypeFetching } = useGetAppAccessTypeQuery(appName, {
        skip: !appName,
        selectFromResult: ({ data, isFetching }) => ({
            accessType: data?.type,
            accessTypeFetching: isFetching,
        }),
    });

    const showAuth = [Errors.appForbidden, Errors.passwordRequired, Errors.passwordIncorrect].includes(error?.data?.message);

    if (isFetching || accessTypeFetching) return <MPFallback />;

    if (showAuth) return <AppAuthorization refetch={refetch} />;

    if (isError) return <AppError error={error?.data} />;

    if (!accessToken) return null;

    return <AppFrame accessToken={accessToken} />;
}
