import { getUserThemePrefers } from "@model-park/common";
import { IModalState, IAppState } from "./interface";

export function AppStateFactory(data?: IAppState): IAppState {

    return {
        isAppInit: data?.isAppInit || false,
        projectList: data?.projectList || [],
        commonModals: data?.commonModals || {},
        theme: data?.theme || getUserThemePrefers(),
    };
}

export function ActiveModalFactory(data?: IModalState): IModalState {

    return {
        name: data?.name || "",
        state: data?.state || {},
        open: data?.open || false,
    };
}