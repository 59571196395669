import { ProfileFormValues, UpdatePasswordFormValues, _entries, _isEqual } from '@model-park/common';
import { IUser } from '@model-park/models';
import { MPForm, MPPassword } from '@model-park/mp-components';
import { useUpdatePasswordMutation } from '@pages/Authentication/services/AuthService';
import { Form } from 'antd';
import { useCallback } from 'react';
import Styles from './Styles.module.scss';

export default function ChangePasswordForm() {
    const [form] = Form.useForm<UpdatePasswordFormValues>();
    const [updatePassword, { isLoading: updateLoading }] = useUpdatePasswordMutation();

    const onFinish = useCallback(
        async (values: UpdatePasswordFormValues) => {
            try {
                const payload = {
                    password: values.password,
                    newPassword: values.newPassword,
                    newPasswordConfirm: values.newPasswordConfirm,
                };

                const res = await updatePassword(payload);
                form.resetFields();
            } catch (error) {
                console.error(error);
            }
        },
        [updatePassword, form]
    );
    return (
        <MPForm<any>
            onFinish={onFinish}
            layout="vertical"
            loading={updateLoading}
            form={form}
            className={Styles.ProfileSettingForm}
            submitclassName={Styles.SubmitButton}
            submitBtnText="Update Password"
        >
            <h2>Change Password</h2>
            <Form.Item
                name="password"
                label="Old Password"
                rules={[
                    {
                        required: true,
                        message: 'Please input your old password!',
                    },
                ]}
            >
                <MPPassword placeholder="Old Password" />
            </Form.Item>
            <Form.Item
                name="newPassword"
                label="Password"
                rules={[
                    {
                        required: true,
                        message: 'Please input your password!',
                    },
                ]}
            >
                <MPPassword placeholder="Password" />
            </Form.Item>
            <Form.Item
                name="newPasswordConfirm"
                label="Confirm Password"
                dependencies={['password']}
                hasFeedback
                rules={[
                    {
                        required: true,
                        message: 'Please confirm your password!',
                    },
                    ({ getFieldValue }) => ({
                        validator(_, value) {
                            if (!value || getFieldValue('newPassword') === value) {
                                return Promise.resolve();
                            }
                            return Promise.reject(new Error('The two passwords that you entered do not match!'));
                        },
                    }),
                ]}
            >
                <MPPassword placeholder="Confirm Password" />
            </Form.Item>
        </MPForm>
    );
}
function getDifferences(payload: ProfileFormValues, data: IUser) {
    const differences: Partial<IUser> = {};
    _entries(payload).forEach(([key, value]) => {
        if (!_isEqual(value, data?.[key])) {
            (differences as any)[key] = value;
        }
    });

    return differences;
}
