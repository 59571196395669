import { Switch, SwitchProps } from 'antd';
import cs from 'classnames';
import Styles from './Styles.module.scss';

export default function MPSwitch({ className, label, ...props }: IMPSwitchProps) {
    return (
        <div className={Styles.SwitchWrapper}>
            {label ? <div className={Styles.Label}>{label}</div> : null}
            <Switch
                className={cs(Styles.MPSwitch, className)}
                {...props}
            />
        </div>
    );
}

export interface IMPSwitchProps extends SwitchProps {
    label?: string;
}
