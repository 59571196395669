import { MPForm, MPInput, MPSearch } from '@model-park/mp-components';
import { Form, FormInstance } from 'antd';
import Styles from "./Styles.module.scss";

export default function SearchForm({ form, onFinish }: IProps) {
    return (
        <>
            <MPForm
                form={form}
                onFinish={onFinish}
                className={Styles.FormContainer}
                layout="inline"
                submitBtnText="Search"
            >
                <Form.Item
                    name="search"
                    label="Search"
                    className={Styles.FormSerch}
                >
                    <MPSearch />
                </Form.Item>

                <Form.Item
                    name="author"
                    label="Author"
                    className={Styles.FormSerch}

                >
                    <MPInput />
                </Form.Item>
            </MPForm>
        </>
    );
}

interface IProps {
    form: FormInstance;
    onFinish: (values: any) => void;
}