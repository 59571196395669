import { selectCurrentUser } from '@app/store/selectors';
import { useAppSelector } from '@common/hooks';
import { BasicUserAppSubscriptionTypeOptions, PremiumUserAppSubscriptionTypeOptions } from '@model-park/common';
import { AppSubscriptionType, UserSubscriptionType } from '@model-park/entities';
import { MPInputNumber, MPSelect, MPSwitch } from '@model-park/mp-components';
import { Col, Form, Row } from 'antd';
import ConditionallyRender from 'react-conditionally-render';

export default function Subscription() {
    const user = useAppSelector(selectCurrentUser);
    const subscription = Form.useWatch<AppSubscriptionType>('subscription');
    const applyTimeout = Form.useWatch<boolean>('applyTimeout');

    return (
        <>
            <Row gutter={24}>
                <Col span={12}>
                    <Form.Item
                        name={'subscription'}
                        label={'Subscription'}
                        rules={[{ required: true, message: 'Please select a subscription type' }]}
                        initialValue={AppSubscriptionType.FREE}
                    >
                        <MPSelect
                            placeholder={'Select a subscription type'}
                            options={
                                user?.subscription === UserSubscriptionType.PREMIUM
                                    ? PremiumUserAppSubscriptionTypeOptions
                                    : BasicUserAppSubscriptionTypeOptions
                            }
                        />
                    </Form.Item>
                </Col>
                <Col>
                    <Form.Item
                        name={'applyTimeout'}
                        label={'Apply Timeout'}
                        valuePropName="checked"
                        initialValue={true}
                    >
                        <MPSwitch disabled={subscription === AppSubscriptionType.FREE} />
                    </Form.Item>
                </Col>

                <ConditionallyRender
                    condition={applyTimeout}
                    show={
                        <Col>
                            <Form.Item
                                name={'liveDuration'}
                                label={'Live Duration'}
                                rules={[
                                    {
                                        required: subscription === AppSubscriptionType.FREE,
                                        message: 'You need to specify a live duration in Free Subscription',
                                    },
                                ]}
                                initialValue={3}
                            >
                                <MPInputNumber
                                    min={1}
                                    max={subscription === AppSubscriptionType.FREE ? 3 : 999}
                                />
                            </Form.Item>
                        </Col>
                    }
                />
            </Row>
        </>
    );
}
