import { useCheckAppPasswordMutation } from '@app/services';
import { setAppPassword } from '@common';
import { isIncludeOrEqual } from '@model-park/common';
import { Errors } from '@model-park/entities';
import { MPForm, MPPassword } from '@model-park/mp-components';
import { Form } from 'antd';
import useAppname from '../useAppname';

export default function PasswordForm({ refetch }: IProps) {
    const [form] = Form.useForm<IAppPasswordForm>();
    const [checkAppPassword, { isLoading }] = useCheckAppPasswordMutation();
    const { appName } = useAppname();

    async function onFinish(values: IAppPasswordForm) {
        try {
            const { password } = values;

            await checkAppPassword({ password, name: appName }).unwrap();

            setAppPassword(appName, password);

            refetch();
        } catch (error: any) {
            console.error(error);
            if (isIncludeOrEqual(error?.data?.message, Errors.passwordIncorrect)) {
                form.setFields([
                    {
                        name: 'password',
                        errors: [Errors.passwordIncorrect],
                    },
                ]);
            }
        }
    }

    return (
        <>
            <MPForm
                onFinish={onFinish}
                layout="vertical"
                form={form}
                loading={isLoading}
            >
                <Form.Item
                    name="password"
                    label="Password"
                    rules={[
                        {
                            required: true,
                            message: 'Please input your password!',
                        },
                    ]}
                >
                    <MPPassword placeholder="Password" />
                </Form.Item>
            </MPForm>
        </>
    );
}

export interface IAppPasswordForm {
    password: string;
    remember: boolean;
}

interface IProps {
    refetch: () => void;
}
