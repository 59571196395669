import variables from '@app/assets/_variables.module.scss';
import { selectUserTheme } from '@app/store/selectors';
import { useAppSelector } from '@common';
import { ConfigProvider, theme } from 'antd';
import { ThemeConfig } from 'antd/es/config-provider/context';
import _merge from 'lodash/merge';
import { useMemo } from 'react';

export default function ThemeProvider({ children }: { children: React.ReactNode }) {
    // const userTheme = useAppSelector(selectUserTheme);

    // const currentTheme = useMemo(() => _merge(themeMap[userTheme], commonThemeConfig), [userTheme]);

    return <ConfigProvider theme={currentTheme}>{children}</ConfigProvider>;
}

const commonThemeConfig: ThemeConfig = {
    token: {
        colorPrimary: variables.colorPrimary,
        fontFamily: 'roboto, sans-serif',
    },
};

const darkThemeConfig: ThemeConfig = {
    token: {
        colorBgBase: variables.darkThemeColorBgBase,
        colorTextBase: variables.darkThemeColorTextBase,
        colorBorder: variables.darkThemeColorBorder,
        colorBorderSecondary: variables.darkThemeColorBorderSecondary,
    },
    components: {
        Pagination: {
            colorBgTextActive: variables.darkThemePaginationColorBgTextActive,
            colorPrimaryBorder: variables.darkThemePaginationColorPrimaryBorder,
            colorPrimary: variables.darkThemePaginationColorPrimary,
            colorPrimaryHover: variables.darkThemePaginationColorPrimaryHover,
        },
        Tabs: {
            titleFontSize: 20,
            inkBarColor: variables.darkThemeTabsActiveColor,
            itemActiveColor: variables.darkThemeTabsActiveColor,
            itemSelectedColor: variables.darkThemeTabsActiveColor,
            itemColor: variables.darkThemeTabsDefaultColor,
            itemHoverColor: variables.darkThemeTabsHoverColor,
        },
    },
    algorithm: theme.darkAlgorithm,
};

const lightThemeConfig: ThemeConfig = {
    token: {
        colorTextBase: variables.lightThemeColorTextBase,
        colorBgBase: variables.lightThemeColorBgBase,
        colorBgLayout: variables.lightThemeColorBgLayout,
        boxShadow: '0px 1px 28px 0px #DCDFF2',
    },
    components: {
        Layout: {
            colorBgBody: variables.lightThemeColorBgBody,
        },
        Tabs: {
            titleFontSize: 20,
            itemColor: variables.lightThemeTabsDefaultColor,
            inkBarColor: variables.lightThemeTabsActiveColor,
            itemActiveColor: variables.lightThemeTabsActiveColor,
            itemSelectedColor: variables.lightThemeTabsActiveColor,
            itemHoverColor: variables.lightThemeTabsHoverColor,
        },
        Input: {
            activeBorderColor: '#6AE942',
            hoverBorderColor: '#6AE942',
            activeShadow: '#6AE942',
        },
        Switch: {
            colorPrimary: '#6AE942',
            colorPrimaryHover: '#6AE942',
        },
    },
};

const themeMap = {
    dark: darkThemeConfig,
    light: lightThemeConfig,
};

const currentTheme = _merge(themeMap.light, commonThemeConfig);
