import { IModalState } from '@app/entities';
import { updateActiveModalState } from '@app/store';
import { getActiveModalSelector } from '@app/store/selectors';
import { useAppDispatch, useAppSelector } from '@common';
import { useCallback, useMemo } from 'react';

export function useModalState<T extends object>(name: string) {
    const modalState = useAppSelector(useMemo(() => getActiveModalSelector<T>(name), [name]));
    const dispatch = useAppDispatch();

    const updateModalState = useCallback(
        (newState: Partial<IModalState<T>>) => {
            dispatch(updateActiveModalState({
                name,
                ...newState,
            }));
        },
        [dispatch, name],
    );

    return [modalState, updateModalState] as const;
}
