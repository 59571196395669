import { MPFallback } from '@model-park/mp-components';
import { Layout } from 'antd';
import { Outlet } from 'react-router-dom';
import Styles from './Styles.module.scss';
import useLayoutContent from './useLayoutContent';

export default function LayoutContent() {
    const { isAppInit, colorBgBase } = useLayoutContent();

    if (!isAppInit) return <MPFallback />;

    return (
        <Layout.Content
            className={Styles.LayoutContent}
            style={{ background: colorBgBase }}
        >
            <div>
                <Outlet />
            </div>
        </Layout.Content>
    );
}
