export enum AppRegistration {
    EXTERNAL_RESOURCE = 'external-resource',
    URI_REGISTRATION = 'uri-registration',
    TUNNEL_REGISTRATION = 'tunnel-registration',
}
export const AppRegistrationMap = {
    [AppRegistration.EXTERNAL_RESOURCE]: 'External Resource',
    [AppRegistration.URI_REGISTRATION]: 'URI Registration',
    [AppRegistration.TUNNEL_REGISTRATION]: 'Tunnel Registration',
} as const;

export enum LinkStatus {
    ONLINE = 1,
    OFFLINE = 2,
    ERROR = 3,
}

export enum NamespaceStatus {
    active = 'active',
    reserved = 'reserved',
    archived = 'archived',
    removed = 'removed',
}

export const LinkStatusMap = {
    [LinkStatus.ONLINE]: 'Online',
    [LinkStatus.OFFLINE]: 'Offline',
    [LinkStatus.ERROR]: 'Error',
} as const;

export const LinkStatusColorMap = {
    [LinkStatus.ONLINE]: 'success',
    [LinkStatus.OFFLINE]: 'default',
    [LinkStatus.ERROR]: 'error',
} as const;

export enum AIModelCategory {
    LLM = 'llm',
    CV = 'cv',
    NLP = 'nlp',
    Audio = 'audio',
    MISC = 'misc',
    OTHER = 'other',
}

export const AIModelCategoryMap = {
    [AIModelCategory.LLM]: 'LLM',
    [AIModelCategory.CV]: 'CV',
    [AIModelCategory.NLP]: 'NLP',
    [AIModelCategory.Audio]: 'Audio',
    [AIModelCategory.MISC]: 'Misc',
    [AIModelCategory.OTHER]: 'Other',
} as const;

export enum AIModelTask {
    Classification = 'classification',
    Detection = 'detection',
    Segmentation = 'segmentation',
    Generation = 'generation',
    Translation = 'translation',
    Summarization = 'summarization',
    Other = 'other',
    NamedEntityRecognition = 'named-entity-recognition',
}
export const AIModelTaskMap = {
    [AIModelTask.Classification]: 'Classification',
    [AIModelTask.Detection]: 'Detection',
    [AIModelTask.Segmentation]: 'Segmentation',
    [AIModelTask.Generation]: 'Generation',
    [AIModelTask.Translation]: 'Translation',
    [AIModelTask.Summarization]: 'Summarization',
    [AIModelTask.NamedEntityRecognition]: 'Named Entity Recognition',
    [AIModelTask.Other]: 'Other',
} as const;

export enum AppProjectType {
    AI_MODEL = 'ai-model',
    WEB_APP = 'web-app',
    OTHER = 'other',
    COLAB = 'colab',
    REST_API = 'rest-api',
}

export const AppProjectTypeMap = {
    [AppProjectType.AI_MODEL]: 'AI Model',
    [AppProjectType.WEB_APP]: 'Web App',
    [AppProjectType.COLAB]: 'Colab',
    [AppProjectType.REST_API]: 'REST API',
    [AppProjectType.OTHER]: 'Other',
} as const;

export enum AIModelFramework {
    Streamlit = 'streamlit',
    Gradio = 'gradio',
}

export const AIModelFrameworkMap = {
    [AIModelFramework.Streamlit]: 'Streamlit',
    [AIModelFramework.Gradio]: 'Gradio',
} as const;

export enum AppFilterType {
    ALL = 'all',
    MY_APPS = 'my-apps',
    FAVORITES = 'favorites',
    MOST_POPULAR = 'most-popular',
    RECENT = 'recent',
    ONLINE = 'online',
    OFFLINE = 'offline',
    ERROR = 'error',
    ARCHIVED = 'archived',
    RESERVED = 'reserved',
    REMOVED = 'removed',
}
