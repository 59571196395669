import isEmpty from 'lodash/isEmpty';
import isEqual from 'lodash/isEqual';
import mapKeys from 'lodash/mapKeys';
import mapValues from 'lodash/mapValues';
import omit from 'lodash/omit';

export const _isEmpty = isEmpty;
export const _mapKeys = mapKeys;
export const _mapValues = mapValues;
export const _omit = omit;
export const _isEqual = isEqual;

export function _entries<T extends object>(obj?: T): Array<[keyof T, T[keyof T]]> {
    if (!obj || typeof obj !== 'object') return [];
    return Object.entries(obj) as any;
}

export function _values<T extends object>(obj?: T): Array<T[keyof T]> {
    if (!obj || typeof obj !== 'object') return [];

    return Object.values(obj);
}

export function _keys<T extends object>(obj?: T): Array<keyof T> {
    if (!obj || typeof obj !== 'object') return [];

    return Object.keys(obj) as any;
}

export function _compact<T extends Array<any>>(arr: T): T {
    if (!arr) return [] as any;
    return arr?.filter(i => !_isEmpty(i)) as T;
}

export function _compactDict<T extends object>(dict: T): Partial<T> {
    return _entries(dict).reduce((acc: Partial<T>, [key, value]) => {
        if (!_isEmpty(value)) {
            acc[key] = value;
        }
        return acc;
    }, {});
}
