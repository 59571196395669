import { MPModal, SignupForm } from '@model-park/mp-components';
import Styles from './Styles.module.scss';
import { useSignupForm } from './useSignupForm';
import { useState } from 'react';
import LegalClaimer from '../LegalClaimer';
import { Checkbox } from 'antd';

export default function Signup() {
    const { onFinish, isLoading } = useSignupForm();
    const [isModalOpen, setIsModalOpen] = useState(false);

    const showModal = () => {
        setIsModalOpen(true);
    };

    return (
        <>
            <SignupForm
                onFinish={onFinish}
                isLoading={isLoading}
                className={Styles.SignupForm}
                showModal={showModal}
            />
            <MPModal
                title="ModelPark User Content Disclaimer"
                open={isModalOpen}
                onCancel={() => setIsModalOpen(false)}
                onOk={() => setIsModalOpen(false)}
                width={800}
            >
                <LegalClaimer/>
            </MPModal>
        </>
    );
}
