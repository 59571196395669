import { UserCircle } from '@assets/icons';
import { MPDropDown } from '@model-park/mp-components';
import { Image } from 'antd';
import ConditionallyRender from 'react-conditionally-render';
import Styles from './Styles.module.scss';
import useUserProfile from './useUserProfile';

export default function UserProfile() {
    const { items, user, profilePicture } = useUserProfile();

    return (
        <div className={Styles.UserSection}>
            <ConditionallyRender
                condition={Boolean(user?.userName ?? user?.fullName)}
                show={<div className={Styles.UserName}>Hi, {user?.fullName ?? user?.userName}</div>}
            />
            <div className="flex pointer">
                <MPDropDown
                    menu={{ items }}
                    placement="top"
                    overlayStyle={{ width: '200px' }}
                >
                    {profilePicture ? (
                        <Image
                            src={profilePicture}
                            alt="profile-image"
                            preview={false}
                            width={36}
                            height={36}
                            className={Styles.ProfileAvatar}
                        />
                    ) : (
                        <UserCircle style={{ color: user ? '#6AE942' : '#e1e1e1' }} />
                    )}
                </MPDropDown>
            </div>
        </div>
    );
}
