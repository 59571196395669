import { Input, InputProps } from 'antd';
import cs from "classnames";
import Styles from "./Styles.module.scss";

export default function MPInput({ className, ...props }: MPInputProps) {
    return (
        <>
            <Input
                className={cs(Styles?.MPInput, className)}
                {...props}
            />
        </>
    );
}

export interface MPInputProps extends InputProps {

}
