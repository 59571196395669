import { useGetAppAccessTypeQuery } from '@app/services';
import { Logout, NavigationHelper, getAuthToken } from '@common';
import { DateFormat, URLHelper } from '@model-park/common';
import { MPButton } from '@model-park/mp-components';
import { Space } from 'antd';
import dayjs from 'dayjs';
import React, { ReactNode } from 'react';
import useAppname from '../useAppname';
import { RequestAccess } from './RequestAccess';

export default function PasswordInfo() {
    const authToken = getAuthToken();
    const { appName, maindomain } = useAppname();

    const { passwordExists, passwordRequired, accessRequest } = useGetAppAccessTypeQuery(appName, {
        skip: !appName,
        selectFromResult: ({ data }) => ({
            passwordRequired: Boolean(data?.passwordRequired),
            passwordExists: Boolean(data?.passwordExists),
            accessRequest: data?.accessRequest,
        }),
    });

    const info: Array<ReactNode> = ['This app is not publicly available since it is set Private by the owner.'];

    const action: Array<ReactNode> = [];

    function renderInfo() {
        action.push(<a href={new URLHelper(`https://${maindomain}`).toString()}>Back to Home</a>);

        return (
            <>
                <ul>
                    {info.map((item, index) =>
                        typeof item === 'string' ? (
                            <li key={index}>{item}</li>
                        ) : (
                            <React.Fragment key={index}>{item}</React.Fragment>
                        )
                    )}
                </ul>
                <Space
                    direction="vertical"
                    size="large"
                >
                    {action.map((item, index) => (
                        <React.Fragment key={index}>{item}</React.Fragment>
                    ))}
                </Space>
            </>
        );
    }

    if (passwordRequired) {
        // Only access with password

        info.push('This app is password protected and you need the get that from the app owner.');
        info.push('Please enter the password if you have it or request an access from the app owner.');

        if (authToken) {
            if (accessRequest) {
                action.push(
                    <div>
                        You have already requested access to this app on
                        {dayjs(accessRequest.createdAt).format(DateFormat.Date)}.
                    </div>
                );
            } else action.push(<RequestAccess />);
        } else {
            info.push("To request access, you'll need to sign in or create an account.");
            action.push(
                <MPButton>
                    <a
                        href={NavigationHelper.Auth.Signup().uri}
                        target="_blank"
                        rel="noreferrer"
                    >
                        Create an account
                    </a>
                </MPButton>
            );
        }

        return renderInfo();
    }

    if (authToken) {
        // The user is logged in but doesn't have access to this app

        info.push("You don't have access to this app.");

        if (passwordExists) {
            info.push('Please enter the password if you have it or request an access from the app owner.');
        } else {
            if (accessRequest) {
                action.push(
                    <div>
                        You have already requested access to this app on
                        {dayjs(accessRequest.createdAt).format(DateFormat.Date)}.
                    </div>
                );
            } else info.push('Please request access from its owner.');
        }

        action.push(
            <Space>
                <MPButton onClick={() => Logout()}>Sign out</MPButton>
                {accessRequest ? null : <RequestAccess />}
            </Space>
        );

        return renderInfo();
    }

    // The user is not logged in

    if (passwordExists) {
        info.push('This app is password protected and you need the get that from the app owner.');
    } else {
        info.push('Please sign in or create an account to request access from the app owner.');
    }

    action.push(
        <MPButton>
            <a
                href={NavigationHelper.Auth.Signup().uri}
                target="_blank"
                rel="noreferrer"
            >
                Create an account
            </a>
        </MPButton>
    );

    return renderInfo();
}
