import { Seo } from '@common/components';
import { Layout } from 'antd';
import { DashboardContent } from './DashboardContent';

export default function Main() {
    return (
        <>
            <Seo
                title="Model Park"
                description="ModelPark is an innovative platform designed to simplify and secure the sharing of web applications. Whether your app is hosted locally or on a cloud server, ModelPark provides a seamless way to share it with others through easy-to-remember, secure URLs. Our service, ideal for data scientists, AI researchers, and web developers, offers privacy controls, analytics, and a sleek management interface, revolutionizing how you showcase and market your applications to the world. Join ModelPark and experience a new era of effortless app sharing and discovery."
                keywords="localtunnel, localhost, ngrok, modelpark, cloud server, app sharing, ModelPark, Model Park, tunnel, tunneling, port forwarding,"
                name="Model Park"
            />
            <Layout.Content>
                <DashboardContent />
            </Layout.Content>
        </>
    );
}
