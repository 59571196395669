import { AboutUsCard1, AboutUsCard2, AboutUsCard3, AboutUsCard4 } from '@model-park/assets';
import Styles from './Styles.module.scss';

export default function CardSection() {
    function boldenText(text: string) {
        const boldWords = [
            'ModelPark',
            'CLI',
            'localhost or a cloud server',
            'myapp.modelpark.app',
            "ModelPark's advanced analytics",
            'alerting',
            'IP address',
            'private mode and password protection',
            'ensuring protection from unauthorized access',
            'Model Card',
            'reserve custom model names and subdomains',
            "it's a marketplace for innovation",
        ];
        const regex = new RegExp(`(${boldWords.join('|')})`, 'gi');
        const textParts = text.split(regex);

        return textParts.map((part, index) => {
            if (boldWords.some(word => new RegExp(word, 'gi').test(part))) {
                return <strong key={index}>{part}</strong>;
            } else {
                return part;
            }
        });
    }

    return (
        <div className={Styles.InfoSection}>
            {aboutUsText.map(({ title, text, icon }) => {
                return (
                    <div className={Styles.Info}>
                        <div className={Styles.TextWrapper}>
                            <div className={Styles.IconWrapper}>{icon}</div>
                            <hr
                                className={Styles.Hr}
                                role="presentation"
                                data-uw-rm-sr=""
                            />
                            <h3 className={Styles.Infotitle}>
                                <strong>{title}</strong>
                            </h3>
                            <p className={Styles.Infotext}>{boldenText(text)}</p>
                        </div>
                    </div>
                );
            })}
        </div>
    );
}

const aboutUsText = [
    {
        icon: <AboutUsCard1 />,
        title: 'Effortless Sharing with Secure URLs',
        text: "ModelPark enables a transformative way to present your applications. Whether your app is running on your localhost or a cloud server, our platform effortlessly tunnels your app to the outside world, providing you with an easy-to-remember, secure URL like 'myapp.modelpark.app'. This process simplifies server configurations and inbound rule settings, catering to both beginners and advanced users.",
    },
    {
        icon: <AboutUsCard2 />,
        title: 'Flexible App Registration',
        text: "ModelPark offers two modes of app registration to suit your needs. The CLI module allows you to register your apps from anywhere using the command line, ensuring that no one, not even ModelPark, has access to your IP address. This mode is essential for local host deployments. Alternatively, register your app via our UI, where you'll share the original public URL (or IP address and port number) that you can also hide from other users.",
    },
    {
        icon: <AboutUsCard3 />,
        title: 'Advanced Analytics and User Engagement',
        text: "Unlock powerful insights into how users interact with your apps with ModelPark's advanced analytics. Track user activities, including time spent on your apps, and gather valuable feedback directly from your users. This data is instrumental in understanding user behavior, improving user experience, and enhancing app performance.",
    },
    {
        icon: <AboutUsCard4 />,
        title: 'Real-Time Monitoring and Access Control',
        text: "ModelPark actively monitors the status of your tunneled apps, alerting you if your app goes offline or becomes inaccessible. This ensures continuous availability and instant awareness of any connectivity issues. Moreover, viewers don't need a ModelPark account to access your apps, making it easy to reach a broader audience.",
    },
    {
        icon: <AboutUsCard4 />,
        title: 'Privacy and Security in Your Hands',
        text: ' With features like private mode and password protection, ModelPark puts privacy and security in your hands. You control who views your apps, ensuring protection from unauthorized access.',
    },
    {
        icon: <AboutUsCard3 />,
        title: 'Customizable Model Cards and Analytics',
        text: "Each app shared via ModelPark is accompanied by a 'Model Card' – a customizable space enriched with useful information. Manage these cards through our sleek interface and gain valuable insights from our analytics on user engagement and views.",
    },
    {
        icon: <AboutUsCard2 />,

        title: 'Reserve Your Unique Model Name and App URLs',
        text: "With ModelPark, you have the opportunity to reserve custom model names and subdomains for a definite period, enhancing your brand's visibility and identity.",
    },
    {
        icon: <AboutUsCard1 />,

        title: 'Market Your Apps with Ease',
        text: "ModelPark is more than just an app-sharing platform; it's a marketplace for innovation. Showcase your work, receive feedback, and connect with a community of like-minded individuals and organizations. We are your partner in bringing your innovative applications to the forefront.",
    },
];
