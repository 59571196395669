import { Upload, UploadProps } from 'antd';
import ImgCrop from 'antd-img-crop';
import { UploadChangeParam } from 'antd/lib/upload/interface';
import { useState } from 'react';
import Styles from './Styles.module.scss';

export default function MPProfileImageUpload(props: MPProfileUploadProps) {
    const [fileList, setFileList] = useState<any>([]);

    function onChange(e: UploadChangeParam<unknown>) {
        setFileList(e.fileList);
    }

    function onBeforeUpload(file: File) {
        props.onImageChanged?.(file);
        props.setCroppedImage?.(file);
        return false;
    }

    return (
        <div className={Styles.UploadImageContainer}>
            <ImgCrop showGrid>
                <Upload
                    listType="picture-card"
                    fileList={fileList}
                    onChange={onChange}
                    maxCount={1}
                    beforeUpload={onBeforeUpload}
                    showUploadList={false}
                >
                    <div>Upload an Image</div>
                </Upload>
            </ImgCrop>
        </div>
    );
}

interface MPProfileUploadProps extends UploadProps {
    onImageChanged?: (file?: File) => void;
    setCroppedImage?: (file: File) => void;
}
