import { Input } from 'antd';
import { TextAreaProps } from 'antd/lib/input';
import cs from "classnames";
import Styles from "./Styles.module.scss";

export default function MPTextArea({ className, ...props }: IMPTextAreaProps) {
    return (
        <>
            <Input.TextArea
                className={cs(Styles?.MPTextAreaContainer, className)}
                {...props}
            />
        </>
    );
}


export interface IMPTextAreaProps extends TextAreaProps {

}