import { Typography } from 'antd';
import Initialize from './Initialize';
import Kill from './Kill';
import List from './List';
import Login from './Login';
import Logout from './Logout';
import Register from './Register';
import Stop from './Stop';

const { Title } = Typography;

export function Commands() {
    return (
        <>
            <Title level={3}>Commands</Title>
            <Login />
            <Initialize />
            <Stop />
            <Logout />
            <Register />
            <List />
            <Kill />
        </>
    );
}
