import { useGetAppAccessQuery, useGetAppAccessTypeQuery } from '@app/services';
import { useTrackAppSession } from '@common';
import { AppTrackEvent } from '@model-park/entities';
import { useEffect } from 'react';
import ActionButtons from './ActionButtons';
import { AppContent } from './AppContent';
import Header from './Header';
import useAppname from './useAppname';

export default function AppPage() {
    const { appName } = useAppname();

    const { appData: appInfo } = useGetAppAccessQuery(appName, {
        skip: !appName,
        selectFromResult: ({ data }) => ({
            appData: data?.app,
        }),
    });

    const { title } = useGetAppAccessTypeQuery(appName, {
        skip: !appName,
        selectFromResult: ({ data }) => ({
            title: data?.title,
        }),
    });

    useTrackAppSession(AppTrackEvent.AppPageOpened, appInfo?._id);

    useEffect(() => {
        if (!appName || !title) return;
        document.title = title || appName;
    }, [appName, title]);

    return (
        <div>
            <Header
                title={title || appName}
                app={appInfo}
            />

            <AppContent />

            <ActionButtons appInfo={appInfo} />
        </div>
    );
}
