import { AppRegistration } from '@model-park/entities';
import { MPInput, MPSwitch } from '@model-park/mp-components';
import { Col, Form, Row } from 'antd';

export default function RegistrationConfig() {
    const registrationType = Form.useWatch<AppRegistration>('registrationType');

    if (registrationType === AppRegistration.TUNNEL_REGISTRATION) return null;

    return (
        <>
            <Row gutter={24}>
                <Col span={12}>
                    <Form.Item
                        name={['registrationConfig', 'uri']}
                        label={'URI'}
                        rules={[
                            {
                                type: 'url',
                                message: 'The input is not valid URI!',
                            },
                        ]}
                        initialValue={'https://'}
                    >
                        <MPInput
                            placeholder={'https://example.com'}
                            type={'uri'}
                        />
                    </Form.Item>
                </Col>
                <Col>
                    <Form.Item
                        name={['registrationConfig', 'showOriginalUri']}
                        label={'Show Original URI'}
                        valuePropName="checked"
                        initialValue={true}
                    >
                        <MPSwitch />
                    </Form.Item>
                </Col>
                <Col>
                    <Form.Item
                        name={['registrationConfig', 'checkConnection']}
                        label={'Check Connection'}
                        valuePropName="checked"
                        initialValue={true}
                    >
                        <MPSwitch />
                    </Form.Item>
                </Col>
            </Row>
        </>
    );
}
