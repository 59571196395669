import { useSubscribeEventBus } from '@app/hooks';
import FooterSection from '@pages/MainDashBoard/sections/FeaturedList/components/FooterSection';
import { Layout } from 'antd';
import Header from './Header';
import LayoutContent from './LayoutContent';
import Styles from './Styles.module.scss';
import { MPDrawer } from '@model-park/mp-components';
import { EditApp } from '@pages/AppDetails/sections';
import { useAppDispatch } from '@common';
import { useSelector } from 'react-redux';
import { selectIsEditDrawerOpen, setEditDrawerOpen } from '@pages/MainDashBoard/store';

export function MainLayout() {
    useSubscribeEventBus();
    const dispatch = useAppDispatch();
    const isDrawerOpen = useSelector(selectIsEditDrawerOpen);
    function handleDrawerClose() {
        dispatch(setEditDrawerOpen(false));
    }
    return (
        <Layout className={Styles.LayoutContainer}>
            <Header />
            <LayoutContent />
            <FooterSection />
            <MPDrawer
                title="Edit App"
                open={isDrawerOpen}
                onClose={handleDrawerClose}
                size="large"
            >
                <EditApp />
            </MPDrawer>
        </Layout>
    );
}

export * from './ThemeSwitch';
export * from './UserProfile';
