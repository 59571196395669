import { AppTracking, IAppTracking } from '@model-park/models';
import { MPAPISlice } from './ModelParkAPI';

export const AppTrackingsAPI = MPAPISlice.injectEndpoints({
    endpoints: builder => ({
        getAppTrackingStats: builder.query<IAppTracking[], string>({
            query: appId => ({
                url: `app-tracking`,
                params: { appId },
            }),
        }),

        createAppTracking: builder.mutation<AppTracking, AppTracking>({
            query: body => ({
                url: 'app-tracking',
                method: 'POST',
                body,
            }),
        }),
    }),
});

export const { useGetAppTrackingStatsQuery, useCreateAppTrackingMutation } = AppTrackingsAPI;
