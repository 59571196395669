import { DateFormat } from '@model-park/common';
import { IAppProject } from '@model-park/models';
import { MPCard, MPTooltip } from '@model-park/mp-components';
import { Tag, Typography } from 'antd';
import dayjs from 'dayjs';
import IconSection from './IconSection';
import Styles from './Styles.module.scss';
import useAppCard from './useAppCard';
import CardCover, { renderTooltip } from './CardCover';

export default function AppCard({ item, handleModalOpen }: IProps) {
    const { goToApp } = useAppCard(item, handleModalOpen);

    return (
        <MPCard
            onClick={goToApp}
            className={Styles.AppCardContainer}
            hoverable
            cover={<CardCover item={item} />}
        >
            <div className={Styles.CardBody}>
                <Typography.Title
                    className={Styles.CardTitle}
                    level={5}
                    ellipsis={true}
                >
                    {item.title}
                    <div className={Styles.CardName}>{item.name}</div>
                </Typography.Title>

                <div className={Styles.DateWrapper}>
                    <div className={Styles.WrapperContainer}>
                        <Typography.Paragraph className={Styles.Date}>
                            {dayjs(item.updatedAt).format(DateFormat.Date)}
                        </Typography.Paragraph>
                        <div className={Styles.GreenDot} />
                        <MPTooltip
                            placement="bottom"
                            title={item.ownerName}
                        >
                            <Typography.Paragraph className={Styles.Owner}>{item.ownerName}</Typography.Paragraph>
                        </MPTooltip>
                    </div>
                </div>
                <IconSection
                    item={item}
                    handleModalOpen={handleModalOpen}
                />
            </div>
        </MPCard>
    );
}

export interface IProps {
    item: IAppProject;
    handleModalOpen?: (id: string | null) => void;
}
