import { useSearchParams } from 'react-router-dom';

export function useGetSearchParamValue<T = string>(paramName: string) {
    const [searchParams] = useSearchParams();

    return searchParams.get(paramName) as T;
}

export function getValueFromUrl(paramName: string) {
    return new URLSearchParams(window.location.search).get(paramName);
}
