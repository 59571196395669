import { HuggingfaceForm } from "./Resources";

export default function ResourceForm({ selectedResource }: IProps) {

    switch (selectedResource) {
        case "huggingface":
            return <HuggingfaceForm />;

        default:
            return null;
    }

}

interface IProps {
    selectedResource: string;
}