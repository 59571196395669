import { useAppSelector } from '@common';
import { CommonNavigationHelper, DateFormat, _compactDict, parseTime } from '@model-park/common';
import {
    AIModelCategoryMap,
    AIModelFrameworkMap,
    AIModelTaskMap,
    AppProjectTypeMap,
    AppRegistrationMap,
    LinkStatus,
    LinkStatusMap,
} from '@model-park/entities';
import { IAppProject } from '@model-park/models';
import { MPButton, MPModal } from '@model-park/mp-components';
import { selectAuthToken } from '@pages/Authentication/store';
import { Tag } from 'antd';
import Link from 'antd/es/typography/Link';
import cs from 'classnames';
import Styles from './Styles.module.scss';

export default function AppCardModal({ item, setSelectedItem }: IProps) {
    const authToken = useAppSelector(selectAuthToken);
    const color = item?.accessManagement.access === 'public' ? 'green' : 'purple';

    return (
        <MPModal
            className={Styles.ModelContainer}
            open={Boolean(item)}
            footer={null}
            onCancel={() => setSelectedItem(null)}
        >
            <div className={Styles.ModelCard}>
                <div className={Styles.ModelCardHeader}>
                    <div className={Styles.StatusContainer}>
                        <div
                            className={cs(
                                Styles.Status,
                                { [Styles.Online]: item?.linkStatus === LinkStatus.ONLINE },
                                { [Styles.Offline]: item?.linkStatus === LinkStatus.OFFLINE },
                                { [Styles.Error]: item?.linkStatus === LinkStatus.ERROR }
                            )}
                        >
                            {item ? LinkStatusMap[item.linkStatus] : ''}
                        </div>
                        <Tag
                            className={Styles.ModelTag}
                            color={color}
                            key={item?.accessManagement.access}
                        >
                            {item?.accessManagement?.access.toUpperCase()}
                        </Tag>
                    </div>
                    <h2 className={Styles.ModelTitle}> {item?.title}</h2>
                    <h4 className={Styles.ModelName}>{item?.name}</h4>
                </div>
                <div className={Styles.ModelCardBody}>
                    <div className={Styles.ModelDetailsContainer}>
                        <div className={Styles.ModelDescription}>
                            <strong className={Styles.ModelLable}>Description: </strong>
                            {item?.description}
                        </div>
                        <div>
                            <strong className={Styles.ModelLable}>App Type:</strong>
                            {item ? AppProjectTypeMap[item.appType] : '-'}
                        </div>
                        <div>
                            <strong className={Styles.ModelLable}>AI Category:</strong>
                            {item ? AIModelCategoryMap[item?.aiModelProperties.category] : '-'}
                        </div>
                        <div>
                            <strong className={Styles.ModelLable}>AI Task:</strong>
                            {item ? AIModelTaskMap[item.aiModelProperties.task] : '-'}
                        </div>
                        <div>
                            <strong className={Styles.ModelLable}>Web Framework:</strong>
                            {item ? AIModelFrameworkMap[item?.aiModelProperties.framework] : '-'}
                        </div>
                        <div>
                            <strong className={Styles.ModelLable}>Model Type:</strong>
                            {item ? AppRegistrationMap[item.registrationType] : '-'}
                        </div>
                        <div>
                            <strong className={Styles.ModelLable}>Added By :</strong> {item?.ownerName}
                        </div>
                        <div>
                            <strong className={Styles.ModelLable}>Last Updated :</strong>{' '}
                            {parseTime(item?.updatedAt, DateFormat.Date)}
                        </div>
                        <div>
                            <strong className={Styles.ModelLable}>Added :</strong> {parseTime(item?.createdAt, DateFormat.Date)}
                        </div>
                    </div>
                </div>
                <div className={Styles.ModelButton}>
                    <Link
                        href={CommonNavigationHelper.getAppUri({
                            appName: item?.name || '',
                            params: _compactDict({ authToken }),
                            host: window.location.host,
                            protocol: window.location.protocol,
                        })}
                        target="_blank"
                    >
                        <MPButton>Go to App</MPButton>
                    </Link>
                </div>
            </div>
        </MPModal>
    );
}

interface IProps {
    item: IAppProject | undefined;
    setSelectedItem: (id: string | null) => void;
}
