import { useGetAppDetailsQuery, useUpdateAppProjectMutation } from '@app/services';
import { useAppDispatch } from '@common';
import { NamespaceStatus } from '@model-park/entities';
import { selectCurrentAppName, setEditDrawerOpen } from '@pages/MainDashBoard/store';
import { useCallback, useState } from 'react';
import { useSelector } from 'react-redux';

export default function useUpdateNameStatus({ modalOpen, setModalOpen }: IProps) {
    const currentAppName = useSelector(selectCurrentAppName);
    const dispatch = useAppDispatch();
    const [appStatus, setAppStatus] = useState<NamespaceStatus>(NamespaceStatus.archived);
    const [updateAppProject, { isLoading: updateLoading }] = useUpdateAppProjectMutation();

    const { data: app } = useGetAppDetailsQuery(currentAppName as string, { skip: !currentAppName });

    const handleCancel = () => {
        setModalOpen(false);
    };

    const handleOk = useCallback(async () => {
        try {
            const payload = { namespaceStatus: appStatus };
            await updateAppProject({ body: payload as any, id: app?._id as string }).unwrap();
        } catch (error: any) {
            console.log(error);
        }
        dispatch(setEditDrawerOpen(false));
        setModalOpen(false);
    }, [app, updateAppProject, dispatch, appStatus, setModalOpen]);

    const OkBtnTextMap: { [key in NamespaceStatus]?: string } = {
        active: 'Active',
        reserved: 'Reserve',
        archived: 'Archive',
        removed: 'Delete',
    };

    const modalProps = {
        open: modalOpen,
        onCancel: handleCancel,
        onOk: handleOk,
        title: `Delete ${currentAppName} App ?`,
        width: 600,
        okText: OkBtnTextMap[appStatus],
        cancelText: 'Cancel',
        okButtonProps: { danger: appStatus === NamespaceStatus.removed ?? false },
    };
    return {
        setModalOpen,
        modalProps,
        appStatus,
        setAppStatus,
    };
}

interface IProps {
    modalOpen: boolean;
    setModalOpen: (open: boolean) => void;
}
