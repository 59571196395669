import { Breadcrumb, BreadcrumbProps } from 'antd';
import cs from 'classnames';
import { Link, useLocation } from 'react-router-dom';
import Styles from "./Styles.module.scss";

export function MPBreadcrumb({ className, useDefault, nameMap, children, ...props }: IMPBreadcrumbProps) {

    const location = useLocation();
    const pathSnippets = location.pathname.split('/').filter(i => i);

    const extraBreadcrumbItems = pathSnippets.map((_, index) => {
        const url = `/${pathSnippets.slice(0, index + 1).join('/')}`;
        return (
            <Breadcrumb.Item key={url}>
                <Link to={url}>{nameMap?.[url]}</Link>
            </Breadcrumb.Item>
        );
    });

    const breadcrumbItems = [
        <Breadcrumb.Item key="home">
            <Link to="/">Home</Link>
        </Breadcrumb.Item>,
    ].concat(extraBreadcrumbItems);

    return (
        <>
            <Breadcrumb
                className={cs(Styles?.MPBreadcrumb, className)}
                {...props}
            >
                {useDefault ? breadcrumbItems : children}
            </Breadcrumb>
        </>
    );
}

export interface IMPBreadcrumbProps extends BreadcrumbProps {
    useDefault?: boolean;
    nameMap?: Dictionary<string>;
}
