import { AccessAction, UserRole } from '../enums';
import type { AppProjectStats } from './APIResults';

export type AccessActionKey = keyof typeof AccessAction;

export type Projection<T> = Partial<Record<keyof T, number>>;

export class JWTPayload {
    constructor(
        public userId: string,
        public email: string,
        public role: UserRole,
        public appName?: string,
        public sharingUser?: string
    ) {}

    get() {
        return {
            userId: this.userId,
            email: this.email,
            role: this.role,
            appName: this.appName,
            sharingUser: this.sharingUser,
        };
    }
}

export const AppProjectStatsTypes: Array<keyof AppProjectStats> = ['registrationType', 'appType'];

export type * from './APIResults';

export type ThemeType = 'light' | 'dark';

export interface ICliDistItem {
    name: string;
    size: number;
    url: string;
    mod_time: string;
    mode: number;
    is_dir: boolean;
    is_symlink: boolean;
}
