import { DashboardPanel } from '@app/components';
import { AppSelection, VisitorStatsTable } from './components';

export default function AppAnalytics() {
    return (
        <DashboardPanel>
            <AppSelection />
            <VisitorStatsTable />
        </DashboardPanel>
    );
}
