/* eslint-disable eqeqeq */
import { Result, ResultProps } from 'antd';
import type { ResultStatusType } from 'antd/lib/result';
import React, { useCallback } from 'react';
import { useNavigate } from 'react-router-dom';
import { MPButton } from '../../General';

export function MPResult({ status, error, subTitle, homeLink = "/", ...props }: IMPResultProps) {
    const navigate = useNavigate();

    const onNavigateHome = useCallback(() => {
        if (homeLink.startsWith("http")) window.location.href = homeLink;
        else navigate(homeLink);
    }, [homeLink, navigate]);

    let errorMessage = error?.message ?? error;

    if (typeof errorMessage === "object") errorMessage = JSON.stringify(error);

    const ResultPageMap: Array<IResultPageItem> = [
        {
            status: '404',
            title: '404',
            subTitle: subTitle || 'Sorry, the page you visited does not exist.',
            extra: (
                <MPButton
                    type="primary"
                    onClick={onNavigateHome}
                >
                    Back Home
                </MPButton>
            ),
        },
        {
            status: '403',
            title: '403',
            subTitle: (
                <div>
                    <p>{subTitle || 'Sorry, the page is forbidden'}</p>
                </div>
            ),
            extra: (
                <MPButton
                    type="primary"
                    onClick={onNavigateHome}
                >
                    Back Home
                </MPButton>
            ),
        },
        {
            status: '500',
            title: '500',
            subTitle: (
                <div>
                    <p>{subTitle || 'Sorry, something went wrong...'}</p>
                    <p>{errorMessage}</p>
                </div>
            ),
            extra: (
                <MPButton
                    type="primary"
                    onClick={onNavigateHome}
                >
                    Back Home
                </MPButton>
            ),
        },
    ];

    return (
        <>
            <Result
                {...ResultPageMap?.find(item => item.status == status)}
                {...props}
            />

        </>
    );
}

export interface IMPResultProps extends Omit<ResultProps, "title" | "extra"> {
    error?: any;
    homeLink?: string;
}

export interface IResultPageItem {
    status: ResultStatusType;
    title: string;
    subTitle: React.ReactNode;
    extra: React.ReactNode;
}