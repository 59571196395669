import { LoginOutlined, LogoutOutlined, SettingOutlined, UserAddOutlined } from '@ant-design/icons';
import { Logout, NavigationHelper } from '@common';
import { getLocationParts } from '@model-park/common';
import { MPTooltip } from '@model-park/mp-components';
import { selectAuthUser } from '@pages/Authentication/store/selectors';
import { useGetProfilePictureQuery } from '@pages/Settings/services/UserService';
import { MenuProps, Typography } from 'antd';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';

export default function useUserProfile() {
    const user = useSelector(selectAuthUser);
    const { data: profilePicture } = useGetProfilePictureQuery(null, { skip: !user?._id || !user?.profilePicture });

    const { subdomain } = getLocationParts();
    function goToSettings() {
        NavigationHelper.Settings.Profile().navigate();
    }

    const privateUserMenu: MenuProps['items'] = [
        {
            key: '1',
            label: (
                <MPTooltip title={user?.email}>
                    <Typography.Text
                        ellipsis={true}
                        style={{ width: '100px', fontWeight: 'bold' }}
                    >
                        {user?.fullName ?? user?.email}
                    </Typography.Text>
                </MPTooltip>
            ),
        },
        {
            key: '2',
            disabled: Boolean(subdomain),
            label: <Typography.Text>Settings</Typography.Text>,
            icon: <SettingOutlined style={{ fontSize: '16px' }} />,
            onClick: goToSettings,
        },
        {
            key: '3',
            label: <Typography.Text>Log Out</Typography.Text>,
            icon: <LogoutOutlined style={{ fontSize: '16px' }} />,
            onClick: () => Logout(),
        },
    ];
    const publicUserMenu: MenuProps['items'] = [
        {
            key: '1',
            label: <Link to={NavigationHelper.Auth.Login().uri}>Sign In</Link>,
            icon: <LoginOutlined style={{ fontSize: '16px' }} />,
        },
        {
            type: 'divider',
        },
        {
            key: '2',
            label: <Link to={NavigationHelper.Auth.Signup().uri}>Sign Up</Link>,
            icon: <UserAddOutlined style={{ fontSize: '16px' }} />,
        },
    ];

    const items = user ? privateUserMenu : publicUserMenu;
    return { items, user, profilePicture };
}
