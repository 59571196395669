import { Form, FormProps } from 'antd';
import React from 'react';
import Styles from './Styles.module.scss';
import cs from 'classnames';
import { MPButton } from '../../General';
import { IMPButtonProps } from '../../General/mp-button';
import ConditionallyRender from 'react-conditionally-render';

export default function MPForm<T = any>({
    className,
    children,
    loading,
    submit,
    submitclassName,
    submitBtnText = 'Submit',
    showSubmit = true,
    ...props
}: MPFormProps<T>) {
    return (
        <>
            <Form<T>
                className={cs(Styles?.MPFormContainer, className)}
                {...props}
            >
                {children}

                <ConditionallyRender
                    condition={showSubmit}
                    show={
                        <Form.Item className={cs(Styles.FormSubmit, submitclassName)}>
                            <MPButton
                                type="primary"
                                htmlType="submit"
                                loading={loading}
                                {...submit}
                            >
                                {submitBtnText}
                            </MPButton>
                        </Form.Item>
                    }
                />
            </Form>
        </>
    );
}

export interface MPFormProps<T> extends FormProps<T> {
    children?: React.ReactNode;
    loading?: boolean;
    submit?: IMPButtonProps;
    submitclassName?: string;
    submitBtnText?: string;
    showSubmit?: boolean;
}
