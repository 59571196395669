import React from 'react';
import { Typography, Table } from 'antd';

const { Title, Paragraph } = Typography;

export default function Stop() {
    return (
        <>
            <Title level={4}>Stop:</Title>
            <Paragraph>Stop the modelpark cli</Paragraph>
            <Typography>
                <code>modelpark stop</code>
            </Typography>
        </>
    );
}
