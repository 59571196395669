import { Tooltip } from 'antd';
import { TooltipPropsWithTitle } from 'antd/lib/tooltip';
import cx from 'classnames';
import Styles from './Styles.module.scss';

export function MPTooltip({ className, title, children, overlayClassName, show = true, ...props }: MPTooltipProps) {
    if (show) {
        return (
            <Tooltip
                title={title}
                className={className}
                color="white"
                overlayInnerStyle={{ color: '#364153' }}
                overlayClassName={cx(Styles.Tooltip, overlayClassName)}
                {...props}
            >
                {children}
            </Tooltip>
        );
    }

    return <>{children}</>;
}

export interface MPTooltipProps extends TooltipPropsWithTitle {
    className?: string;
    overlayClassName?: string;
    show?: boolean;
}
