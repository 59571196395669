import { useCreateAppTrackingMutation } from '@app/services';
import { useAppSelector } from '@common';
import { _entries } from '@model-park/common';
import { selectAuthUser } from '@pages/Authentication/store';
import { useEffect } from 'react';

export function useAppTracking() {
    const [createAppTracking] = useCreateAppTrackingMutation();
    const user = useAppSelector(selectAuthUser);

    useEffect(() => {
        function sendLog(event: any) {
            const data: Dictionary = event.detail;
            data.userId = user?._id ?? localStorage.getItem('tempUserId');

            const formData = new FormData();

            _entries(data).forEach(([key, value]) => {
                formData.append(key, value);
            });

            window.navigator.sendBeacon('/api/app-tracking', JSON.stringify(data));
            // createAppTracking(data as any);
        }

        document.addEventListener('App-Tracking', sendLog);

        return () => document.removeEventListener('App-Tracking', sendLog);
    }, [createAppTracking, user?._id]);
}
