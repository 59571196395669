import { AccessTypeOptions } from '@model-park/common';
import { AccessType } from '@model-park/entities';
import { MPPassword, MPSwitch } from '@model-park/mp-components';
import { Col, Form, Radio, Row } from 'antd';
import ConditionallyRender from 'react-conditionally-render';

export default function AccessManagement() {
    const accessType = Form.useWatch(['accessManagement', 'access']);
    const passwordRequired = Form.useWatch(['accessManagement', 'passwordRequired']);

    return (
        <>
            <Row gutter={24}>
                <Col span={12}>
                    <Form.Item
                        name={['accessManagement', 'access']}
                        label={'Access'}
                        initialValue={'public'}
                    >
                        <Radio.Group options={AccessTypeOptions} />
                    </Form.Item>
                </Col>

                <ConditionallyRender
                    condition={accessType !== AccessType.PUBLIC}
                    show={
                        <Row gutter={24}>
                            <Col span={8}>
                                <Form.Item
                                    name={['accessManagement', 'password']}
                                    label={'Password'}
                                    rules={[{ required: passwordRequired, message: 'Please input your password!' }]}
                                >
                                    <MPPassword />
                                </Form.Item>
                            </Col>
                            <Col>
                                <Form.Item
                                    name={['accessManagement', 'passwordRequired']}
                                    label={'Password Required'}
                                    valuePropName="checked"
                                >
                                    <MPSwitch />
                                </Form.Item>
                            </Col>
                        </Row>
                    }
                />
                <Col>
                    <Form.Item
                        name={'hideMyName'}
                        label={'Hide My Name'}
                        valuePropName="checked"
                        initialValue={false}
                        help={'If checked, your name will not be displayed'}
                    >
                        <MPSwitch />
                    </Form.Item>
                </Col>
            </Row>
        </>
    );
}
