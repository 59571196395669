import { Seo } from '@common/components';
import Styles from './Styles.module.scss';
import { DistHead } from './components';
import { CLI, PyLib } from './sections';

export default function Distribution() {
    return (
        <>
            <Seo
                title="ModelPark Distribution"
                description="ModelPark Distribution provides a list of all your apps, including their URLs, and commands to run them."
                keywords="modelpark,distribution, localtunnel, localhost, ngrok, modelpark, cloud server, tunneling, app sharing"
                name="modelpark"
            />
            <div className={Styles.DistContainer}>
                <DistHead />

                <PyLib />

                <CLI />
            </div>
        </>
    );
}
