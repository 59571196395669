import { useIsAppNameUniqueMutation } from '@app/services';
import { Errors } from '@model-park/entities';
import { MPInput, MPTextArea } from '@model-park/mp-components';
import { Form } from 'antd';
import { useCallback } from 'react';

export default function AppInfo({ currentAppName }: IProps) {
    const [isAppNameUnique] = useIsAppNameUniqueMutation();

    const checkFieldUniqueness = useCallback(
        async (_: any, value: string) => {
            try {
                const isUnique = await isAppNameUnique({ name: value }).unwrap();
                return isUnique ? Promise.resolve() : Promise.reject(Errors.appExists);
            } catch (error) {
                return Promise.reject(Errors.appExists);
            }
        },
        [isAppNameUnique]
    );

    return (
        <>
            <Form.Item
                hasFeedback
                validateTrigger="onBlur"
                name={'name'}
                label={'Name'}
                rules={[
                    {
                        pattern: /^[a-zA-Z0-9-]+$/,
                        message: 'Name must be alphanumeric',
                    },
                    {
                        required: true,
                        message: 'Please enter a name',
                    },

                    ({ getFieldValue }) => ({
                        validator(_, value) {
                            if (value !== currentAppName) {
                                return checkFieldUniqueness(_, value);
                            }
                            return Promise.resolve();
                        },
                    }),
                ]}
                normalize={normalizeAppName}
            >
                <MPInput />
            </Form.Item>

            <Form.Item
                name={'title'}
                label={'Title'}
                rules={[{ required: true, message: 'Please enter a title' }]}
            >
                <MPInput />
            </Form.Item>

            <Form.Item
                name={'description'}
                label={'Description'}
                rules={[{ required: true, message: 'Please enter a description' }]}
            >
                <MPTextArea />
            </Form.Item>
        </>
    );
}

const normalizeAppName = (value: string) => value?.toLowerCase();

interface IProps {
    currentAppName?: string;
}
