import { useGetUserIdMutation } from '@app/services';
import { isValidEmail } from '@model-park/common';
import { Errors } from '@model-park/entities';
import { MPAlert, MPButton, MPInput } from '@model-park/mp-components';
import { Col, Form, Input, Row } from 'antd';
import React, { useState } from 'react';
import Styles from './Styles.module.scss';

export default function EmailForm() {
    const [value, setValue] = useState<string>();
    const form = Form.useFormInstance();
    const [getUserId, { error }] = useGetUserIdMutation();
    const [internalError, setInternalError] = useState<string>();

    const handleClick = async (e: React.MouseEvent<HTMLElement, MouseEvent>) => {
        try {
            e.preventDefault();
            setInternalError(undefined);

            if (!value || !isValidEmail(value)) throw new Error(Errors.invalidEmail);

            const user = await getUserId(value).unwrap();

            if (!user) throw new Error('User not found');

            const permissions = form.getFieldValue(['accessManagement', 'permissions']) || [];

            if (permissions?.find((item: any) => item.user === user._id)) throw new Error(Errors.emailExists);

            form.setFieldValue(
                ['accessManagement', 'permissions'],
                [
                    ...permissions,
                    {
                        email: user.email,
                        user: user._id,
                        access: [],
                    },
                ]
            );

            setValue(undefined);
        } catch (error: any) {
            console.error(error);
            setInternalError(error?.message);
        }
    };

    const errorMessage = internalError || (error as any)?.data?.message;
    return (
        <Row>
            <Col span={8}>
                <Input.Group
                    compact
                    style={{ marginBottom: 20, display: 'flex' }}
                >
                    <MPInput
                        type="email"
                        value={value}
                        onChange={e => setValue(e.target.value)}
                        style={{ flex: 1 }}
                        placeholder="User Email"
                    />
                    <MPButton
                        htmlType="submit"
                        type="primary"
                        onClick={handleClick}
                    >
                        Add User
                    </MPButton>
                </Input.Group>

                {errorMessage && (
                    <MPAlert
                        message={errorMessage}
                        type="error"
                        className={Styles.Alert}
                    />
                )}
            </Col>
        </Row>
    );
}
