import { AppTrackEvent } from '@model-park/entities';
import { useEffect, useRef } from 'react';
import { v4 as uuidv4 } from 'uuid';

export function useTrackAppSession(eventType: AppTrackEvent, appId?: string) {
    const sessionId = useRef(uuidv4());

    useEffect(() => {
        if (!appId) return;
        function trackSession() {
            document.dispatchEvent(
                new CustomEvent('App-Tracking', { detail: { appId, sessionId: sessionId.current, eventType } })
            );
        }
        trackSession();

        document.addEventListener('visibilitychange', trackSession);

        return () => document.removeEventListener('visibilitychange', trackSession);
    }, [appId, eventType]);
}
