import { Input } from 'antd';
import { PasswordProps } from 'antd/lib/input';
import cs from "classnames";
import Styles from "./Styles.module.scss";

export default function MPPassword({ className, ...props }: MPInputProps) {
    return (
        <>
            <Input.Password
                className={cs(Styles?.MPInput, className)}
                {...props}
            />
        </>
    );
}

export interface MPInputProps extends PasswordProps {

}
