import { Space } from '@modelpark/huggingface-hub-api';
import { ColumnsType } from 'antd/es/table';
import dayjs from 'dayjs';

export function useResultTable() {
    const columns: ColumnsType<Space> = [
        {
            title: 'Name',
            key: 'name',
            dataIndex: ['cardData', 'title'],
        },
        {
            title: 'Author',
            key: 'author',
            dataIndex: 'author',
        },
        {
            title: 'Last Modified',
            key: 'lastModified',
            dataIndex: 'lastModified',
            render: (date: string) => dayjs(date).format('MM/DD/YYYY HH:mm'),
        },
        {
            title: 'Tags',
            key: 'tags',
            dataIndex: 'tags',
            render: (tags: string[]) => tags.join(', '),
        },
    ];

    return { columns };
}
