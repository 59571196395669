import { Typography } from 'antd';

export function PyLib() {
    return (
        <>
            <Typography.Title level={2}>Python Library Documentation</Typography.Title>

            <Typography.Title level={3}>Features</Typography.Title>

            <Typography>
                <ul>
                    <li>Share models directly from the Python API.</li>
                    <li>Publish and manage applications using the ModelPark Python API.</li>
                    <li>Configure access management according to your needs through Python methods.</li>
                </ul>
            </Typography>

            <Typography.Title level={3}>Installation</Typography.Title>

            <Typography.Paragraph>
                Please follow the instructions in the repository's README file to install the Python package.
            </Typography.Paragraph>

            <Typography.Link
                href="https://github.com/model-park/modelpark/"
                target="_blank"
            >
                ModelPark Python Library Repository
            </Typography.Link>

            {/* <Typography.Paragraph>To install ModelPark, you can use pip:</Typography.Paragraph>

            <Typography>
                <code>pip install modelpark</code>
            </Typography>

            <Typography.Title level={3}>Configuration</Typography.Title>

            <Typography.Paragraph>
                Ensure Python and pip are installed on your machine. This API interfaces with the ModelPark CLI but manages
                interactions programmatically through Python.
            </Typography.Paragraph>

            <Typography.Title level={3}>Usage</Typography.Title>

            <Typography.Paragraph>Here's how you can use the ModelPark Python package:</Typography.Paragraph>

            <Typography.Title level={4}>Initialize and Login</Typography.Title>

            <Typography>
                <pre>
                    from modelpark import ModelPark mp = ModelPark() # downloads the modelpark CLI binary/ executable to your home
                    folder as "~/modelpark' mp.login(username="your_username", password="your_password") mp.init()
                </pre>
            </Typography> */}
        </>
    );
}
