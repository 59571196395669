import { RoutingMap } from '@app/entities';
import {
    AboutUsIcon,
    AnalyticsIcon,
    AppStoreIcon,
    EditIcon,
    ModelparkLogo,
    ModelparkMobilLogo,
    NoteIcon,
} from '@model-park/assets';
import { MPMenu } from '@model-park/mp-components';
import { Layout } from 'antd';
import { ItemType } from 'antd/es/menu/hooks/useItems';
import UserProfile from '../UserProfile';
import Styles from './Styles.module.scss';
import useHeader from './useHeader';
import { useWindowSize } from 'react-use';

export default function Header() {
    const { menu, onClick } = useHeader();
    const { width } = useWindowSize();

    function renderMenuItems() {
        return menu.map(key => {
            const itemList = width < 768 ? MenuItemsWithoutLabel : MenuItems;
            return itemList.find(item => item?.key === key) as MenuItemType;
        });
    }

    return (
        <>
            <Layout.Header className={Styles.HeaderContainer}>
                <div className={Styles.MenuSection}>
                    <a
                        href="https://modelpark.app"
                        className={Styles.LogoContainer}
                    >
                        {width <= 500 ? (
                            <ModelparkMobilLogo className={Styles.logo} />
                        ) : (
                            <ModelparkLogo className={Styles.ModelparkLogo} />
                        )}
                    </a>

                    <MPMenu
                        items={renderMenuItems()}
                        onClick={onClick}
                        className={Styles.NavMenu}
                        theme="dark"
                        defaultSelectedKeys={[window.location.pathname]}
                        selectedKeys={[window.location.pathname]}
                    />
                </div>

                <UserProfile />
                {/* <ThemeSwitch /> */}
            </Layout.Header>
        </>
    );
}

const MenuItems: MenuItemType[] = [
    {
        key: RoutingMap.Home,
        label: 'Apps',
        icon: <MenuIcon name="Home" />,
        title: 'Home',
    },
    {
        key: RoutingMap.NewProject,
        label: 'New Project',
        icon: <MenuIcon name="NewProject" />,
        title: 'New Project',
    },
    {
        key: RoutingMap.Distribution,
        label: 'Distribution',
        icon: <MenuIcon name="Distribution" />,
        title: 'Distribution',
    },
    {
        key: RoutingMap.Analytcis,
        label: 'Analytics',
        icon: <MenuIcon name="Analytcis" />,
        title: 'Analytics',
        className: Styles.Analytics,
    },
    {
        key: RoutingMap.About,
        label: 'About',
        icon: <MenuIcon name="About" />,
        title: 'About',
    },
];
const MenuItemsWithoutLabel = MenuItems.map(({ label, ...rest }) => rest);

const MenuIconMap = {
    Home: <AppStoreIcon />,
    NewProject: <EditIcon />,
    Distribution: <NoteIcon />,
    Analytcis: <AnalyticsIcon />,
    About: <AboutUsIcon />,
};

function MenuIcon({ name }: { name: keyof typeof MenuIconMap }) {
    return <div className={Styles.MenuIcon}>{MenuIconMap[name]}</div>;
}
type MenuItemType = ItemType & { key: string; label?: string };
