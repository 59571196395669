import { MainLayout } from '@app/components';
import { RoutingMap } from '@app/entities';
import { useInitApp } from '@app/hooks';
import useGetThemeClass from '@app/hooks/useGetThemeClass';
import { getLocationSubdomain } from '@model-park/common';
import { MPFallback, MPResult } from '@model-park/mp-components';
import AboutUs from '@pages/AboutUs';
import Analytics from '@pages/Analytics/index';
import AppPage from '@pages/AppPage';
import Authentication from '@pages/Authentication';
import LegalClaimer from '@pages/Authentication/views/LegalClaimer';
import Distribution from '@pages/Distribution';
import MainDashBoard from '@pages/MainDashBoard';
import NewProject from '@pages/NewProject';
import Settings from '@pages/Settings';
import AccountSettings from '@pages/Settings/Account';
import OrganizationSettings from '@pages/Settings/Organization';
import CreateOrganization from '@pages/Settings/Organization/Create/CreateOrganization';
import OrganizationDetails from '@pages/Settings/Organization/Create/OrganizationDetails';
import ProfileSettings from '@pages/Settings/Profile';
import { Suspense } from 'react';
import { RouterProvider, createBrowserRouter } from 'react-router-dom';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.min.css';

export default function Router() {
    const { scrollClass } = useGetThemeClass();
    useInitApp();

    return (
        <>
            <div className={scrollClass}>
                <Suspense fallback={<MPFallback />}>
                    <RouterProvider
                        router={router}
                        fallbackElement={<MPFallback />}
                    />
                </Suspense>
            </div>

            <ToastContainer />
        </>
    );
}
const subdomain = getLocationSubdomain();
const router = createBrowserRouter([
    {
        path: RoutingMap.Home,
        element: <MainLayout />,
        children: subdomain
            ? [
                  {
                      path: '/',
                      element: <AppPage />,
                  },
              ]
            : [
                  {
                      path: '/',
                      element: <MainDashBoard />,
                  },
                  {
                      path: '/apps',
                      element: <MainDashBoard />,
                  },
                  {
                      path: '/app/:name',
                      element: <AppPage />,
                  },
                  {
                      path: RoutingMap.NewProject,
                      element: <NewProject />,
                  },
                  {
                      path: RoutingMap.AppPage,
                      element: <AppPage />,
                  },
                  {
                      path: RoutingMap.Distribution,
                      element: <Distribution />,
                  },
                  {
                      path: RoutingMap.Analytcis,
                      element: <Analytics />,
                  },
                  {
                      path: RoutingMap.About,
                      element: <AboutUs />,
                  },
                  {
                      path: RoutingMap.LegalClaimer,
                      element: <LegalClaimer />,
                  },
                  {
                      path: RoutingMap.Settings.Root,
                      element: <Settings />,
                      children: [
                          {
                              path: RoutingMap.Settings.Profile,
                              element: <ProfileSettings />,
                          },
                          {
                              path: RoutingMap.Settings.Account,
                              element: <AccountSettings />,
                          },
                          {
                              path: RoutingMap.Settings.Organizations,
                              element: <OrganizationSettings />,
                          },
                          {
                              path: RoutingMap.Settings.NewOrganization,
                              element: <CreateOrganization />,
                          },
                          {
                              path: RoutingMap.Settings.OrganitionDetails,
                              element: <OrganizationDetails />,
                          },
                      ],
                  },
              ],
    },
    {
        path: RoutingMap.Auth,
        element: <Authentication />,
        children: [
            {
                path: RoutingMap.Login,
                element: <Authentication />,
            },
            {
                path: RoutingMap.Signup,
                element: <Authentication />,
            },
        ],
    },
    {
        path: '*',
        element: <MPResult status={404} />,
    },
]);
