import {
    AIModelCategory,
    AIModelFramework,
    AIModelTask,
    AccessType,
    AppProjectType,
    AppProjectTypeMap,
    AppRegistration,
    AppSubscriptionType,
} from '@model-park/entities';

export interface IFormOption<T extends string = string> {
    value: T;
    label: string;
    disabled?: boolean;
    children?: IFormOption[];
}

export const AppRegistrationTypeOptions: Array<IFormOption<AppRegistration>> = [
    { value: AppRegistration.URI_REGISTRATION, label: 'URI Registration' },
    { value: AppRegistration.EXTERNAL_RESOURCE, label: 'External Resource', disabled: true },
];

export type ExternalResourceType = 'huggingface';
export const ExternalAppOptions: Array<IFormOption<ExternalResourceType>> = [
    {
        label: 'Hugging Face',
        value: 'huggingface',
    },
];

export interface ExternalResource {
    type: ExternalResourceType;
    resourceId: string;
    url: string;
    name: string;
    title: string;
    description: string;
}

export const AccessTypeOptions: Array<IFormOption<AccessType>> = [
    {
        value: AccessType.PUBLIC,
        label: 'Public',
    },
    {
        value: AccessType.PRIVATE,
        label: 'Private',
    },
    {
        value: AccessType.PROTECTED,
        label: 'Protected',
    },
];

export const AppProjectTypeOptions = Object.entries(AppProjectTypeMap).map(([value, label]) => ({
    value,
    label,
})) as Array<IFormOption<AppProjectType>>;

export const AIModelCategoryOptions: Array<IFormOption<AIModelCategory>> = [
    {
        value: AIModelCategory.LLM,
        label: 'LLM',
    },
    {
        value: AIModelCategory.Audio,
        label: 'Audio',
    },
    {
        value: AIModelCategory.NLP,
        label: 'NLP',
    },
    {
        value: AIModelCategory.CV,
        label: 'CV',
    },
    {
        value: AIModelCategory.MISC,
        label: 'MISC',
    },
    {
        value: AIModelCategory.OTHER,
        label: 'Other',
    },
];

export const AIModelTaskOptions: Array<IFormOption<AIModelTask>> = [
    {
        value: AIModelTask.Classification,
        label: 'Classification',
    },
    {
        value: AIModelTask.Detection,
        label: 'Detection',
    },
    {
        value: AIModelTask.Segmentation,
        label: 'Segmentation',
    },
    {
        value: AIModelTask.Generation,
        label: 'Generation',
    },
    {
        value: AIModelTask.Translation,
        label: 'Translation',
    },
    {
        value: AIModelTask.Summarization,
        label: 'Summarization',
    },
    {
        value: AIModelTask.NamedEntityRecognition,
        label: 'Named Entity Recognition',
    },
    {
        value: AIModelTask.Other,
        label: 'Other',
    },
];

export const AIModelFrameworkOptions: Array<IFormOption<AIModelFramework>> = [
    {
        value: AIModelFramework.Streamlit,
        label: 'Streamlit',
    },
    {
        value: AIModelFramework.Gradio,
        label: 'Gradio',
    },
];

export type Expire = 'never' | '1d' | '1w' | '1m' | '1y';
export const ExpiresOptions: Array<IFormOption<Expire>> = [
    {
        value: 'never',
        label: 'Never',
    },
    {
        value: '1d',
        label: '1 Day',
    },
    {
        value: '1w',
        label: '1 Week',
    },
    {
        value: '1m',
        label: '1 Month',
    },
    {
        value: '1y',
        label: '1 Year',
    },
];

export const PremiumUserAppSubscriptionTypeOptions: Array<IFormOption<AppSubscriptionType>> = [
    {
        value: AppSubscriptionType.FREE,
        label: 'Free',
    },
    {
        value: AppSubscriptionType.PRO,
        label: 'Pro',
    },
];

export const BasicUserAppSubscriptionTypeOptions: Array<IFormOption<AppSubscriptionType>> = [
    {
        value: AppSubscriptionType.FREE,
        label: 'Free',
    },
];
