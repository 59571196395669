import { getAuthToken } from '@common';
import { Expire } from '@model-park/common';
import { IUser } from '@model-park/models';
import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';

export const MPAPISlice = createApi({
    reducerPath: 'api',
    baseQuery: fetchBaseQuery({
        baseUrl: '/api',
        prepareHeaders: (headers, { getState }) => {
            const authToken = getAuthToken();
            if (authToken) {
                headers.set('Authorization', `Bearer ${authToken}`);
            }
            return headers;
        },
    }),
    tagTypes: ['AppProjectList', 'AppAccess', 'OrganizationList', 'ProfilePicture'],
    endpoints: builder => ({
        checkAuth: builder.mutation<Omit<IUser, 'password'>, undefined>({
            query: () => 'auth/checkAuth',
        }),

        isUriValidWithIFrame: builder.query<boolean, string>({
            query: name => `app-project/is-valid-app-uri/${name}`,
            providesTags: (_result, _error, arg) => [{ type: 'AppAccess', id: arg }],
        }),

        getTempUrl: builder.query<{ url: string }, { appName?: string; expiresIn?: Expire }>({
            query: ({ appName, expiresIn }) => ({
                url: `app-project/temp-url/${appName}`,
                params: { expiresIn },
            }),
        }),

        getUserId: builder.mutation<Pick<IUser, 'email' | '_id'>, string>({
            query: (email: string) => ({
                url: 'users/user-id',
                params: { email },
            }),
        }),

        getNewObjectId: builder.mutation<{ newObjectId: string }, undefined>({
            query: () => 'new-object-id',
        }),
    }),
});

export const {
    useCheckAuthMutation,
    useIsUriValidWithIFrameQuery,
    useGetTempUrlQuery,
    useGetUserIdMutation,
    useGetNewObjectIdMutation,
} = MPAPISlice;
