import { EventBus, useUpdateSearchParams } from '@model-park/common';
import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { useAppTracking } from './useAppTracking';

export function useSubscribeEventBus() {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const updateSearchParams = useUpdateSearchParams();
    useAppTracking();

    useEffect(() => {
        EventBus.on('navigate', ({ path, options }: any) => navigate(path, options));
        EventBus.on('updateSearchParams', updateSearchParams);
        EventBus.on('dispatch', dispatch as any);
    }, [dispatch, navigate, updateSearchParams]);
}
