import { Menu, MenuProps } from "antd";
import cs from "classnames";
import Styles from "./Styles.module.scss";

export function MPMenu({ className, ...props }: IMPMenuProps) {
    return (
        <>
            <Menu
                className={cs(Styles.MPMenu, className)}
                {...props}
            />
        </>
    );
}

export interface IMPMenuProps extends MenuProps {

}