import { Commands, DistTable, Installation } from '@pages/Distribution/components';
import { Typography } from 'antd';

const { Title } = Typography;

export function CLI() {
    return (
        <>
            <Title level={2}>CLI Documentation</Title>

            <Title level={3}>Features</Title>
            <Typography>
                <ul>
                    <li>Share your models from the modelpark dashboard</li>
                    <li>Publish your models from modelpark CLI with our CLI tunnel tool.</li>
                    <li>Config your access management according to your needs</li>
                </ul>
            </Typography>

            <Installation />
            <Commands />
            <DistTable />
        </>
    );
}
