import { Gradio, Streamlit } from '@assets/icons';
import { useCoverPicture } from '@common';
import { AIModelCategoryMap, AIModelFramework, AIModelTaskMap, AppProjectTypeMap } from '@model-park/entities';
import { IAppProject } from '@model-park/models';
import { MPTooltip } from '@model-park/mp-components';
import { Image, Tag } from 'antd';
import ConditionallyRender from 'react-conditionally-render';
import ModelImage from '../../../../../../assets/image/ModelImage.png';
import Styles from './Styles.module.scss';

export default function CardCover({ item }: IProps) {
    const { coverPicture } = useCoverPicture(item.name, item.coverPicture);

    return (
        <div className={Styles.ImageSection}>
            <Image
                src={coverPicture}
                alt={item.title}
                preview={false}
                className={Styles.CardImage}
                fallback={ModelImage}
            />
            <div className={Styles.ModelPropertiesConteiner}>
                <div className={Styles.TagContainer}>
                    {item?.tags?.map((tag, index) => (
                        <MPTooltip
                            key={tag + index}
                            title={renderTooltip('Tag', tag)}
                            placement="bottom"
                        >
                            <div className={Styles.Tag}>
                                <Tag
                                    color="#00C7F2"
                                    style={{ fontWeight: 'bold' }}
                                >
                                    {tag}
                                </Tag>
                            </div>
                        </MPTooltip>
                    ))}
                </div>

                <div className={Styles.ModelProperties}>
                    <ConditionallyRender
                        condition={Boolean(item?.appType)}
                        show={
                            <MPTooltip
                                title={renderTooltip('App Type', AppProjectTypeMap[item?.appType])}
                                placement="bottom"
                            >
                                <div className={Styles.AppType}>
                                    <span>{AppProjectTypeMap[item?.appType]}</span>
                                </div>
                            </MPTooltip>
                        }
                    />

                    <ConditionallyRender
                        condition={Boolean(AIModelCategoryMap[item?.aiModelProperties?.category])}
                        show={
                            <MPTooltip title={renderTooltip('Category', AIModelCategoryMap[item?.aiModelProperties?.category])}>
                                <div
                                    className={Styles.Property}
                                    style={tagColorMap?.[item?.aiModelProperties?.category]}
                                >
                                    {AIModelCategoryMap[item?.aiModelProperties?.category]}
                                </div>
                            </MPTooltip>
                        }
                    />
                    <ConditionallyRender
                        condition={Boolean(AIModelTaskMap[item?.aiModelProperties?.task])}
                        show={
                            <MPTooltip title={renderTooltip('Task', AIModelTaskMap[item?.aiModelProperties?.task])}>
                                <div
                                    className={Styles.Property}
                                    style={tagColorMap?.[item?.aiModelProperties?.task]}
                                >
                                    {AIModelTaskMap[item?.aiModelProperties?.task]}
                                </div>
                            </MPTooltip>
                        }
                    />
                </div>
                <ConditionallyRender
                    condition={Boolean(FrameworkIconMap[item?.aiModelProperties?.framework])}
                    show={
                        <MPTooltip
                            title={renderTooltip('Framework', item?.aiModelProperties?.framework)}
                            placement="bottom"
                        >
                            <div className={Styles.FrameworkIcon}>{FrameworkIconMap[item?.aiModelProperties?.framework]}</div>
                        </MPTooltip>
                    }
                />
            </div>
        </div>
    );
}

export interface IProps {
    item: IAppProject;
}

export function renderTooltip(title: string, content: string) {
    return `${title}: ${content}`;
}

const FrameworkIconMap = {
    [AIModelFramework.Gradio]: <Gradio />,
    [AIModelFramework.Streamlit]: <Streamlit />,
};

const tagColorMap: Dictionary<React.CSSProperties> = {
    other: { backgroundColor: '#A8FFA6', color: '#50B04D' },
    nlp: { backgroundColor: '#19CFBD', color: '#fff' },
    classification: { backgroundColor: '#FF5C00', color: '#fff' },
    detection: { backgroundColor: '#FFC0C2', color: '#F66568' },
    cv: { backgroundColor: '#634BDD', color: '#fff' },
};
