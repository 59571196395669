import { Table, Typography } from 'antd';

const { Title, Paragraph } = Typography;

export default function Register() {
    return (
        <>
            <Title level={4}>Register:</Title>
            <Paragraph>Register your app to Modelpark.</Paragraph>
            <Typography>
                <code>modelpark register</code>
            </Typography>
            <Title level={5}>Parameters:</Title>
            <Paragraph>File Path: Path to your model file.</Paragraph>
            <Title level={5}>Options:</Title>
            <Table
                columns={registerColumns}
                dataSource={registerData}
                pagination={false}
                scroll={{ x: 'max-content' }}

            />
            <Title level={5}>Example:</Title>
            <Typography>
                <code>modelpark register ~/my-app/streamlit-app.py -p 3000 -n my-app -a public -f streamlit</code>
            </Typography>
        </>
    );
}

const registerColumns = [
    {
        title: 'Name',
        dataIndex: 'name',
        key: 'name',
    },
    {
        title: 'Type',
        dataIndex: 'type',
        key: 'type',
    },
    {
        title: 'Description',
        dataIndex: 'description',
        key: 'description',
    },
    {
        title: 'Default value',
        dataIndex: 'defaultValue',
        key: 'defaultValue',
    },
];

const registerData = [
    {
        key: '1',
        name: '--port, -p (required)',
        type: 'number',
        description: 'Port for the local server.',
        defaultValue: 'undefined',
    },
    {
        key: '2',
        name: '--name, -n (required)',
        type: 'string',
        description: 'App name.',
        defaultValue: 'undefined',
    },
    {
        key: '3',
        name: '--access, -a (optional)',
        type: 'string',
        description: 'App Access Type (public or private).',
        defaultValue: 'private',
    },
    {
        key: '4',
        name: '--framework, -f (optional)',
        type: 'string',
        description: 'Framework (streamlit).',
        defaultValue: 'undefined',
    },
];
