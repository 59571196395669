import { LockOutlined, LoginOutlined } from '@ant-design/icons';
import { useGetAppAccessTypeQuery } from '@app/services';
import { getAuthToken } from '@common';
import { MPModal } from '@model-park/mp-components';
import { Login } from '@pages/Authentication/views';
import { Collapse, Space } from 'antd';
import useAppname from '../useAppname';
import PasswordForm from './PasswordForm';
import PasswordInfo from './PasswordInfo';
import Styles from './Styles.module.scss';

export default function AppAuthorization({ refetch }: IProps) {
    const authToken = getAuthToken();
    const { appName } = useAppname();

    const { passwordExists } = useGetAppAccessTypeQuery(appName, {
        skip: !appName,
        selectFromResult: ({ data }) => ({
            accessType: data?.type,
            passwordRequired: Boolean(data?.passwordRequired),
            passwordExists: Boolean(data?.passwordExists),
        }),
    });

    return (
        <MPModal
            open
            footer={null}
            title={
                <Space>
                    <LockOutlined />
                    <span>Authorization</span>
                </Space>
            }
            closable={false}
        >
            <Collapse
                bordered={false}
                defaultActiveKey={passwordExists ? ['info', 'password'] : ['info', 'signin']}
                className={Styles.AppPassword}
                items={[
                    {
                        key: 'password',
                        label: (
                            <Space>
                                <LockOutlined />
                                <span>Enter password</span>
                            </Space>
                        ),
                        collapsible: passwordExists ? undefined : 'disabled',
                        children: <PasswordForm refetch={refetch} />,
                    },
                    {
                        key: 'signin',
                        label: (
                            <Space>
                                <LoginOutlined />
                                <span>Sign in</span>
                            </Space>
                        ),
                        collapsible: authToken ? 'disabled' : undefined,
                        children: (
                            <Login
                                onSuccess={refetch}
                                withCard={false}
                            />
                        ),
                    },
                    {
                        key: 'info',
                        label: "Why can't I access this app?",
                        children: <PasswordInfo />,
                    },
                ]}
            />
        </MPModal>
    );
}

interface IProps {
    refetch: () => void;
}
