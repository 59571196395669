import { AppProjectAbility, _entries } from '@model-park/common';
import { JWTPayload } from '@model-park/entities';
import { IAppProject } from '@model-park/models';
import { selectAuthUser } from '@pages/Authentication/store/selectors';
import { useMemo } from 'react';
import { useSelector } from 'react-redux';

export function useAppProjectAbility() {
    const user = useSelector(selectAuthUser);
    return useMemo(
        () =>
            user
                ? AppProjectAbility(new JWTPayload(user?._id?.toString(), user?.email, user?.role), AppProjectClone as any)
                : null,
        [user]
    );
}

export class AppProjectClone {
    constructor(appProject?: IAppProject) {
        _entries(appProject).forEach(([key, value]) => {
            (this as any)[key] = value;
        });
    }
}
