import { Modals } from '@app/entities';
import { useModalState } from '@app/hooks';
import { ExternalAppOptions, ExternalResourceType } from '@model-park/common';
import { AppRegistration } from '@model-park/entities';
import { MPModal, MPSelect } from '@model-park/mp-components';
import { useCallback, useState } from 'react';
import ResourceForm from './ResourceForm';
import Styles from './Styles.module.scss';
import { IExternalResourceModalProps, IExternalResourceModalState } from './types';

export default function ImportExternalResource({ form }: IExternalResourceModalProps) {
    const [{ open, state }, updateModalState] = useModalState<IExternalResourceModalState>(Modals.ImportExternalApp);
    const [selectedResource, setSelectedResource] = useState<ExternalResourceType>("huggingface");

    const onModalComplete = useCallback(
        () => {
            updateModalState({ open: false });
            if (!state?.selectedResource) return;
            const { selectedResource } = state;
            form.setFieldsValue({
                name: selectedResource.name,
                title: selectedResource.title,
                description: selectedResource.description,
                registrationType: AppRegistration.EXTERNAL_RESOURCE,
                resourceId: selectedResource.resourceId,
                registrationConfig: {
                    checkConnection: true,
                    uri: selectedResource.url,
                },
            });
        },
        [form, state, updateModalState],
    );


    return (
        <MPModal
            className={Styles.ExternalResourceModal}
            open={open}
            onCancel={() => updateModalState({ open: false })}
            onOk={onModalComplete}
            title={"Import from external resources"}
            width={1000}
            bodyStyle={{
                maxHeight: "70vh",
                overflow: "auto"
            }}
            okButtonProps={{ disabled: !state?.selectedResource }}
        >
            <MPSelect<ExternalResourceType>
                options={ExternalAppOptions}
                value={selectedResource}
                onChange={setSelectedResource}
                className={Styles.ExternalResourceSelection}
            />

            <ResourceForm
                selectedResource={selectedResource}
            />

        </MPModal>
    );
}

