import { Alert, AlertProps } from 'antd';

export function MPAlert({ ...props }: IMPAlertProps) {
    return (
        <>
            <Alert {...props} />
        </>
    );
}

export interface IMPAlertProps extends AlertProps {

}