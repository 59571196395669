import { MPAPISlice } from '@app/services';
import { LoginRequest, RegisterRequest, UpdatePasswordRequest, UserResponse } from '@model-park/models';

export const AuthApiSlice = MPAPISlice.injectEndpoints({
    endpoints: builder => ({
        login: builder.mutation<UserResponse, LoginRequest>({
            query: credentials => ({
                url: 'auth/login',
                method: 'POST',
                body: credentials,
            }),
            invalidatesTags: ['AppProjectList', 'AppAccess'],
        }),
        signup: builder.mutation<UserResponse, RegisterRequest>({
            query: payload => ({
                url: 'auth/register',
                method: 'POST',
                body: payload,
            }),
        }),
        updatePassword: builder.mutation<any, UpdatePasswordRequest>({
            query: body => ({
                url: 'auth/update-password',
                method: 'POST',
                body,
            }),
        }),
    }),
});

export const { useLoginMutation, useSignupMutation, useUpdatePasswordMutation } = AuthApiSlice;
