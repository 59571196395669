import SyncOutlined from '@ant-design/icons/SyncOutlined';
import { MPButton, MPSelect } from '@model-park/mp-components';
import { Space, Typography } from 'antd';
import Styles from './Styles.module.scss';
import { OptionType, useAppSelection } from './useAppSelection';

export function AppSelection() {
    const { selectedApp, onChangeSelectedApp, options, isFetching, refetch, filterOption } = useAppSelection();
    return (
        <Space className={Styles.AppSelectionRow}>
            <Typography>Select App:</Typography>
            <div>
                <MPSelect<string, OptionType>
                    showSearch
                    value={selectedApp}
                    onChange={onChangeSelectedApp}
                    options={options}
                    loading={isFetching}
                    className={Styles.AppSelection}
                    placeholder="Select an app"
                    filterOption={filterOption}
                />

                <MPButton
                    icon={<SyncOutlined />}
                    onClick={() => refetch()}
                />
            </div>
        </Space>
    );
}
