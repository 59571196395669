import { AIModelCategoryOptions, AIModelFrameworkOptions, AIModelTaskOptions, AppProjectTypeOptions } from '@model-park/common';
import { MPGrid, MPSelect } from '@model-park/mp-components';
import { Form } from 'antd';

export default function AiModelProperties() {
    return (
        <>
            <MPGrid gridOptions={gridOptions}>
                <Form.Item
                    name={'appType'}
                    label={'Type'}
                    rules={[{ required: true, message: 'Please select a type' }]}
                >
                    <MPSelect
                        placeholder="App Type"
                        options={AppProjectTypeOptions}
                    />
                </Form.Item>
                <Form.Item
                    name={['aiModelProperties', 'category']}
                    label={'Category'}
                    rules={[{ required: true, message: 'Please select a category' }]}
                >
                    <MPSelect
                        placeholder="Category"
                        options={AIModelCategoryOptions}
                    />
                </Form.Item>
                <Form.Item
                    name={['aiModelProperties', 'task']}
                    label={'Task'}
                >
                    <MPSelect
                        placeholder="Task"
                        options={AIModelTaskOptions}
                        allowClear
                    />
                </Form.Item>
                <Form.Item
                    name={['aiModelProperties', 'framework']}
                    label={'Framework'}
                >
                    <MPSelect
                        placeholder="Framework"
                        options={AIModelFrameworkOptions}
                        allowClear
                    />
                </Form.Item>
            </MPGrid>
        </>
    );
}

const gridOptions = {
    xs: { gap: 24, columns: 2 },
    sm: { gap: 24, columns: 2 },
    md: { gap: 24, columns: 2 },
    lg: { gap: 24, columns: 4 },
    xl: { gap: 24, columns: 4 },
    xxl: { gap: 24, columns: 4 },
};
