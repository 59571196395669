import { getAppPassword } from '@common';
import { AppProjectQueryParams, base64ResponseHandler } from '@model-park/common';
import { AppAccess, AppAccessOptions, AppProjectStats } from '@model-park/entities';
import { IAppProject, IUserFavorites } from '@model-park/models';
import { MPAPISlice } from './ModelParkAPI';
import { ServerEvent } from './ServerEvent';

export const AppProjectAPI = MPAPISlice.injectEndpoints({
    endpoints: builder => ({
        getAppProjects: builder.query<{ data: Array<IAppProject>; count: number }, AppProjectQueryParams>({
            query: params => ({ url: `app-project/`, params }),
            providesTags: ['AppProjectList'],
            async onCacheEntryAdded(arg, { updateCachedData, cacheDataLoaded, cacheEntryRemoved }) {
                const abortController = new AbortController();

                const serverEvent = new ServerEvent(
                    `api/project-tracking`,
                    data => {
                        updateCachedData(draft => {
                            const index = draft.data?.findIndex(item => item._id === data.payload?._id);
                            if (index !== -1) {
                                draft.data[index] = data?.payload;
                            }
                        });
                    },
                    undefined,
                    abortController
                );
                await serverEvent.subscribeEvent();
                await cacheDataLoaded;
                cacheEntryRemoved.then(() => {
                    abortController.abort();
                });
            },
        }),

        getAppProjectsCount: builder.query<number, AppProjectQueryParams>({
            query: ({ limit, search, skip, sort }) => ({
                url: `app-project/count`,
                params: { limit, search, skip, sort },
            }),
        }),

        getAppDetails: builder.query<IAppProject, string>({
            query: id => ({ url: `app-project/${id}` }),
            providesTags: (_result, _error, id) => [{ type: 'AppProjectList', id }],
        }),

        getAppAccessType: builder.query<AppAccessOptions, string>({
            query: name => ({ url: `app-project/access-type/${name}` }),
        }),

        updateAppProject: builder.mutation<IAppProject, { body: FormData; id: string }>({
            query: ({ id, body }) => ({
                url: `app-project/${id}`,
                method: 'PATCH',
                body,
            }),
            invalidatesTags: app => ['AppProjectList', { type: 'AppAccess', id: app?.name }],
        }),

        createNewAppProject: builder.mutation<IAppProject, FormData>({
            query: body => ({
                url: `app-project/`,
                method: 'POST',
                body,
            }),
            invalidatesTags: ['AppProjectList'],
        }),

        deleteProject: builder.mutation<IAppProject, string>({
            query: id => ({
                url: `app-project/${id}`,
                method: 'DELETE',
            }),
            invalidatesTags: ['AppProjectList'],
        }),

        toggleAppFavorite: builder.mutation<IUserFavorites, string>({
            query: appId => ({
                url: `user-favorites/toggle-app`,
                body: { appId },
                method: 'PATCH',
            }),
            invalidatesTags: ['AppProjectList'],
        }),
        getRegistrationTypeStats: builder.query<AppProjectStats, undefined>({
            query: () => ({
                url: `app-project/stats`,
            }),
        }),

        getAppAccess: builder.query<AppAccess, string>({
            query: name => ({
                url: `app-project/access/${name}`,
                params: {
                    password: getAppPassword(name),
                },
            }),
            providesTags: (_result, _error, id) => [{ type: 'AppAccess', id }],
        }),

        checkAppPassword: builder.mutation<{ authToken: string }, { name: string; password: string }>({
            query: ({ name, password }) => ({
                url: `app-project/check-app-password`,
                params: { name, password },
            }),
        }),

        isAppNameUnique: builder.mutation<{ isUnique: boolean }, { name: string }>({
            query: ({ name }) => ({
                url: `app-project/is-app-name-unique`,
                params: { name },
            }),
        }),

        requestAccess: builder.mutation<{ success: boolean }, { name: string; message?: string }>({
            query: ({ name, message }) => ({
                url: `access-request`,
                method: 'POST',
                body: { name, message },
            }),
        }),

        getCoverPicture: builder.query<string, string>({
            query: name => ({
                url: `app-project/cover-picture/${name}`,
                responseHandler: base64ResponseHandler,
            }),
            providesTags: (_result, _error, id) => [{ type: 'AppProjectList', id }],
        }),

        getOriginalUri: builder.query<string, string>({
            query: name => ({
                url: `app-project/original-uri/${name}`,
                responseHandler: response => response?.text(),
            }),
        }),
    }),
});

export const {
    useGetAppProjectsQuery,
    useGetAppProjectsCountQuery,
    useCreateNewAppProjectMutation,
    useGetAppDetailsQuery,
    useUpdateAppProjectMutation,
    useDeleteProjectMutation,
    useToggleAppFavoriteMutation,
    useGetRegistrationTypeStatsQuery,
    useGetAppAccessQuery,
    useGetAppAccessTypeQuery,
    useCheckAppPasswordMutation,
    useIsAppNameUniqueMutation,
    useRequestAccessMutation,
    useGetCoverPictureQuery,
    useGetOriginalUriQuery,
} = AppProjectAPI;
