import { RoutingMap } from '@app/entities';
import { getAuthToken } from '@common';
import { URLHelper, getLocationParts } from '@model-park/common';
import { selectAuthUser } from '@pages/Authentication/store/selectors';
import { MenuProps } from 'antd';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';

export default function useHeader() {
    const navigate = useNavigate();
    const user = useSelector(selectAuthUser);
    const authToken = getAuthToken();

    const { subdomain, maindomain } = getLocationParts();

    const onClick: MenuProps['onClick'] = e => {
        if (subdomain) {
            const newLocation = new URLHelper(`${window.location.protocol}//${maindomain}${e?.key}`, {
                authToken,
            });
            window.location.href = newLocation.toString();
        } else {
            navigate(`${e?.key}`);
        }
    };

    const menu = user ? PrivateMenuItems : PublicMenuItems;
    return { menu, onClick };
}

export const PublicMenuItems = [RoutingMap.Home, RoutingMap.Distribution, RoutingMap.Analytcis, RoutingMap.About];

export const PrivateMenuItems = [
    RoutingMap.Home,
    RoutingMap.NewProject,
    RoutingMap.Distribution,
    RoutingMap.Analytcis,
    RoutingMap.About,
];
