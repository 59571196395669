import { ProfileFormValues, _entries, _isEqual } from '@model-park/common';
import { IUser } from '@model-park/models';
import { MPForm, MPInput, MPTextArea } from '@model-park/mp-components';
import { selectAuthUser } from '@pages/Authentication/store';
import { Col, Form, Row } from 'antd';
import { useCallback, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useIsUserNameUniqueMutation, useUpdateProfileMutation } from '../services/UserService';
import ProfilePicture from './ProfilePicture';
import Styles from './Styles.module.scss';
import { Errors } from '@model-park/entities';

export default function ProfileSettings() {
    const [form] = Form.useForm<ProfileFormValues>();
    const user = useSelector(selectAuthUser);
    const [updateProfile, { isLoading: updateLoading }] = useUpdateProfileMutation();
    const [isUserNameUnique] = useIsUserNameUniqueMutation();

    const checkFieldUniqueness = useCallback(
        async (_: any, value: string) => {
            try {
                const isUnique = await isUserNameUnique({ userName: value }).unwrap();
                return isUnique ? Promise.resolve() : Promise.reject(Errors.userNameExists);
            } catch (error) {
                return Promise.reject(Errors.userNameExists);
            }
        },
        [isUserNameUnique]
    );
    useEffect(() => {
        if (!user) return;
        form.setFieldsValue(user);
    }, [user, form]);

    const onFinish = useCallback(
        async (values: ProfileFormValues) => {
            try {
                if (!user) return;
                const payload = getDifferences(values, user as IUser);
                await updateProfile({ body: payload as any, id: user?._id as string }).unwrap();
            } catch (error: any) {
                console.log(error);
            }
        },
        [user, updateProfile]
    );
    return (
        <div>
            <h1 className={Styles.Title}>Profile Setting</h1>
            <MPForm<ProfileFormValues>
                onFinish={onFinish}
                layout="vertical"
                loading={updateLoading}
                form={form}
                className={Styles.ProfileSettingForm}
                submitclassName={Styles.SubmitButton}
                submitBtnText="Update Profile"
            >
                <Row gutter={48}>
                    <Col flex="auto">
                        <Form.Item
                            name="fullName"
                            label="Full Name"
                            rules={[
                                {
                                    type: 'string',
                                    message: 'The input is not valid name',
                                },
                            ]}
                        >
                            <MPInput
                                placeholder="Full Name"
                                size="large"
                            />
                        </Form.Item>
                        <Form.Item
                            hasFeedback
                            validateTrigger="onBlur"
                            name={'userName'}
                            label={'User Name'}
                            rules={[
                                {
                                    type: 'string',
                                    message: 'The input is not valid name',
                                },
                                {
                                    required: true,
                                    message: 'Please input your user name!',
                                },
                                {
                                    pattern: /^[a-zA-Z0-9]+$/,
                                    message: 'No space or special character allowed',
                                },

                                ({ getFieldValue }) => ({
                                    validator(_, value) {
                                        if (value !== user?.userName) {
                                            return checkFieldUniqueness(_, value);
                                        }
                                        return Promise.resolve();
                                    },
                                }),
                            ]}
                            normalize={(value: string) => value?.toLowerCase()}
                        >
                            <MPInput
                                placeholder="User Name"
                                size="large"
                            />
                        </Form.Item>
                        <Form.Item
                            name="homePage"
                            label="Home Page"
                            rules={[
                                {
                                    type: 'url',
                                    message: 'The input is not valid url',
                                },
                            ]}
                        >
                            <MPInput
                                placeholder="Home Page"
                                size="large"
                            />
                        </Form.Item>
                        <Form.Item
                            name="interests"
                            label="Your AI & ML Interests"
                        >
                            <MPTextArea
                                placeholder="Share your interests in AI & ML, e.g., neural networks, data analysis..."
                                size="large"
                                maxLength={200}
                                showCount
                                autoSize={{ minRows: 3 }}
                            />
                        </Form.Item>
                        <Form.Item
                            name="github"
                            label="GitHub User Name"
                            rules={[
                                {
                                    type: 'string',
                                    message: 'The input is not valid name',
                                },
                            ]}
                        >
                            <MPInput
                                placeholder="GitHub User Name Page"
                                size="large"
                            />
                        </Form.Item>
                        <Form.Item
                            name="company"
                            label="Company"
                        >
                            <MPInput
                                placeholder="Company Name"
                                size="large"
                            />
                        </Form.Item>{' '}
                        <Form.Item
                            name="location"
                            label="Location"
                            rules={[
                                {
                                    type: 'string',
                                    message: 'The input is not valid name',
                                },
                            ]}
                        >
                            <MPInput
                                placeholder="Location"
                                size="large"
                            />
                        </Form.Item>
                    </Col>

                    <Col>
                        <ProfilePicture />
                    </Col>
                </Row>
            </MPForm>
        </div>
    );
}

function getDifferences(payload: ProfileFormValues, data: IUser) {
    const differences: Partial<IUser> = {};
    _entries(payload).forEach(([key, value]) => {
        if (!_isEqual(value, data?.[key])) {
            (differences as any)[key] = value;
        }
    });

    return differences;
}
