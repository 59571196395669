import { blobToBase64 } from './blobToBase64';

export async function base64ResponseHandler(response: Response) {
    try {
        if (!response.ok) return;
        const blob = await response.blob();
        return blobToBase64(blob);
    } catch (error) {
        console.error('base64ResponseHandler', error);
    }
}
