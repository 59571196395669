export * from "./Factory";
export * from "./interface";


export const SettingRouteMap = {
    Root: "/settings",
    Profile: "/settings/profile",
    Account: "/settings/account",
    Billing: "/settings/billing",
    Organizations: "/settings/organizations",
    OrganitionDetails: "/settings/organizations/:organization",
    NewOrganization: "/settings/organizations/new",
    Security: "/settings/security",
    Notifications: "/settings/notifications",
    Integrations: "/settings/integrations",
    Help: "/settings/help",
    About: "/settings/about",
} as const;

export const RoutingMap = {
    Home: '/',
    Auth: '/auth',
    Login: '/auth/login',
    Signup: '/auth/signup',
    ForgotPassword: '/auth/forgot-password',
    NewProject: '/new-project',
    AppPage: '/:appName',
    Distribution: '/distribution',
    Analytcis: '/analytics',
    About: '/about-us',
    LegalClaimer: '/legal-claimer',
    Settings: SettingRouteMap,
} as const;




export enum Modals {
    AppDetails = "AppDetails",
    ImportExternalApp = "ImportExternalApp",
}