export enum UserRole {
    USER = 'user',
    ADMIN = 'admin',
}

export enum UserType {
    USER = 'user',
    TEAM = 'team',
}

export enum AccessAction {
    MANAGE = 'manage',
    CREATE = 'create',
    READ = 'read',
    UPDATE = 'update',
    DELETE = 'delete',
    ACCESS = 'access',
}

export enum AccessType {
    PUBLIC = 'public',
    PRIVATE = 'private',
    PROTECTED = 'protected',
}

export enum LogLevel {
    INFO = 'info',
    ERROR = 'error',
    DEBUG = 'debug',
    WARNING = 'warning',
}

export * from './app-project';
export * from './errors';
export * from './subscription';
export * from './track-events';
export * from './access-request';
