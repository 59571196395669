import { _entries } from '@model-park/common';
import { Grid } from 'antd';
import type { Breakpoint } from 'antd/lib';

export function useCurrentBreakpoint(size?: Size): Breakpoint {
    const breakpoints = Grid.useBreakpoint();

    const activeBreakpoints = _entries(breakpoints)
        .filter(([_, value]) => value)
        .map(([key]) => key as Breakpoint);

    const currentBreakpoint = size?.width
        ? _entries(BreakpointMap).find(([_, [min, max]]) => size?.width && size?.width >= min && size?.width < max)?.[0]
        : findBiggestBreakpoint(activeBreakpoints);

    return currentBreakpoint || 'xs';
}

function findBiggestBreakpoint(breakpoints: Array<Breakpoint>) {
    const breakpointOrder: Array<Breakpoint> = ['xxl', 'xl', 'lg', 'md', 'sm', 'xs'];
    return breakpointOrder.find(breakpoint => breakpoints.includes(breakpoint));
}

export interface Size {
    width?: number;
    height?: number;
}

const BreakpointMap: Record<Breakpoint, [number, number]> = {
    xs: [0, 576],
    sm: [576, 768],
    md: [768, 992],
    lg: [992, 1200],
    xl: [1200, 1600],
    xxl: [1600, Infinity],
};
