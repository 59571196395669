import { EditOutlined, ExportOutlined } from '@ant-design/icons';
import { useGetOriginalUriQuery } from '@app/services';
import { AppProjectClone, useAppDispatch, useAppProjectAbility } from '@common';
import { MagicBrush } from '@model-park/assets';
import { AccessAction } from '@model-park/entities';
import { IAppProject } from '@model-park/models';
import { setEditAppName, setEditDrawerOpen } from '@pages/MainDashBoard/store';
import { FloatButton } from 'antd';
import useAppname from './useAppname';

export default function ActionButtons({ appInfo }: Props) {
    const { appName } = useAppname();
    const ability = useAppProjectAbility();
    const dispatch = useAppDispatch();

    function handleEditDrawerOpen(name?: string) {
        if (!name) return;
        dispatch(setEditAppName(name));
        dispatch(setEditDrawerOpen(true));
    }
    const { currentData: originalUri } = useGetOriginalUriQuery(appName, { skip: !appInfo });
    const canEdit = ability?.can?.(AccessAction.UPDATE, new AppProjectClone(appInfo as IAppProject) as any) || false;

    if (!appInfo) return null;

    return (
        <>
            <FloatButton.Group
                trigger="hover"
                style={{ right: 24 }}
                icon={<MagicBrush />}
            >
                {originalUri && (
                    <FloatButton
                        icon={<ExportOutlined />}
                        tooltip="If it is taking too loading to load, click here to go to original uri"
                        href={originalUri}
                        target="_blank"
                    />
                )}
                <FloatButton
                    tooltip="Edit App"
                    icon={<EditOutlined />}
                    onClick={() => canEdit && handleEditDrawerOpen(appInfo?.name)}
                />
            </FloatButton.Group>
        </>
    );
}

interface Props {
    appInfo?: IAppProject;
}
