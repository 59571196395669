import { Seo } from '@common/components';
import Lottie from 'react-lottie';
import { useWindowSize } from 'react-use';
import CardSection from './CardSection';
import Styles from './Styles.module.scss';
import lottie from './lottie.json';
import lottie2 from './lottie2.json';

export default function AboutUs() {
    const { width } = useWindowSize();

    const isTabletSize = 577 < width && width < 890;
    const isMobileSize = width < 576;
    const lottieSize = isTabletSize ? 300 : isMobileSize ? 240 : 400;
    const defaultOptions1 = {
        loop: true,
        autoplay: true,
        animationData: lottie,
        rendererSettings: {
            preserveAspectRatio: 'xMidYMid slice',
        },
    };
    const defaultOptions2 = {
        loop: true,
        autoplay: true,
        animationData: lottie2,
        rendererSettings: {
            preserveAspectRatio: 'xMidYMid slice',
        },
    };

    return (
        <>
            <Seo
                title="About ModelPark"
                description="Join ModelPark, an innovative platform revolutionizing data science and AI research. Connect with a vibrant community reshaping digital innovation, where creativity meets convenience. Contact us for more information or to request features and report issues."
                name="modelpark"
                keywords="CLI, localhost,cloud server, modelpark, digital innovations, data scientists, AI researchers, applications, platform"
            />
            <div className={Styles.AboutUs}>
                <div className={Styles.Top}>
                    <h1 className={Styles.Hero}>
                        <strong>About ModelPark</strong>
                    </h1>
                    <div className={Styles.SubHero}>We're in business to demonstrate your applications</div>
                    <div className={Styles.Description}>
                        <strong>ModelPark</strong> is an innovative platform designed to revolutionize the way data scientists, AI
                        researchers, and web developers share and demonstrate their applications.
                    </div>
                </div>

                <div className={Styles.Middle}>
                    <div className={Styles.TextSection}>
                        <h2>Join ModelPark today</h2>
                        <h2> be part of a community</h2>
                        <div className={Styles.MiddleDesc}>
                            We're reshaping the way we share and discover digital innovations. Where your creativity meets
                            convenience, that's where you'll find <strong>ModelPark.</strong>
                            You can contact us at <a href="mailto: info@modelpark.app"> info@modelpark.app </a> for more
                            information, to make a feature request, or to report an issue.
                        </div>
                    </div>

                    <div>
                        <Lottie
                            options={defaultOptions1}
                            height={lottieSize}
                            width={lottieSize}
                        />
                    </div>
                </div>
                <div className={Styles.Middle}>
                    <div>
                        <Lottie
                            options={defaultOptions2}
                            height={lottieSize}
                            width={lottieSize}
                        />
                    </div>
                    <div className={Styles.TextSection}>
                        <h2>We simplify</h2>
                        <h2> global app sharing</h2>
                        <div className={Styles.MiddleDesc}>
                            <strong>ModelPark</strong> understands the challenges of showcasing your work to the world. That's why
                            we've created a versatile solution that allows you to share your locally deployed or cloud-based apps
                            through easy-to-remember, secure URLs, eliminating the cumbersome and often risky practice of using IP
                            addresses and ports.
                        </div>
                    </div>
                </div>
                <CardSection />
                <div className={Styles.GradientBottom}></div>
                <div className={Styles.GradientTop}></div>
                <div className={Styles.GradientTopRight}></div>
            </div>
        </>
    );
}
