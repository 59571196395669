import { Segmented } from 'antd';
import { SegmentedProps } from 'antd/lib';
import cs from 'classnames';
import Styles from './Styles.module.scss';

export function MPSegmented({ className, ...props }: IMPSegmentedProps) {
    return (
        <Segmented
            className={cs(Styles.MPSegmentedContainer, className)}
            {...props}
        />
    );
}

export interface IMPSegmentedProps extends Omit<SegmentedProps, 'ref'> {}
