import { camelCaseToTitle, DateFormat, ICustomFormElementProps } from '@model-park/common';
import { AccessAction } from '@model-park/entities';
import { Permission } from '@model-park/models';
import { MPSwitch, MPTable } from '@model-park/mp-components';
import { Collapse, Form } from 'antd';
import { ColumnsType } from 'antd/es/table';
import dayjs from 'dayjs';
import EmailForm from './EmailForm';
import Styles from './Styles.module.scss';

export default function PermissionsTable() {
    return (
        <Collapse
            className={Styles.Permissions}
            items={[
                {
                    key: 'permissions',
                    label: 'Permissions',
                    children: (
                        <>
                            <EmailForm />

                            <Form.Item
                                name={['accessManagement', 'permissions']}
                                valuePropName="dataSource"
                            >
                                <MPTable
                                    bordered={true}
                                    columns={columns}
                                />
                            </Form.Item>
                        </>
                    ),
                },
            ]}
        />
    );
}

const columns: ColumnsType<Permission> = [
    {
        dataIndex: 'email',
        title: 'Email',
        align: 'center',
    },
    {
        dataIndex: 'permissions',
        title: 'Permissions',
        align: 'center',
        children: [AccessAction.MANAGE, AccessAction.READ, AccessAction.UPDATE, AccessAction.ACCESS].map(accessAction => ({
            title: camelCaseToTitle(accessAction),
            dataIndex: accessAction,
            align: 'center',
            render: (_, record, i) => (
                <Form.Item name={['accessManagement', 'permissions', i, 'access']}>
                    <PermissionSwitch accessAction={accessAction} />
                </Form.Item>
            ),
        })),
    },
    {
        dataIndex: 'createdAt',
        title: 'Added At',
        align: 'center',
        render: (value: string) => dayjs(value).format(DateFormat.Default),
    },
];

function PermissionSwitch({ value = [], onChange, accessAction }: PermissionSwitchProps) {
    const handleChange = (checked: boolean) => {
        if (checked) onChange?.([...value, accessAction]);
        else {
            onChange?.(value.filter(item => item !== accessAction));
        }
    };
    return (
        <MPSwitch
            checked={value.includes(accessAction)}
            onChange={handleChange}
        />
    );
}

interface PermissionSwitchProps extends ICustomFormElementProps<Array<string>> {
    accessAction: AccessAction;
}
