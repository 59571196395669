import Styles from './Styles.module.scss';

export default function LegalClaimer() {
    return (
        <div className={Styles.CheckModalContainer}>
            <h3>
                Welcome to ModelPark, a dynamic platform designed to facilitate the sharing and demonstration of applications by
                data scientists, AI researchers, and web developers. Our service enables users to share their locally deployed or
                cloud-based apps through secure, easy-to-remember URLs. While ModelPark is committed to providing an innovative
                and secure environment for showcasing applications, it is important for all users to acknowledge and agree to the
                following disclaimer regarding the use of our service:
            </h3>
            <div>
                <strong>Content Responsibility</strong>
                <p>
                    ModelPark serves as a tunneling platform that allows users to register and share applications through secure
                    URLs. The content, including but not limited to data, text, information, and applications tunneled through or
                    registered on ModelPark, is the sole responsibility of the user who posts, shares, or registers it. ModelPark
                    does not own, control, or endorse any content tunneled through its service and, therefore, cannot guarantee
                    the accuracy, integrity, or quality of such content.
                </p>
            </div>
            <div>
                <strong>Third-Party Services</strong>
                <p>
                    Users may register applications originally served by other vendors or companies. ModelPark is not responsible
                    for any content, functionality, or services provided by these third-party entities. ModelPark also doesn’t
                    claim any ownership of such content and servicese. Users are encouraged to review and accept any terms,
                    conditions, and disclaimers provided by these third-party services.
                </p>
            </div>
            <div>
                <strong>User Liability</strong>
                <p>
                    By using ModelPark, you acknowledge and agree that you are solely responsible for any content you tunnel,
                    share, or register through our platform. You must ensure that your content complies with all applicable laws,
                    regulations, and third-party rights. ModelPark will not be liable for any content that is tunneled through or
                    registered by users.
                </p>
            </div>
            <div>
                <strong>Indemnification</strong>
                <p>
                    Users agree to indemnify and hold harmless ModelPark, its affiliates, officers, agents, and employees from any
                    claim or demand, including reasonable attorneys' fees, made by any third party due to or arising out of
                    content you submit, post, tunnel, or transmit through ModelPark, your use of the service, your connection to
                    the service, your violation of the terms of service, or your violation of any rights of another.
                </p>
            </div>
            <div>
                <strong>No Warranty</strong>
                <p>
                    ModelPark provides its service "as is" and without any warranty or condition, express, implied, or statutory.
                    ModelPark specifically disclaims any implied warranties of title, merchantability, fitness for a particular
                    purpose, and non-infringement.
                </p>
            </div>
            <h3>
                By registering an application on ModelPark, you acknowledge that you have read, understood, and agree to be bound
                by this disclaimer. You also acknowledge that ModelPark has the right, but not the obligation, to monitor and
                remove content that violates this disclaimer or the terms of service. For any questions or concerns regarding this
                disclaimer, please contact us at info@modelpark.app.
            </h3>
        </div>
    );
}
