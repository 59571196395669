import React from 'react';
import { Typography, Table } from 'antd';

const { Title, Paragraph } = Typography;

export default function Logout() {
    return (
        <>
            <Title level={4}>Logout:</Title>
            <Paragraph>Remove saved credentials from your machine.</Paragraph>
            <Typography>
                <code>modelpark logout</code>
            </Typography>
        </>
    );
}
