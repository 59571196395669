import { Edit } from '@assets/icons';
import { modelImage } from '@assets/image';
import { ICustomFormElementProps } from '@model-park/common';
import { MPDropDown, MPProfileImageUpload } from '@model-park/mp-components';
import { Form, Image, MenuProps } from 'antd';
import Styles from './Styles.module.scss';
import { useCoverPicture } from '@common/hooks';

export default function CoverImageForm() {
    const form = Form.useFormInstance();
    const imageUrl = Form.useWatch<string>('coverPicture');
    const name = Form.useWatch<string>('name');
    const imageFile = Form.useWatch<File>('coverPictureFile');
    const { coverPicture } = useCoverPicture(name, imageUrl || form.getFieldValue('coverPicture'));

    return (
        <div className={Styles.CoverImageForm}>
            <Image
                src={imageFile ? URL.createObjectURL(imageFile) : coverPicture || modelImage}
                alt="profile-image"
                preview={false}
                width={200}
                height={200}
                className={Styles.AppCover}
            />

            <Form.Item
                name="coverPictureFile"
                noStyle
            >
                <ImageForm />
            </Form.Item>
        </div>
    );
}

function ImageForm({ onChange }: ICustomFormElementProps<File>) {
    const items: MenuProps['items'] = [
        {
            key: 'upload',
            label: <MPProfileImageUpload onImageChanged={onChange} />,
        },
        // {
        //     key: 'random',
        //     label: 'getRandomImage',
        // },
    ];

    return (
        <MPDropDown
            menu={{ items }}
            trigger={['click']}
        >
            <div className={Styles.Edit}>
                <Edit />
                <span>Edit</span>
            </div>
        </MPDropDown>
    );
}
