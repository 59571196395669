import { selectCurrentUser } from '@app/store/selectors';
import { Edit } from '@assets/icons';
import { IUser } from '@model-park/models';
import { MPDropDown, MPProfileImageUpload } from '@model-park/mp-components';
import { updateAuthState } from '@pages/Authentication/store';
import { Avatar, Image, MenuProps } from 'antd';
import { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
    useGetProfilePictureQuery,
    useRemoveProfilePictureMutation,
    useUpdateProfilePictureMutation,
} from '../services/UserService';
import Styles from './Styles.module.scss';
import { UserOutlined } from '@ant-design/icons';

export default function ProfilePicture(props: any) {
    const dispatch = useDispatch();
    const [croppedImage, setCroppedImage] = useState<File>();
    const user = useSelector(selectCurrentUser);
    const { data: profilePicture } = useGetProfilePictureQuery(null, { skip: !user?._id || !user?.profilePicture });
    const [updateProfilePicture] = useUpdateProfilePictureMutation();
    const [removeProfilePicture] = useRemoveProfilePictureMutation();

    const profilePictureUrl = profilePicture;

    const onImageChanged = async (file: File | undefined) => {
        try {
            if (!file) return;
            const formData = new FormData();
            formData.append('profilePicture', file);

            const { profilePicturePath } = await updateProfilePicture(formData).unwrap();

            dispatch(updateAuthState({ user: { ...user, profilePicture: profilePicturePath } as IUser }));
        } catch (error) {
            console.log(error);
        }
    };

    const onImageRemoved = async () => {
        try {
            await removeProfilePicture().unwrap();

            setCroppedImage(undefined);
            dispatch(updateAuthState({ user: { ...user, profilePicture: null } as IUser }));
        } catch (error) {
            console.log(error);
        }
    };
    const items: MenuProps['items'] = [
        {
            key: '1',
            label: (
                <MPProfileImageUpload
                    onImageChanged={onImageChanged}
                    setCroppedImage={setCroppedImage}
                />
            ),
        },
    ];

    if (profilePicture) {
        items.push({
            key: '2',
            label: 'Remove Image',
            onClick: onImageRemoved,
        });
    }

    return (
        <div className={Styles.AvatarWrapper}>
            <Avatar
                size={{ xs: 200, sm: 200, md: 200, lg: 200, xl: 200, xxl: 200 }}
                icon={<UserOutlined />}
                src={croppedImage ? URL.createObjectURL(croppedImage) : profilePictureUrl}
            />

            <MPDropDown
                menu={{ items }}
                trigger={['click']}
            >
                <div className={Styles.Edit}>
                    <Edit />
                    <span>Edit</span>
                </div>
            </MPDropDown>
        </div>
    );
}
