import { useGetAppTrackingStatsQuery } from '@app/services';
import { selectCurrentUser } from '@app/store/selectors';
import { useAppSelector } from '@common';
import { DateFormat, _compact, useGetSearchParamValue } from '@model-park/common';
import { IAppTracking } from '@model-park/models';
import { TableColumnsType } from 'antd';
import dayjs from 'dayjs';
import duration from 'dayjs/plugin/duration';
import relativeTime from 'dayjs/plugin/relativeTime';

dayjs.extend(duration);
dayjs.extend(relativeTime);

export function useVisitorStatsTable() {
    const user = useAppSelector(selectCurrentUser);
    const appId = useGetSearchParamValue('app');
    const { currentData, isFetching } = useGetAppTrackingStatsQuery(appId, { skip: !appId || !user?._id });

    const columns: TableColumnsType<IAppTracking> = [
        {
            key: 'eventType',
            dataIndex: 'eventType',
            title: 'Event Type',
        },
        {
            key: 'user',
            dataIndex: ['user', 'userName'],
            title: 'User',
            render: value => value || 'Anonymous',
        },

        {
            key: 'geoLocation',
            dataIndex: 'geoLocation',
            title: 'Geo Location',
            render: (value: IAppTracking['geoLocation']) => (value ? _compact([value.city, value.country]).join(', ') : ''),
        },
        {
            key: 'createdAt',
            dataIndex: 'createdAt',
            title: 'Date & Time',
            render: value => dayjs.unix(value).format(DateFormat.Default),
        },
        {
            key: 'sessionDuration',
            dataIndex: 'sessionDuration',
            title: 'Session Duration',
            render: value => dayjs.duration(value, 'seconds').humanize(),
        },
        {
            key: 'deviceInfo',
            dataIndex: 'deviceInfo',
            title: 'Device Info',
            render: value => value?.os?.name || 'Unknown',
        },
        {
            key: 'ipAdress',
            dataIndex: 'ipAdress',
            title: 'IP Address',
        },
    ];

    return {
        data: currentData,
        columns,
        isFetching,
    };
}
