import { ThemeType } from '@model-park/entities';
import { Card, CardProps } from 'antd';
import cs from 'classnames';
import Styles from './Styles.module.scss';

export function MPCard({ className, theme, ...props }: IMPCardProps) {
    return (
        <>
            <Card
                className={cs(Styles?.MPCard, className, { [Styles.BoxShadowLight]: theme === 'light' })}
                {...props}
            />
        </>
    );
}

export interface IMPCardProps extends CardProps {
    theme?: ThemeType;
}
