import { getAuthToken } from '@common';
import { URLHelper } from '@model-park/common';
import { ApiError, Errors } from '@model-park/entities';
import { MPResult } from '@model-park/mp-components';
import useAppname from './useAppname';

export function AppError({ error }: Props) {
    const authToken = getAuthToken();
    const { maindomain } = useAppname();
    const homePath = new URLHelper(`${window.location.protocol}//${maindomain}`, {
        authToken,
    });

    const status = error.statusCode || error.message === Errors.appNotFound ? '404' : '403';

    return (
        <MPResult
            homeLink={homePath.toString()}
            status={status}
            error={error}
            subTitle={error.message}
        />
    );
}

interface Props {
    error: ApiError;
}
