import { Modals } from '@app/entities';
import { useModalState } from '@app/hooks';
import { convertToValidAppName } from '@model-park/common';
import { MPTable } from '@model-park/mp-components';
import { Space } from '@modelpark/huggingface-hub-api';
import { Form } from 'antd';
import { useCallback } from 'react';
import { IExternalResourceModalState } from '../../types';
import SearchForm from './SearchForm';
import { useHFApi } from './useHFApi';
import { useResultTable } from './useResultTable';

export function HuggingfaceForm() {
    const { columns } = useResultTable();
    const { tableState, getHFSpaces } = useHFApi();
    const [form] = Form.useForm();
    const [{ state }, updateModalState] = useModalState<IExternalResourceModalState>(Modals.ImportExternalApp);

    const onRowSelect = useCallback(
        (record: Space) => {
            updateModalState({
                open: true,
                state: {
                    selectedResource: {
                        type: 'huggingface',
                        name: convertToValidAppName(record.cardData?.title),
                        title: record.cardData?.title as string,
                        description: record.cardData?.title as string,
                        url: new URL(`https://${record.subdomain}.hf.space`).toString(),
                        resourceId: record.id as string,
                    },
                },
            });
        },
        [updateModalState]
    );

    return (
        <>
            <SearchForm
                form={form}
                onFinish={values => getHFSpaces(values)}
            />

            <MPTable
                scroll={{x:"max-width"}}
                rowKey={'id'}
                columns={columns}
                dataSource={tableState.spaceList}
                loading={tableState.loading}
                pagination={{
                    total: tableState.total,
                    onChange: (page, pageSize) => getHFSpaces(form.getFieldsValue(), page, pageSize),
                }}
                rowSelection={{
                    type: 'radio',
                    onSelect: onRowSelect,
                    selectedRowKeys: state?.selectedResource?.type === 'huggingface' ? [state.selectedResource?.resourceId] : [],
                }}
            />
        </>
    );
}
