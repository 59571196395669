import { useToggleAppFavoriteMutation } from '@app/services';
import { Copy, Edit, FavoriteIcon, Info } from '@assets/icons';
import { AppProjectClone, useAppDispatch, useAppProjectAbility, useAppSelector } from '@common';
import { CommonNavigationHelper, _compactDict, useQueryState } from '@model-park/common';
import {
    AIModelCategory,
    AIModelCategoryMap,
    AIModelFramework,
    AIModelFrameworkMap,
    AIModelTask,
    AIModelTaskMap,
    AccessAction,
    AppFilterType,
    AppProjectType,
    AppProjectTypeMap,
    AppRegistration,
    AppRegistrationMap,
    LinkStatus,
    LinkStatusColorMap,
    LinkStatusMap,
} from '@model-park/entities';
import { AppProject, IAppProject } from '@model-park/models';
import { MPBadge, MPTable, MPTooltip } from '@model-park/mp-components';
import { selectAuthToken } from '@pages/Authentication/store';
import { setEditAppName, setEditDrawerOpen } from '@pages/MainDashBoard/store';
import { Rate, Tag, Typography } from 'antd';
import { ColumnsType } from 'antd/es/table';
import Link from 'antd/es/typography/Link';
import dayjs from 'dayjs';
import { usePagination } from '../AppGallery/usePagination';
import Styles from './Styles.module.scss';

export function useAppTable() {
    const dispatch = useAppDispatch();
    const [search] = useQueryState('search');
    const authToken = useAppSelector(selectAuthToken);
    const [toggleAppFavorite] = useToggleAppFavoriteMutation();

    const [statusFilter, setStatusFilter] = useQueryState<string>('statusFilter', [AppFilterType.ONLINE].join(','));
    const { data, isLoading, total, currentPage, setCurrentPage, pageSize, setPageSize } = usePagination();
    const renderBaseField = (value: any) => (Array.isArray(value) ? value.join(', ') : value);
    const ability = useAppProjectAbility();

    function handleEditDrawerOpen(name: string) {
        dispatch(setEditAppName(name));
        dispatch(setEditDrawerOpen(true));
    }
    const columns: ColumnsType<AppProject> = [
        {
            dataIndex: 'name',
            title: 'Name',
            width: 150,
            fixed: 'left',
            render: (value, record) => (
                <MPTooltip title="Go to App">
                    <Link
                        href={CommonNavigationHelper.getAppUri({
                            appName: record.name,
                            params: _compactDict({ authToken }),
                            host: window.location.host,
                            protocol: window.location.protocol,
                        })}
                        target="_blank"
                    >
                        {value}
                    </Link>
                </MPTooltip>
            ),
        },

        {
            dataIndex: 'title',
            title: 'Title',
            width: 130,
        },
        {
            dataIndex: 'ownerName',
            title: 'User',
            width: 214,
        },
        { dataIndex: 'appType', title: 'App Type', render: (value: AppProjectType) => AppProjectTypeMap[value], width: 90 },
        {
            dataIndex: 'registrationType',
            title: 'Registration Type',
            render: (value: AppRegistration) => AppRegistrationMap[value],
            width: 170,
        },
        {
            dataIndex: 'accessManagement',
            title: 'Access',
            width: 120,
            render: value => {
                const color = value?.access === 'public' ? 'green' : 'purple';
                return (
                    <Tag
                        color={color}
                        key={value.access}
                    >
                        {value?.access?.toUpperCase()}
                    </Tag>
                );
            },
        },
        {
            dataIndex: 'linkStatus',
            title: 'Link Status',
            width: 120,
            filters: [
                {
                    text: 'Online',
                    value: AppFilterType.ONLINE,
                },
                {
                    text: 'Offline',
                    value: AppFilterType.OFFLINE,
                },
                {
                    text: 'Error',
                    value: AppFilterType.ERROR,
                },
            ],
            filteredValue: statusFilter === 'online' ? statusFilter?.split(',') : [],
            sorter: (a, b) => a.linkStatus - b.linkStatus,
            sortDirections: ['ascend','descend'],
            render: (value: LinkStatus) => (
                <>
                    {
                        <MPBadge
                            status={LinkStatusColorMap[value]}
                            offset={[-8, 0]}
                        />
                    }
                    {LinkStatusMap[value]}
                </>
            ),
        },
        {
            title: 'Category',
            dataIndex: 'aiModelProperties',
            render: value => AIModelCategoryMap[value?.category as AIModelCategory],
            width: 110,
        },

        {
            title: 'Framework',
            dataIndex: 'aiModelProperties',
            render: value => AIModelFrameworkMap[value?.framework as AIModelFramework],
            width: 110,
        },
        {
            title: 'Task',
            dataIndex: 'aiModelProperties',
            render: value => AIModelTaskMap[value?.task as AIModelTask],
            width: 118,
        },

        {
            dataIndex: 'createdAt',
            title: 'Added',
            render: value => dayjs(value).format('MM.DD.YYYY'),
            width: 110,
        },
        { title: 'Last Updated', dataIndex: 'updatedAt', render: value => dayjs(value).format('MM.DD.YYYY'), width: 110 },
        {
            title: 'Action',
            width: 90,
            render: (value, record) => {
                const canEdit = ability?.can?.(AccessAction.UPDATE, new AppProjectClone(record as IAppProject) as any) || false;

                return (
                    <div className={Styles.IconWrapper}>
                        <div className={Styles.Icon}>
                            <MPTooltip
                                placement="bottomRight"
                                title="Copy the URL"
                            >
                                <Typography.Paragraph
                                    copyable={{
                                        icon: <Copy />,
                                        tooltips: false,
                                        text: CommonNavigationHelper.getAppUri({
                                            appName: record.name,
                                            params: _compactDict({ authToken }),
                                            host: window.location.host,
                                            protocol: window.location.protocol,
                                        }),
                                    }}
                                    className={Styles.CopyIcon}
                                ></Typography.Paragraph>
                            </MPTooltip>
                            <MPTooltip
                                placement="bottom"
                                title={value?.description}
                            >
                                <Info />
                            </MPTooltip>
                        </div>
                        <div className={Styles.FavoriteIcon}>
                            <MPTooltip
                                placement="bottomRight"
                                title={value?.isFavorite ? 'Remove from favorites' : 'Add to favorites'}
                            >
                                {Boolean(authToken) && (
                                    <Rate
                                        disabled={isLoading}
                                        value={value?.isFavorite ? 1 : 0}
                                        count={1}
                                        onChange={() => toggleAppFavorite(value._id)}
                                        character={<FavoriteIcon className={value?.isFavorite ? Styles.Favorite : ''} />}
                                    />
                                )}
                            </MPTooltip>
                            <div className={Styles.Icon}>
                                <MPTooltip
                                    title={canEdit ? 'Edit App' : "You don't have permission to edit this app"}
                                    placement="bottomLeft"
                                >
                                    <Edit onClick={() => canEdit && handleEditDrawerOpen(record.name)} />
                                </MPTooltip>
                            </div>
                        </div>
                    </div>
                );
            },
        },
    ];

    const expandedRowRender = (record: any) => {
        const columns: ColumnsType<AppProject> = [
            {
                dataIndex: 'description',
                title: 'Description',
            },
            {
                title: 'Tags',
                dataIndex: 'tags',
                render: renderBaseField,
            },
        ];

        return (
            <MPTable
                columns={columns}
                dataSource={data?.filter(item => item.name === record.name)}
                pagination={false}
                rowKey="_id"
            />
        );
    };

    return {
        columns,
        expandedRowRender,
        data,
        isLoading,
        setStatusFilter,
        total,
        currentPage,
        setCurrentPage,
        pageSize,
        setPageSize,
    };
}
