import { Table, Typography } from 'antd';

const { Title, Paragraph } = Typography;

export default function Initialize() {
    return (
        <>
            <Title level={4}>Initialize:</Title>
            <Paragraph>Initialize the modelpark cli</Paragraph>
            <Typography>
                <code>modelpark init</code>
            </Typography>
            <Title level={5}>Options:</Title>
            <Table
                columns={initializeColumns}
                dataSource={initializeData}
                pagination={false}
                scroll={{ x: 'max-content' }}

            />
        </>
    );
}

const initializeColumns = [
    {
        title: 'Name',
        dataIndex: 'name',
    },
    {
        title: 'Type',
        dataIndex: 'type',
    },
    {
        title: 'Description',
        dataIndex: 'description',
    },
    {
        title: 'Default value',
        dataIndex: 'defaultValue',
    },
];

const initializeData = [
    {
        key: '1',
        name: '--port, -p',
        type: 'number',
        description: 'Port for the local server',
        defaultValue: 'undefined',
    },
    {
        key: '2',
        name: '--detach, -d',
        type: 'boolean',
        description: 'Detach the process',
        defaultValue: 'true',
    },
];
