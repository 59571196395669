import { MPTable } from '@model-park/mp-components';
import Styles from './Styles.module.scss';
import { useAppTable } from './useAppTable';

export default function AppListTable() {
    const {
        columns,
        data,
        expandedRowRender,
        isLoading,
        setStatusFilter,
        total,
        currentPage,
        setCurrentPage,
        pageSize,
        setPageSize,
    } = useAppTable();

    return (
        <>
            <MPTable
                columns={columns}
                rowKey="_id"
                dataSource={data}
                loading={isLoading}
                expandable={{
                    expandedRowRender,
                    defaultExpandedRowKeys: ['0'],
                }}
                onChange={(pagination, filters, sorter) => {
                    setStatusFilter(filters?.status as any);
                }}
                className={Styles.AppListTable}
                scroll={{ y: 700, x: 1400 }}
                pagination={{
                    total,
                    current: currentPage,
                    pageSize,
                    hideOnSinglePage: true,
                    onChange: setCurrentPage,
                    onShowSizeChange: setPageSize,
                    showTotal: total => (
                        <span>
                            Total <span style={{ fontWeight: 'bold' }}>{total}</span> apps
                        </span>
                    ),
                }}
            />
        </>
    );
}
