import { useGetNewObjectIdMutation } from '@app/services';
import { selectIsAppInit } from '@app/store/selectors';
import { NavigationHelper, useAppSelector } from '@common';
import { selectAuthUser } from '@pages/Authentication/store/selectors';
import { theme } from 'antd';
import { useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';


export default function useLayoutContent(){
    const isAppInit = useAppSelector(selectIsAppInit);
    const user = useAppSelector(selectAuthUser);
    const location = useLocation();
    const navigate = useNavigate();
    const [getNewObjectId] = useGetNewObjectIdMutation();
    const { token } = theme.useToken();

    useEffect(() => {
        if (!isAppInit || user?._id) return;
        const isPrivateRoute = privateRoutes.includes(location.pathname);

        if (isPrivateRoute && !user?._id) {
            NavigationHelper.Auth.Login().navigate();
        }

        if (!localStorage.getItem('tempUserId')) {
            getNewObjectId(undefined)
                .unwrap()
                .then(res => {
                    localStorage.setItem('tempUserId', res?.newObjectId);
                });
        }
    }, [getNewObjectId, isAppInit, location, navigate, user]);

    return { isAppInit,  colorBgBase:token?.colorBgBase };
}

const privateRoutes = ['settings', '/settings/profile', '/settings/account', '/settings/organization'];
