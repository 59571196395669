import { NavigationHelper, useAppDispatch } from '@common';
import { isIncludeOrEqual } from '@model-park/common';
import { Errors } from '@model-park/entities';
import { ISignupForm } from '@pages/Authentication/entities/interface';
import { useSignupMutation } from '@pages/Authentication/services/AuthService';
import { updateAuthState } from '@pages/Authentication/store';
import { FormInstance } from 'antd/lib/form/Form';

export function useSignupForm() {
    const dispatch = useAppDispatch();
    const [signup, { isLoading }] = useSignupMutation();

    async function onFinish(values: ISignupForm, form: FormInstance<ISignupForm>) {
        try {
            const response = await signup(values).unwrap();

            if (response.authToken) {
                dispatch(updateAuthState(response));
                NavigationHelper.Home().navigate();

                sessionStorage.setItem('authToken', response.authToken);
            }
        } catch (error: any) {
            if (isIncludeOrEqual(error?.data?.message, [Errors.emailExists, Errors.userNotVerified], true)) {
                form.setFields([
                    {
                        name: 'email',
                        errors: [error?.data?.message],
                    },
                ]);
            }
        }
    }
    return { onFinish, isLoading };
}
