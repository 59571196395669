import { Organization } from '@model-park/models';
import { Form } from 'antd';
import { useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { useGetOrganizationQuery } from '../../services/UserService';
import Styles from './../Styles.module.scss';
import OrganizationForm from './OrganizationForm';

export default function OrganizationDetails() {
    const { organization } = useParams<{ organization: string }>();
    const [form] = Form.useForm<Organization>();
    const { data } = useGetOrganizationQuery(organization as string, { skip: !organization });
    useEffect(() => {
        form.setFieldsValue(data as any);
    }, [data, form]);

    return (
        <div>
            <h1 className={Styles.Title}>Organization Details</h1>
            <OrganizationForm
                form={form}
                isLoading={false}
                btnText="Update Organization"
            />
        </div>
    );
}
