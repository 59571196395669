import { NavigationHelper } from '@common';
import { MPFallback, MPList } from '@model-park/mp-components';
import { Avatar, List, Typography } from 'antd';
import { useGetOrganizationsQuery } from '../services/UserService';
import Styles from './Styles.module.scss';

const { Title, Text } = Typography;

export default function OrganizationList() {
    const { data, isFetching } = useGetOrganizationsQuery(undefined);

    if (isFetching) return <MPFallback />;

    if (!data?.length) return <div className={Styles.Explanation}>You are not a member of any organization.</div>;

    const handleOrganizationClick = (id: string) => {
        NavigationHelper.Settings.OrganizationDetails({ params: { organization: id } }).navigate();
    };

    return (
        <div>
            <MPList
                dataSource={data}
                renderItem={item => (
                    <List.Item
                        key={item.name}
                        className={Styles.OrganizationItem}
                        onClick={() => handleOrganizationClick(item._id.toString())}
                    >
                        <List.Item.Meta
                            avatar={
                                <Avatar
                                    src={item.profilePicture}
                                    size={64}
                                />
                            }
                            title={<Title level={4}>{item.fullName}</Title>}
                            description={<Text>{item.name}</Text>}
                        />
                    </List.Item>
                )}
            />
        </div>
    );
}
