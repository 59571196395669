import { DashboardPanel, IDashboardPanelProps } from '@app/components';
import { useAppDispatch, useAppSelector } from '@common';
import { DashboardPanelType } from '../entities';
import { selectActiveDashboardPanelKey, updateActivePanelKey } from '../store';

export function MainDashboardPanel({ panelKey, ...props }: IProps) {
    const activePanel = useAppSelector(selectActiveDashboardPanelKey);
    const dispatch = useAppDispatch();

    function onExpand() {
        dispatch(updateActivePanelKey(activePanel === panelKey ? null : panelKey));
    }

    return (
        <>
            <DashboardPanel
                expanded={panelKey === activePanel}
                {...props}
            />
        </>
    );
}

interface IProps extends IDashboardPanelProps {
    panelKey: DashboardPanelType;
}
