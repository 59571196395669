import { useAppDispatch, useAppSelector } from '@common';
import { AppFilterType } from '@model-park/entities';
import { selectAuthUser } from '@pages/Authentication/store/selectors';
import { selectFeaturedListActiveTabKey, updateActiveFilterTab } from '@pages/MainDashBoard/store';
import { TabsProps } from 'antd';
import { useState } from 'react';
import { MainDashboardPanel } from '../MainDashboardPanel';
import styles from './Style.module.scss';
import { AppGallery, AppListTable, ExtraContent, MobileExtraContent } from './components';
import { useWindowSize } from 'react-use';

export default function FeaturedList() {
    const { width } = useWindowSize();
    const isSmallTabSize = width < 840;
    const [view, setView] = useState<View>('card');
    const activeTab = useAppSelector(selectFeaturedListActiveTabKey);
    const dispatch = useAppDispatch();
    const user = useAppSelector(selectAuthUser);
    const _tabs = user?._id ? tabs : tabs?.filter(tab => !privateTabs.includes(tab.key as AppFilterType));

    return (
        <MainDashboardPanel
            tabList={_tabs}
            onTabChange={key => dispatch(updateActiveFilterTab(key as AppFilterType))}
            activeTabKey={activeTab}
            panelKey="FeaturedList"
            tabBarExtraContent={isSmallTabSize ? <MobileExtraContent setView={setView} /> : <ExtraContent setView={setView} />}
            className={styles.FeaturedListPanel}
            bodyStyle={{ height: 'calc(100% - 60px)' }}
            size={isSmallTabSize ? 'small' : 'default'}
        >
            {view === 'card' ? <AppGallery /> : <AppListTable />}
        </MainDashboardPanel>
    );
}

const privateTabs = [AppFilterType.MY_APPS, AppFilterType.FAVORITES];

export const tabs: TabsProps['items'] = [
    {
        key: AppFilterType.MOST_POPULAR,
        label: 'Most Popular',
    },
    {
        key: AppFilterType.RECENT,
        label: 'Recent',
    },
    {
        key: AppFilterType.MY_APPS,
        label: 'My Apps',
    },
    {
        key: AppFilterType.FAVORITES,
        label: 'Favorites',
    },
    {
        key: AppFilterType.ALL,
        label: 'All',
    },
];
type View = 'card' | 'table';
