import { ProjectForm } from '@common';
import { MPButton } from '@model-park/mp-components';
import { useState } from 'react';
import DeleteAppModal from './DeleteAppModal';
import Styles from './Styles.module.scss';
import useUpdateProject from './useUpdateProject';

export function EditApp() {
    const { onFinish, form, detailsLoading, updateLoading, currentAppName } = useUpdateProject();
    const [modalOpen, setModalOpen] = useState(false);

    return (
        <>
            <div className={Styles.BtnWrapper}>
                <MPButton
                    onClick={() => setModalOpen(true)}
                    danger
                >
                    Delete App
                </MPButton>
            </div>

            <ProjectForm
                form={form}
                isLoading={detailsLoading || updateLoading}
                onFinish={onFinish}
                currentAppName={currentAppName}
            />
            <DeleteAppModal 
            modalOpen={modalOpen}
            setModalOpen={setModalOpen}
            />
        </>
    );
}
