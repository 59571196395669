import { RoutingMap } from '@app/entities';
import { CommonNavigationHelper, EventBus } from '@model-park/common';
import { NavigateOptions } from 'react-router-dom';
import { AppProjectDetailsUriArgs, IBaseUriArgs } from './interface';

export class NavigationHelper extends CommonNavigationHelper {
    public static Home() {
        return this.returnPayload('/');
    }

    public static AppProject = {
        AppDashboard: ({ params, query }: IBaseUriArgs, options?: NavigateOptions) => {
            const path = this.replaceNavigationParams(this.RoutingMap.Home, params);
            return this.returnPayload(path, query);
        },

        AppPage: ({ params, query }: AppProjectDetailsUriArgs, options?: NavigateOptions) => {
            const path = this.replaceNavigationParams(this.RoutingMap.AppPage, params);
            return this.returnPayload(path, query);
        },
    };

    public static Settings = {
        Profile: () => {
            return this.returnPayload(this.RoutingMap.Settings.Profile);
        },
        Organizations: () => {
            return this.returnPayload(this.RoutingMap.Settings.Organizations);
        },
        newOrganization: () => {
            return this.returnPayload(this.RoutingMap.Settings.NewOrganization);
        },
        OrganizationDetails: ({ params, query }: IBaseUriArgs) => {
            const payload = `settings/organizations/${params.organization}`;
            return this.returnPayload(payload, query);
        },
    };
    public static Auth = {
        Login: () => {
            return this.returnPayload(this.RoutingMap.Login);
        },
        Signup: () => {
            return this.returnPayload(this.RoutingMap.Signup);
        },
    };

    public static navigate(path: string, options?: NavigateOptions) {
        EventBus.emit('navigate', { path, options });
    }

    public static RoutingMap = RoutingMap;

    public static BreadCrumbNameMap: Dictionary<string> = {
        '/': 'Home',
        '/apps': 'Apps',
        '/new-project': 'New Project',
        '/apps/:appName': ':appName',
    };
}
