import { MPInput, MPSelect, MPTextArea } from '@model-park/mp-components';
import { Form } from 'antd';

export default function Tags() {
    return (
        <>
            <Form.Item
                name={'tags'}
                label={'Tags'}
            >
                <MPSelect open={false} mode="tags" />
            </Form.Item>
        </>
    );
}
