import '@common/helpers/i18n';
import store from '@store';
import { Provider as ReduxProvider } from 'react-redux';
import Router from './Router';
import ThemeProvider from './ThemeProvider';
import { HelmetProvider } from 'react-helmet-async';

export default function Provider() {
    const Providers = combineProviders([Router]);
    return (
        <>
            <ReduxProvider store={store}>
                <HelmetProvider>
                    <ThemeProvider>
                        <Providers />
                    </ThemeProvider>
                </HelmetProvider>
            </ReduxProvider>
        </>
    );
}

function combineProviders(providers: Array<any>) {
    return providers.reduce((Combined, Provider) => ({ children }: any) => (
        <Combined>
            <Provider>{children}</Provider>
        </Combined>
    ));
}
