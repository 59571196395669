import { AppFilterType } from '@model-park/entities';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { DashboardPanelType, MainDashboardStateFactory } from '../entities';

export const MainDashboardSlice = createSlice({
    name: 'MainDashboard',
    initialState: MainDashboardStateFactory(),
    reducers: {
        updateActiveFilterTab(state, { payload }: PayloadAction<AppFilterType>) {
            state.featuredListActiveTabKey = payload;
        },

        setEditDrawerOpen(state, { payload }: PayloadAction<boolean>) {
            state.isEditDrawerOpen = payload;
        },

        updateActivePanelKey(state, { payload }: PayloadAction<DashboardPanelType | null>) {
            state.activePanelKey = payload;
        },

        setEditAppName(state, { payload }: PayloadAction<string>) {
            state.currentAppName = payload;
        },

        updatePanelSizeState(state, { payload }: PayloadAction<{ panelKey: DashboardPanelType; size: number }>) {
            const { panelKey, size } = payload;
            state.panelSizeState[panelKey] = size;
        },
    },
});

export const { updateActiveFilterTab, updateActivePanelKey, updatePanelSizeState, setEditDrawerOpen, setEditAppName } =
    MainDashboardSlice.actions;

export * from './selectors';
