import { MPSearch, MPSegmented, MPSelect, MPSwitch } from '@model-park/mp-components';
import { CardView, TableView } from '@model-park/assets';
import styles from './styles.module.scss';
import { AppProjectTypeOptions, useQueryState } from '@model-park/common';
import { AppFilterType } from '@model-park/entities';

export default function MainDashboardExtraContent({ setView }: { setView: (view: any) => void }) {
    const [, setSearch] = useQueryState('search');
    const [statusFilter, setStatusFilter] = useQueryState<AppFilterType | null>('statusFilter', AppFilterType.ONLINE);
    const [appTypeFilter, setAppTypeFilter] = useQueryState<string | null>('appTypeFilter', null);

    function onFilterChange(checked: boolean) {
        setStatusFilter(checked ? AppFilterType.ONLINE : AppFilterType.ALL);
    }

    return (
        <div className={styles.ExtraContent}>
            <MPSwitch
                checkedChildren="Online"
                unCheckedChildren="All Apps"
                checked={statusFilter === AppFilterType.ONLINE}
                onChange={onFilterChange}
            />

            <MPSelect
                placeholder="App Type"
                options={AppProjectTypeOptions}
                allowClear
                onChange={setAppTypeFilter}
                value={appTypeFilter}
                className={styles.AppTypeFilter}
            />
            <MPSearch
                placeholder="Search App"
                allowClear
                onSearch={setSearch}
                className={styles.SearchInput}
            />
            <div>
                <MPSegmented
                    className={styles.ViewTypeSelector}
                    options={[
                        { label: 'Card View', value: 'card', icon: <TableView />, className: styles.ViewType },
                        { label: 'Table', value: 'table', icon: <CardView />, className: styles.ViewType },
                    ]}
                    onChange={setView}
                />
            </div>
        </div>
    );
}
