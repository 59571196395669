import * as tldjs from 'tldjs';

export class DomainHelper {
    public current: ReturnType<typeof tldjs.fromUserSettings>;

    constructor(private validHosts?: Array<string>) {
        this.current = tldjs.fromUserSettings({ validHosts: this.validHosts });
    }

    public getSubdomain(domain: string) {
        const subdomain = this.current.getSubdomain(domain);
        if (subdomain === 'www') return '';
        return subdomain;
    }

    public getMaindomain(domain: string) {
        return this.current.getDomain(domain);
    }
}
