import { NavigateOptions } from "react-router-dom";

export class EventBus {
    static eventList: Array<EventBusArgs["type"]> = [];
    static on(event: EventBusArgs["type"], callback: (payload: EventBusArgs["payload"]) => void) {
        document.addEventListener(event, (e: any) => callback(e.detail));
    }
    static emit(event: EventBusArgs["type"], payload?: EventBusArgs["payload"]) {
        document.dispatchEvent(new CustomEvent(event, { detail: payload }));
        EventBus.eventList.push(event);
    }
    static remove(event: EventBusArgs["type"], callback: any) {
        document.removeEventListener(event, callback);
        EventBus.eventList = EventBus.eventList.filter(item => item !== event);
    }
}

export type EventBusArgs = ILogoutEvent | IUpdateSearchParamsEvent | INavigateEvent | IReduxAction;

export interface ILogoutEvent {
    type: "forceLogout";
    payload: never;
}

export interface IUpdateSearchParamsEvent {
    type: "updateSearchParams";
    payload: Dictionary<string>;
}

export interface INavigateEvent {
    type: "navigate";
    payload: {
        path: string;
        options?: NavigateOptions;
    };
}

export interface IReduxAction<T = any> {
    type: "dispatch";
    payload: {
        type: string,
        payload: T;
    };
}