import { MPModal, MPRadioGroup, MPTooltip } from '@model-park/mp-components';
import Styles from './Styles.module.scss';
import useUpdateNameStatus from './useUpdateNameSpace';
import { NamespaceStatus } from '@model-park/entities';

export default function DeleteAppModal({ modalOpen, setModalOpen }: IProps) {
    const { modalProps, appStatus, setAppStatus } = useUpdateNameStatus({ modalOpen, setModalOpen });
    return (
        <MPModal {...modalProps}>
            <div className={Styles.TextWrapper}>
                <span>You're about to delete this app.</span>
                <span>If you're not sure, you can archive this app or reserve the app's namespace instead</span>
            </div>
            <MPRadioGroup
                menuItemList={menuItemList}
                onChange={e => setAppStatus(e.target.value)}
                value={appStatus}
            />
        </MPModal>
    );
}

const menuItemList = [
    {
        value: NamespaceStatus.archived,
        title: (
            <MPTooltip title="Archiving this app will make it unavailable. Users can use this namespace.">Archive App</MPTooltip>
        ),
    },
    {
        value: NamespaceStatus.reserved,
        title: (
            <MPTooltip title="Reserving this app name will make unavailable. Only you can use this namespace later.">
                Reserve App Name
            </MPTooltip>
        ),
    },
    {
        value: NamespaceStatus.removed,
        title: <MPTooltip title="">Delete App</MPTooltip>,
    },
];

interface IProps {
    modalOpen: boolean;
    setModalOpen: (open: boolean) => void;
}
