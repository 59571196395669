import { InputNumber, InputNumberProps } from 'antd';
import cs from "classnames";
import Styles from "./Styles.module.scss";

export default function MPInputNumber({ className, ...props }: MPInputProps) {
    return (
        <>
            <InputNumber
                className={cs(Styles?.MPInputNumber, className)}
                {...props}
            />
        </>
    );
}

export interface MPInputProps extends InputNumberProps {

}
