import { useRequestAccessMutation } from '@app/services';
import { RequestAccessFormValues } from '@model-park/common';
import { MPButton, MPForm, MPTextArea } from '@model-park/mp-components';
import { Form, FormInstance, Modal, Typography, notification } from 'antd';
import useAppname from '../useAppname';
import { toast } from 'react-toastify';

export function RequestAccess() {
    const [modal, contextHolder] = Modal.useModal();
    const [form] = Form.useForm();
    const { appName } = useAppname();
    const [requestAccess] = useRequestAccessMutation();
    const message = Form.useWatch<string>('message', form);

    function onRequestAccess() {
        modal
            .confirm({
                title: 'Request Access',
                content: <RequestAccessForm form={form} />,
                centered: true,
            })
            .then(
                confirm => {
                    if (!confirm) return form.resetFields();
                    requestAccess({ name: appName, message })
                        .unwrap()
                        .then(() => {
                            toast.success(
                                <div>
                                    <Typography.Title>Request sent</Typography.Title>
                                    <Typography>Your request has been sent successfully</Typography>
                                </div>
                            );
                        })
                        .catch(err => {
                            toast.error(
                                <div>
                                    <Typography.Title>Request failed</Typography.Title>
                                    <Typography>{err?.data?.message}</Typography>
                                </div>
                            );
                        });
                },
                () => {
                    form.resetFields();
                }
            );
    }

    return (
        <>
            <MPButton onClick={() => onRequestAccess()}>Request Access</MPButton>

            {contextHolder}
        </>
    );
}

function RequestAccessForm({ form }: { form: FormInstance }) {
    return (
        <>
            <MPForm<RequestAccessFormValues>
                layout="vertical"
                form={form}
                showSubmit={false}
            >
                <Form.Item
                    name="message"
                    label="Message"
                >
                    <MPTextArea />
                </Form.Item>
            </MPForm>
        </>
    );
}
