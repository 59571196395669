export enum Errors {
    emailExists = 'Email already exists',
    userNameExists = 'User name already exists',
    userNotFound = 'User not found',
    passwordIncorrect = 'Password is incorrect',
    userNotVerified = 'User not verified',
    appExists = 'App already exists',
    appNotFound = 'App not found',
    appForbidden = 'You are not allowed to access this app',
    invalidEmail = 'Invalid email',
    invalidUrl = 'Invalid url',
    notAllowed = 'Not allowed',
    passwordsDoNotMatch = 'Passwords do not match',
    organizationNotFound = 'Organization not found',
    orgNameExists = 'Organization name already exists',
    passwordRequired = 'Password is required for this app',
    requestAlreadyExist = 'Request already exist',
}