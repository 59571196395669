import { DomainHelper, URLHelper } from '../helpers';

export function getLocationSubdomain() {
    const domainHelper = new DomainHelper();
    return domainHelper.getSubdomain(window.location.hostname);
}

export function getLocationMaindomain() {
    const domainHelper = new DomainHelper();
    return domainHelper.getMaindomain(window.location.hostname);
}

export function getLocationParts(location?: string): LocationParts {
    const domainHelper = new DomainHelper();

    return {
        subdomain: domainHelper.getSubdomain(window.location.hostname),
        maindomain: domainHelper.getMaindomain(window.location.hostname) ?? 'modelpark.app',
        path: getLocationPath(location ?? window.location.href),
    };
}

export function getLocationPath(location: string) {
    const uri = new URLHelper(location);

    return uri.pathname;
}

export interface LocationParts {
    subdomain?: string | null;
    maindomain: string;
    path: string;
}
