import { ProfileFormValues, _entries, _isEqual } from '@model-park/common';
import { IUser } from '@model-park/models';
import { MPForm } from '@model-park/mp-components';
import { selectAuthUser } from '@pages/Authentication/store';
import { Form } from 'antd';
import { AuthEmail } from 'libs/mp-components/src/Pages/Authentication/components/AuthFormItems';
import { useCallback, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useUpdateProfileMutation } from '../services/UserService';
import ChangePasswordForm from './ChangePasswordForm';
import Styles from './Styles.module.scss';

export default function AccountSettings() {
    const [form] = Form.useForm<ProfileFormValues>();
    const user = useSelector(selectAuthUser);
    const [updateProfileProject, { isLoading: updateLoading }] = useUpdateProfileMutation();

    useEffect(() => {
        if (!user) return;
        form.setFieldsValue(user);
    }, [user, form]);

    const onFinish = useCallback(
        async (values: ProfileFormValues) => {
            try {
                const payload = getDifferences(values, user as IUser);
                await updateProfileProject({ body: payload as any, id: user?._id as string }).unwrap();

                // NavigationHelper.AppProject.AppDetails({ params: { appName: app?.name as string } }).navigate();
            } catch (error: any) {
                console.log(error);
            }
        },
        [user, updateProfileProject]
    );
    return (
        <div>
            <h1 className={Styles.Title}>Account Setting</h1>
            <MPForm<ProfileFormValues>
                onFinish={onFinish}
                layout="vertical"
                loading={updateLoading}
                form={form}
                className={Styles.ProfileSettingForm}
                submitclassName={Styles.UpdateEmailButton}
                submitBtnText="Update Email"
            >
                <h2>Change Email</h2>
                <AuthEmail showhelp />
            </MPForm>
            <ChangePasswordForm />
        </div>
    );
}

function getDifferences(payload: ProfileFormValues, data: IUser) {
    const differences: Partial<IUser> = {};
    _entries(payload).forEach(([key, value]) => {
        if (!_isEqual(value, data?.[key])) {
            (differences as any)[key] = value;
        }
    });

    return differences;
}
