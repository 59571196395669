import { ICommonProps } from '@model-park/common';
import { MPCard } from '@model-park/mp-components';
import cs from 'classnames';
import Styles from './Styles.module.scss';

export default function AuthCard({ children }: IAuthCardProps) {
    return <MPCard className={cs(Styles.AuthCardContainer)}>{children}</MPCard>;
}

export interface IAuthCardProps extends ICommonProps {}
