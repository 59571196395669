import { MPButton, MPInput, MPPassword } from '@model-park/mp-components';
import { Col, Form, Row } from 'antd';

export function AuthEmail({ showhelp = false }: { showhelp?: boolean }) {
    let helpText =
        "We'll use this email for account notifications, password resets, communication, and other account related emails.";
    return (
        <>
            <Form.Item
                name="email"
                label="Email"
                help={showhelp && helpText}
                rules={[
                    {
                        type: 'email',
                        message: 'The input is not valid E-mail!',
                    },
                    {
                        required: true,
                        message: 'Please input your E-mail!',
                    },
                ]}
            >
                <MPInput placeholder="Email" />
            </Form.Item>
        </>
    );
}
export function AuthFullName() {
    return (
        <>
            <Form.Item
                name="fullName"
                label="Full Name"
                rules={[
                    {
                        type: 'string',
                        message: 'The input is not valid name',
                    },
                    {
                        required: true,
                        message: 'Please input your full name!',
                    },
                ]}
            >
                <MPInput placeholder="Full Name" />
            </Form.Item>
        </>
    );
}
export function AuthUserName() {
    return (
        <>
            <Form.Item
                name="userName"
                label="User Name"
                rules={[
                    {
                        type: 'string',
                        message: 'The input is not valid name',
                    },
                    {
                        required: true,
                        message: 'Please input your user name!',
                    },
                    /* generate an regex for username validation and add it here 
                    No space allowed
                    No special character allowed
                    proper for url and email
                    */

                    {
                        pattern: /^[a-zA-Z0-9]+$/,
                        message: 'No space or special character allowed',
                    },
                ]}
            >
                <MPInput placeholder="User Name" />
            </Form.Item>
        </>
    );
}

export function AuthPassword() {
    return (
        <>
            <Form.Item
                name="password"
                label="Password"
                rules={[
                    {
                        required: true,
                        message: 'Please input your password!',
                    },
                ]}
            >
                <MPPassword placeholder="Password" />
            </Form.Item>
        </>
    );
}

export function AuthPasswordConfirm() {
    return (
        <>
            <Form.Item
                name="confirm"
                label="Confirm Password"
                dependencies={['password']}
                hasFeedback
                rules={[
                    {
                        required: true,
                        message: 'Please confirm your password!',
                    },
                    ({ getFieldValue }) => ({
                        validator(_, value) {
                            if (!value || getFieldValue('password') === value) {
                                return Promise.resolve();
                            }
                            return Promise.reject(new Error('The two passwords that you entered do not match!'));
                        },
                    }),
                ]}
            >
                <MPPassword placeholder="Confirm Password" />
            </Form.Item>
        </>
    );
}

export function AuthCaptcha() {
    return (
        <>
            <Form.Item
                label="Captcha"
                extra="We must make sure that your are a human."
            >
                <Row gutter={8}>
                    <Col span={12}>
                        <Form.Item
                            name="captcha"
                            noStyle
                            rules={[{ required: true, message: 'Please input the captcha you got!' }]}
                        >
                            <MPInput />
                        </Form.Item>
                    </Col>
                    <Col span={12}>
                        <MPButton>Get captcha</MPButton>
                    </Col>
                </Row>
            </Form.Item>
        </>
    );
}
