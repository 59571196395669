import { Select, SelectProps } from 'antd';
import type { DefaultOptionType } from 'antd/es/select';
import cs from 'classnames';
import Styles from './Styles.module.scss';

export function MPSelect<T = any, P extends DefaultOptionType = any>({ children, ...props }: IMPSelectProps<T>) {
    return (
        <>
            <Select<T, P>
                className={cs(Styles?.MPSelectContainer)}
                {...props}
            />
        </>
    );
}

export interface IMPSelectProps<T = any, P extends DefaultOptionType = any> extends SelectProps<T, P> {}
