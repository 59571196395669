import { useCallback, useEffect } from 'react';
import { _isEmpty } from '../utils';
import { useGetSearchParamValue } from './useGetSearchParamValue';
import { useUpdateSearchParams } from './useUpdateSearchParams';

export function useQueryState<T = string>(field: string, initialValue?: T) {
    const updateSearchParams = useUpdateSearchParams();
    const value = useGetSearchParamValue<T>(field);

    const setValue = useCallback((newValue: T) => updateSearchParams({ [field]: newValue }), [field]);

    useEffect(() => {
        if (!_isEmpty(initialValue) && _isEmpty(value)) {
            setValue(initialValue as T);
        }
    }, []);

    return [value ?? undefined, setValue] as const;
}
