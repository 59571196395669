import { MPTable } from '@model-park/mp-components';
import { useVisitorStatsTable } from './useVisitorStatsTable';
import styles from './styles.module.scss';
export function VisitorStatsTable() {
    const { data, columns, isFetching } = useVisitorStatsTable();
    return (
        <>
            <MPTable
                dataSource={data}
                columns={columns}
                loading={isFetching}
                className={styles.VisitorStatsTable}
                scroll={{ x: "auto" }}
            />
        </>
    );
}
