import { AppFilterType } from '@model-park/entities';
import { MainDashboardState } from './interface';

export function MainDashboardStateFactory(data?: Partial<MainDashboardState>): MainDashboardState {
    return {
        featuredListActiveTabKey: data?.featuredListActiveTabKey || AppFilterType.MOST_POPULAR,
        activePanelKey: data?.activePanelKey || null,
        panelSizeState: data?.panelSizeState || {},
        isEditDrawerOpen: data?.isEditDrawerOpen || false,
        currentAppName: data?.currentAppName,
    };
}
