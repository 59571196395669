import { Typography } from 'antd';

const { Title, Paragraph } = Typography;

export function Installation() {
    return (
        <>
            <Title level={3}>Installation</Title>
            <Title level={4}>Windows</Title>
            <Paragraph>
                Download the latest version from{' '}
                <a href="https://modelpark.app/dist/mpinstaller.exe">https://modelpark.app/dist/mpinstaller.exe</a>
            </Paragraph>
            <Title level={4}>Linux</Title>
            <Paragraph>Run the following command in your terminal:</Paragraph>
            <Typography>
                <code>curl https://modelpark.app/scripts/installer.sh | bash</code>
            </Typography>
            <Title level={4}>Manual Installation</Title>
            <Paragraph>
                You can download the file suitable for your operating system from{' '}
                <a href="https://modelpark.app/distribution">https://modelpark.app/distribution</a>
            </Paragraph>
        </>
    );
}
